import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Thead, Tbody, Tr, Th, Td, useToast, Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomBadge from '../../general/custombadge';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomButton from '../../general/custombutton';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomModal from '../../modal/custommodal';
import SubtitleText from '../../text/subtitletext';

import useFetchHacksData from '../../../hooks/useFetchHacksData';

import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';

const roleColors = {
  'Low': 'purple',
  'Medium': 'pink',
  'High': 'orange',
};

const HacksTable = ({ sx }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hackInput, setHackInput] = useState('');
  const [dateInput, setDateInput] = useState('');
  const [severityInput, setSeverityInput] = useState('');
  const [causeInput, setCauseInput] = useState('');
  const [nextStepsInput, setNextStepsInput] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [errors, setErrors] = useState({
    hack: false,
    date: false,
    severity: false,
    cause: false,
    nextSteps: false
  });

  const toast = useToast();

  const { hacksData, refetch: refetchHacksData } = useFetchHacksData();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setHackInput('');
    setDateInput('');
    setSeverityInput('');
    setCauseInput('');
    setNextStepsInput('');
    setIsModalOpen(false);
  };

  const relevantTaskIDs = [
    "e32564d5-77e4-4eb9-aee9-ed9e2d6fa084",
  ];
  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const [taskData, setTaskData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);
  const { updateTaskData } = useUpdateTaskData();

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
    setIsSubmittedChecked(true);
  }, [fetchedData]);

  const handleAddHack = async () => {
    const token = await getSessionToken();
    setErrors({
      hack: false,
      date: false,
      severity: false,
      cause: false,
      nextSteps: false
    });

    // Validate inputs
    let hasErrors = false;
    if (!hackInput.trim()) {
      setErrors(prev => ({ ...prev, hack: true }));
      hasErrors = true;
    }
    if (!dateInput.trim()) {
      setErrors(prev => ({ ...prev, date: true }));
      hasErrors = true;
    }
    if (!severityInput) {
      setErrors(prev => ({ ...prev, severity: true }));
      hasErrors = true;
    }
    if (!causeInput.trim()) {
      setErrors(prev => ({ ...prev, cause: true }));
      hasErrors = true;
    }
    if (!nextStepsInput.trim()) {
      setErrors(prev => ({ ...prev, nextSteps: true }));
      hasErrors = true;
    }

    if (hasErrors) {
      toast({
        title: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const updatedTasks = taskData.map(task => ({
      ...task,
      complete: true,
      evidence: `Marked task ${task.name} as complete for security breach incident with description: ${hackInput}, date: ${dateInput}, severity: ${severityInput}, cause: ${causeInput}, corrective actions and resolutions: ${nextStepsInput}.`
    }));
    updateTaskData(updatedTasks);

    if (hackInput && dateInput && severityInput && causeInput && nextStepsInput) {
      setIsAdding(true);
      try {
        await axios.post(`${API_BASE_URL}core/org/incident/`, {
          description: hackInput,
          date: dateInput,
          severity: severityInput,
          cause: causeInput,
          next_steps: nextStepsInput
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        toast({
          title: 'Incident added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeModal();
        refetchHacksData();
        posthog.capture('Added Security Incident', {
          hackInput: hackInput,
          dateInput: dateInput,
          severityInput: severityInput,
          causeInput: causeInput,
          nextStepsInput: nextStepsInput
        });
      } catch (error) {
        posthog.capture('Error Adding Security Incident', {
          error: error
        });
        console.error('Error adding incident:', error);
      } finally {
        setIsAdding(false);
      }
    }
  };

  const handleDeleteHack = async (hackId) => {
    const updatedTasks = taskData.map(task => ({
      ...task,
      evidence: `Removed security breach incident with ID ${hackId} from security breach incident list.`
    }));
    updateTaskData(updatedTasks);

    const token = await getSessionToken();
    try {
      await axios.delete(`${API_BASE_URL}core/org/incident/`, {
        data: {
          id: hackId
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      toast({
        title: 'Incident deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refetchHacksData();
      posthog.capture('Removed Security Incident', {
        hackId: hackId
      });
    } catch (error) {
      posthog.capture('Error Deleting Security Incident', {
        error: error
      });
      console.error('Error deleting incident:', error);
    }
  };

  const modalHeader = <SubtitleText>Add security incident</SubtitleText>;
  const modalBody = (
    <VStack spacing={6} align='left'>
      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Incident
        </RegularText>
        <CustomInput 
          placeholder="Employee mentioned PHI while talking to the press" 
          value={hackInput} 
          onChange={(e) => setHackInput(e.target.value)} 
          borderColor={errors.hack ? "red.500" : undefined}
        />
      </VStack>

      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Date
        </RegularText>
        <CustomInput 
          placeholder="2/12/2024" 
          value={dateInput} 
          onChange={(e) => setDateInput(e.target.value)} 
          borderColor={errors.date ? "red.500" : undefined}
        />
      </VStack>

      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Severity
        </RegularText>
        <CustomDropdown 
          items={['Low', 'Medium', 'High']} 
          placeholder="Select severity" 
          value={severityInput} 
          onChange={(e) => setSeverityInput(e.target.value)} 
          borderColor={errors.severity ? "red.500" : undefined}
        />
      </VStack>

      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Cause
        </RegularText>
        <CustomInput 
          placeholder="Insufficient employee training" 
          value={causeInput} 
          onChange={(e) => setCauseInput(e.target.value)} 
          borderColor={errors.cause ? "red.500" : undefined}
        />
      </VStack>

      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Next steps
        </RegularText>
        <CustomInput 
          placeholder="Conduct quarterly security training instead of annual" 
          value={nextStepsInput} 
          onChange={(e) => setNextStepsInput(e.target.value)} 
          borderColor={errors.nextSteps ? "red.500" : undefined}
        />
      </VStack>
    </VStack>
  );
  const modalFooter = <CustomButton onClick={handleAddHack} text='Add'></CustomButton>;


  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (taskData && taskData.length > 0) {  // Check that data is not just present but also has length
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [taskData]);

  return (
    <>
      {isLoading ? (
        <Box>
          <Table variant="simple" colorScheme='white'>
            <Thead>
              <Tr>
                <Th color="#222222" width="70%">
                  <RegularText sx={{ fontSize: 'xs' }}>Configuration</RegularText>
                </Th>
                <Th color="#222222" width="30%">
                  <RegularText sx={{ fontSize: 'xs' }}>Screenshot</RegularText>
                </Th>
              </Tr>
            </Thead>
          </Table>
          <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
          <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
        </Box>
      ) : (
        <Table variant="simple" colorScheme='white' sx={sx}>
          <Thead>
            <Tr>
              {['Incident', 'Date', 'Severity', 'Cause', 'Next steps'].map((header, index) => (
                <Th key={index} color="#222222">
                  <RegularText sx={{ fontSize: 'xs' }}>{header}</RegularText>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {hacksData.map((hack, index) => (
              <Tr key={index} color="#222222">
                <Td>
                  <RegularText sx={{ color: '#DDDDDD' }}>{hack.description}</RegularText>
                </Td>
                <Td>
                  <RegularText>{hack.date}</RegularText>
                </Td>
                <Td>
                  <CustomBadge text={hack.severity} colorScheme={roleColors[hack.severity]} />
                </Td>
                <Td>
                  <RegularText>{hack.cause}</RegularText>
                </Td>
                <Td>
                  <HStack>
                    <RegularText>{hack.next_steps}</RegularText>
                    <Spacer />
                    <ThreeDots deleteFunction={() => handleDeleteHack(hack.id)} />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <HStack mt={5}>
        <CustomOutlineButton
          onClick={openModal}
          text="+ Security incident"
        />
      </HStack>

      <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
    </>
  );
};

export default HacksTable;