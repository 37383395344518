import React, { useEffect, useState } from 'react';
import { useSession, useUser } from '@descope/react-sdk';
import { useNavigate } from 'react-router-dom';
import { grid } from 'ldrs';
import posthog from 'posthog-js';

function AdminFilter({ children }) {
    const { isAuthenticated, isSessionLoading } = useSession();
    const [isAuthorized, setIsAuthorized] = useState(null);
    const { user, isUserLoading } = useUser();
    let navigate = useNavigate();

    useEffect(() => {
        if (!isSessionLoading && !isUserLoading && isAuthenticated) {
            const userRole = user?.userTenants?.[0]?.roleNames?.[0];

            if (userRole === 'Tenant Admin' || userRole === 'Auditor') {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
        } else if (!isAuthenticated && !isSessionLoading) {
            navigate('/login');
        }
    }, [isAuthenticated, isSessionLoading, isUserLoading, user, navigate]);

    if (isSessionLoading || isUserLoading) {
        grid.register();
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                }}
            />
        );
    }

    if (isAuthenticated && isAuthorized === null) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                }}
            >
                Loading...
            </div>
        );
    }

    if (isAuthorized) {
        posthog.identify(user?.userId, {
            name: user?.name,
            email: user?.email,
            role: user?.userTenants[0].roleNames[0]
        });
        posthog.group('organization',
            {
                name: user?.userTenants[0].tenantName,
                id: user?.userTenants[0].tenantId,
            });
        return children;
    }

    if (isAuthorized === false) {
        console.log("Not authorized. Navigating to team compliance");
        navigate('/team-compliance');
        return null;
    }

    return null;
}

export default AdminFilter;