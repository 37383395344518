import React from 'react';
import { Badge } from '@chakra-ui/react';

const colorSchemes = {
  orange: { text: '#D3795A', bg: '#2F1208' },
  pink: { text: '#C265B4', bg: '#2B001C' },
  purple: { text: '#BF83FC', bg: '#220D33' },
  blue: { text: '#53B1FD', bg: '#0B162A' },
  green: { text: '#55BF79', bg: '#25332D' },
  yellow: { text: '#FACA53', bg: '#2B1A07' },
  red: { text: '#E5556B', bg: '#200014' },
  gray: { text: '#848484', bg: '#272728' },
};

const CustomBadge = ({ text, colorScheme }) => {
  const colors = colorSchemes[colorScheme] || { text: 'black', bg: 'gray.200' };

  return (
    <Badge 
      color={colors.text} 
      bg={colors.bg} 
      textTransform="none" 
      fontSize="sm" 
      fontWeight="normal"
    >
      {text}
    </Badge>
  );
};

export default CustomBadge;