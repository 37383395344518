import React, { createContext, useContext } from 'react';
import useFetchTeamData from '../hooks/useFetchTeamData';

const TeamDataContext = createContext();

export const useTeamData = () => useContext(TeamDataContext);

export const TeamDataProvider = ({ children }) => {
    const { teamData, isLoading, error } = useFetchTeamData();
    
    return (
        <TeamDataContext.Provider value={{ teamData, isLoading, error }}>
            {children}
        </TeamDataContext.Provider>
    );
};