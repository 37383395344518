import React, { useState } from 'react';
import { Button, Box, Icon, Input, HStack, Text } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import { FaUpload, FaTimes } from 'react-icons/fa';

const CustomUpload = ({ text, sx, onFileSelect, customRemoveFile }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  if (!text) {
    text = "Upload";
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Get all selected files
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const validFiles = files.filter(file => allowedTypes.includes(file.type));

    if (validFiles.length !== files.length) {
      alert('Only PDF, PNG, and JPG files are allowed.');
    }

    setSelectedFiles(validFiles);
    onFileSelect(validFiles);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter(file => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    onFileSelect(updatedFiles); // Update the parent component with the new file list
    customRemoveFile(fileToRemove); // Call the callback to remove the file
  };

  return (
    <Box>
      <Button
        as="label"
        bg='#101012'
        border="0.5px solid #222222"
        color='#848484'
        borderRadius="xl"
        mr={2}
        boxShadow='none'
        _hover={{ borderColor: '#222222', boxShadow: 'none', cursor: 'pointer' }}
        _active={{ borderColor: '#222222', boxShadow: 'none' }}
        fontSize='sm'
        sx={sx}
      >
        <Box as="span" fontSize="2xl" bg='#272728' borderRadius='xl' pl={2} pr={2} pt={0} pb={0.3} mr={2}>
          <Icon as={FaUpload} w={4} h={4} color="#848484" />
        </Box>
        <RegularText sx={{ fontWeight: 'normal' }}>Upload</RegularText>
        <Input
          type="file"
          accept=".pdf, .png, .jpg, .jpeg"
          onChange={handleFileChange}
          multiple
          display="none"
        />
      </Button>
      {text?.trim() !== "" && selectedFiles.length > 0 && (
        <Box mt={2}>
          {selectedFiles.map((file, index) => (
            <HStack key={index} mt={2} spacing={2} alignItems="center" display="inline-flex"
              variant="unstyled"
              borderRadius="xl"
              border="1px solid #222222"
              bg="transparent"
              w='auto'
              h='35px'
              p={3}
              color="#222222"
            >
              <RegularText sx={{ color: '#dddddd' }} mr={2}>{file.name}</RegularText>
              <Icon as={FaTimes} w={3} h={3} color="#848484" cursor="pointer" onClick={() => handleRemoveFile(file)} />
            </HStack>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomUpload;