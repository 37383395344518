import React from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/reportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Bland AI";
  const CompanyEmail = "hello@bland.ai";
  const AccentColor = '#524DFC';
  const ComplianceFrameworks = ["HIPAA", "SOC2Type1"];
  const PrivacyPolicyURL = "https://app.bland.ai/files/privacy_policy.pdf";

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks} PrivacyPolicyURL={PrivacyPolicyURL}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;