import { useState, useEffect } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchGlobalIntegrationData = () => {
    const [globalIntegrations, setGlobalIntegrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const toast = useToast();

    useEffect(() => {
        const fetchIntegrationData = async () => {
            const sessionToken = await getSessionToken();
            try {
                const response = await axios.get(`${API_BASE_URL}compliance/integration/global/list/`, {
                    headers: {
                        'Authorization': `Bearer ${sessionToken}`,
                        'Accept': 'application/json',
                    },
                });
                setGlobalIntegrations(response.data);
            } catch (error) {
                console.error('Error during request:', error);
                setError(error);
                posthog.capture('Error Fetching Global Integrations', {
                    error: error
                });
            } finally {
                setLoading(false);
            }
        };

        fetchIntegrationData();
    }, [toast]);

    return { globalIntegrations, loading, error };
};

export default useFetchGlobalIntegrationData;
