import React, { useState } from 'react';
import { Button, Stack, Image, Box, HStack, Input, Collapse } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import RegularText from '../text/regulartext';
import RedLogo from '../../assets/redlogo.png';

const ModalSelector = ({ options, onOptionClick }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        borderWidth="0"
        fontSize="sm"
        _placeholder={{ color: '#848484' }}
        _focus={{ borderWidth: 0, boxShadow: 'none' }}
        _hover={{ borderWidth: 0 }}
        mb={-2}
      />
      <Box borderBottom="1px" borderColor="#222222" mt={2} />
      <Box maxH='40vh' overflowY='scroll' position="relative" css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}>
        <Stack spacing={0} mt={2}>
          {options.map((option, index) => (
            <Collapse key={index} in={filteredOptions.includes(option)} w='100%'>
              <Button 
                w='100%'
                rightIcon={<ChevronRightIcon color="#848484" />} 
                variant="ghost" 
                justifyContent="space-between"
                fontWeight="normal"
                _hover={{ bg: "#272728" }}
                onClick={() => onOptionClick(option)}
              >
                <HStack>
                  <Image src={RedLogo} alt="Logo" w='20px'/>
                  <RegularText>{option}</RegularText>
                </HStack>
              </Button>
            </Collapse>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default ModalSelector;