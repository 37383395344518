import { React, useCallback } from 'react';
import { Box, Button, VStack, Image, Avatar, Spacer, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Logo from '../../assets/logo.png';
import NavbarButton from './navbarbutton';
import { useDescope } from '@descope/react-sdk'
import CustomProgressBar from './customprogressbar';

const Navbar = () => {
  const location = useLocation(); // Get the current location
  const currentPath = location.pathname; // Extract pathname from location
  const { logout } = useDescope()
  // Helper function to determine if the button is selected
  const isSelected = (path) => currentPath.includes(path);


  // Function to handle logout
  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  return (
    <Box as="nav" height="100%" width="70px" bg="#1C1C1E" p={4} display="flex" justifyContent="center" borderRightWidth="1px" borderRightColor="#222222">
      <VStack spacing={4} w='100%' h='100%'>

        {/* Logo at the top */}
        <Button onClick={() => window.location.href = '/dashboard'} variant="unstyled">
          <Image src={Logo} alt="Logo" w='100%' h='auto' objectFit='contain' />
        </Button>

        <Spacer></Spacer>

        {/* Navigation Buttons */}
        <VStack spacing={6} w='100%'>
          <NavbarButton title="Dashboard" selected={isSelected('/dashboard')} />
          <NavbarButton title="Team" selected={isSelected('/team')} />
          <NavbarButton title="Integrations" selected={isSelected('/integrations')} />
          <NavbarButton title="Compliance" selected={isSelected('/compliance')} />
        </VStack>

        <Spacer></Spacer>

        {/* Avatar at the bottom */}
        <Menu placement="right">
          <MenuButton as={Button} variant="ghost" p={0}>
            <Avatar
              // src={user?.picture}
              alt="User Profile"
              w="40px"
              h="40px"
              borderRadius="md"
            />
          </MenuButton>
          <MenuList
            minWidth="auto"
            width="max-content"
            minHeight='auto'
            p={0}
            borderRadius='md'
          >
            <MenuItem
              fontSize='sm'
              borderRadius='md'
              _hover={{
                bg: '#FCF0F0',
                color: '#D73601',
              }}
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>

      </VStack>
    </Box>
  );
};

export default Navbar;