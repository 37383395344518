import { useState, useEffect } from "react";
import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { API_BASE_URL } from "../utils/Constants";
import { useToast } from "@chakra-ui/react";
import posthog from "posthog-js";

const useFetchUserEvidenceSubmitted = (globalTaskIds) => {
  const [userListMap, setUserListMap] = useState({});
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchUserEvidence = async () => {
      const sessionToken = await getSessionToken();
      try {
        const response = await axios.post(
          `${API_BASE_URL}core/user/evidence/`,
          {
            global_task_ids: globalTaskIds, // Changed to accept an array of IDs
          },
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserListMap(response.data); // Expecting a map of task IDs to user lists
      } catch (error) {
        console.error("Error during request:", error);
        setError(error);
        toast({
          title: "Error loading user evidence",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        posthog.capture("Error Fetching User Evidence", {
          error: error,
        });
      }
    };

    if (globalTaskIds && globalTaskIds.length > 0) {
      fetchUserEvidence();
    }
  }, [globalTaskIds]);

  return { userListMap, error };
};

export default useFetchUserEvidenceSubmitted;
