// import React, { useEffect, useState } from 'react';
// import { Box } from "@chakra-ui/react";

// import ReportBody from '../../components/report/tomabody';
// import ReportFooter from '../../components/report/reportfooter';

// const Tomareport = () => {
//   const CompanyName = "Toma"; // Define the constant
//   const CompanyEmail = "founders@toma.so";

//   return (
//     <Box className="figtree" w="100%" bg="#F0F0F0">
//         <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail}/>
//         <ReportFooter/>
//     </Box>
//   );
// };

// export default Tomareport;

import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/reportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Fella";
  const CompanyEmail = "privacy@joinfella.com";
  const AccentColor = '#7ab09f';
  const ComplianceFrameworks = ["HIPAA"];
  const PrivacyPolicyURL = "https://www.fellahealth.com/privacy";

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks} PrivacyPolicyURL={PrivacyPolicyURL}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;

