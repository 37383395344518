import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/reportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Grove";
  const CompanyEmail = "founders@grovetrials.com";
  const AccentColor = '#10B981';
  const ComplianceFrameworks = ["HIPAA"];
  const PrivacyPolicyURL = "https://grovetrials.com/privacy";

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks} PrivacyPolicyURL={PrivacyPolicyURL}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;

