import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, VStack, Box, Skeleton } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';
import CustomDropdown from '../../general/customdropdown';
import { useTeamData } from '../../../contexts/TeamDataContext';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import useFetchTaskData from '../../../hooks/useFetchTaskData';

const TeamRolesTable = ({ sx }) => {
    const { teamData } = useTeamData();
    const names = teamData.map(member => member.name);
    const { updateTaskData } = useUpdateTaskData();


    const [taskData, setTaskData] = useState([]);
    const relevantTaskIDs = [
      "8089c40c-7bdf-4d63-b2f3-fc91e3ee5e66",
      "d040e1f3-9a14-4f1c-bb43-833c7e2f4c00",
    ];
    
    const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    useEffect(() => {
      setTaskData(fetchedData);
      const allTasksComplete = fetchedData.every(task => task.complete);
      setIsSubmitted(allTasksComplete);
    }, [fetchedData]);
  
    
    const handleTaskDataChange = (updatedTaskData) => {
      setTaskData(updatedTaskData);
    };



    const handleSelectionChange = (role, event) => {
        const selectedMemberName = event.target.value;
        if (selectedMemberName === "Select") {
            const updatedTaskData = taskData.map(task =>
                task.title === role ? { ...task, associated_user: null, associated_user_name: null } : task
            );
            handleTaskDataChange(updatedTaskData);
            return;
        }
        const selectedMember = teamData.find(member => member.name === selectedMemberName);
        if (!selectedMember) {
            console.error(`No team member found with name: ${selectedMemberName}`);
            return;
        }
        const updatedTaskData = taskData.map(task => {
            if (task.title === role) {
                const updatedTask = { ...task, associated_user: selectedMember.id, associated_user_name: selectedMember.name, complete: true };
                return updatedTask;
            }
            return task;
        });
        handleTaskDataChange(updatedTaskData);
        updateTaskData(updatedTaskData);
    };

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (taskData && taskData.length > 0) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [taskData]);


    return (
        <Box>

            {isLoading ? (
                <Box>
                    <Table variant="simple" colorScheme='white'>
                        <Thead>
                            <Tr>
                                <Th color="#222222" width="70%">
                                    <RegularText sx={{ fontSize: 'xs' }}>Configuration</RegularText>
                                </Th>
                                <Th color="#222222" width="30%">
                                    <RegularText sx={{ fontSize: 'xs' }}>Screenshot</RegularText>
                                </Th>
                            </Tr>
                        </Thead>
                    </Table>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
                </Box>
            ) : (
                <Table variant="simple" colorScheme='white' sx={sx}>
                    <Thead>
                        <Tr>
                            <Th color="#222222" w='70%'>
                                <RegularText sx={{ fontSize: 'xs' }}>Role</RegularText>
                            </Th>
                            <Th color="#222222" w='30%'>
                                <RegularText sx={{ fontSize: 'xs' }}>Team Member</RegularText>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {taskData.map((task, index) => (
                            <Tr key={index} color="#222222">
                                <Td>
                                    <VStack align="start" spacing={0}>
                                        <RegularText sx={{ color: '#dddddd' }}>{task.title}</RegularText>
                                        <RegularText sx={{ color: '#848484' }}>
                                            {task.description}
                                        </RegularText>
                                    </VStack>
                                </Td>
                                <Td>
                                    <CustomDropdown
                                        items={['Select', ...names]}
                                        value={teamData.find(member => member.id === task.associated_user)?.name || 'Select'}
                                        sx={{ bg: '#1C1C1E' }}
                                        onChange={(event) => handleSelectionChange(task.title, event)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};

export default TeamRolesTable;