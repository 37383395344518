import {
    Box,
    HStack,
    Icon,
    Image,
    Link,
    Skeleton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import posthog from "posthog-js";
import { FaTimes } from "react-icons/fa";
import { useIntegrationData } from "../../../contexts/IntegrationDataContext";
import useFetchAccountReviews from "../../../hooks/useFetchAccountReviews";
import useFetchSecurityReviews from "../../../hooks/useFetchSecurityReviews";
import CustomButton from "../../general/custombutton";
import CustomDropdown from "../../general/customdropdown";
import CustomInput from "../../general/custominput";
import CustomOutlineButton from "../../general/customoutlinebutton";
import CustomSingleUpload from "../../general/customsingleupload";
import CustomModal from "../../modal/custommodal";
import RegularText from "../../text/regulartext";
import SubtitleText from "../../text/subtitletext";

import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Constants";

import useFetchTaskData from "../../../hooks/useFetchTaskData";
import useUpdateTaskData from "../../../hooks/useUpdateTaskData";

const TeamTable = ({ sx, clickedIntegration }) => {


    
    const { integrationData, error, refetch } = useIntegrationData();
    const {
        securityReviews,
        refetch: fetchSecurityReviews,
        error: securityReviewsError,
    } = useFetchSecurityReviews();
    const {
        accountReviews,
        refetch: fetchAccountReviews,
        error: accountReviewsError,
    } = useFetchAccountReviews();

    const [data, setData] = useState([]);

    const [riskLevel, setRiskLevel] = useState("Low");
    const [securityPractices, setSecurityPractices] = useState(
        "Conducts annual SOC2 & HIPAA audits"
    );
    const [remediation, setRemediation] = useState("Only pass in certain PHI");
    const [beforeFile, setBeforeFile] = useState(null);
    const [accountChanges, setAccountChanges] = useState(null);
    const [afterFile, setAfterFile] = useState(null);
    const toast = useToast();

    const [imageError, setImageError] = useState({});

    const handleImageError = (integrationId) => {
        setImageError((prev) => ({ ...prev, [integrationId]: true }));
    };

    // Helper function to format integration data
    const formatIntegrationData = (integrationData) => {
        return integrationData
            .map((integration) => ({
                id: integration.id,
                name: integration.name,
                category: integration.category,
                status: `Connected ${new Date(
                    integration.created
                ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                })}`,
                agreement: integration.agreement,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    // Helper function to format review data
    const formatReviewData = (reviews) => {
        return reviews.map((review) => ({
            id: review.id,
            name: review.name,
            date: new Date(review.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
            }),
            riskLevel: review.risk_level,
            securityPractices: review.security_practices,
            remediation: review.remediation,
            organizationIntegrationId: review.organization_integration,
        }));
    };

    // Helper function to format account data
    const formatAccountData = (accounts) => {
        return accounts.map((account) => ({
            id: account.id,
            name: account.name,
            date: new Date(account.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
            }),
            beforeAccountsUpload: account.before_accounts_upload,
            accountsToBeAddedRemoved: account.account_changes,
            afterAccountsUpload: account.after_accounts_upload,
            fileUrl: account.file_url,
            organizationIntegrationId: account.organization_integration,
        }));
    };

    // TODO: fix the useffect so that it actually changes when the integrationDatachanges. right now the integrationdata would not be pulled so it wouldn't change
    useEffect(() => {
        if (integrationData && securityReviews && accountReviews) {
            const formattedIntegrationData =
                formatIntegrationData(integrationData);
            const formattedReviewData = formatReviewData(securityReviews);
            const formattedAccountData = formatAccountData(accountReviews);

            // Map reviews and accounts to the correct integration
            const integrationWithReviewsAndAccounts =
                formattedIntegrationData.map((integration) => ({
                    ...integration,
                    reviews: formattedReviewData.filter(
                        (review) =>
                            review.organizationIntegrationId === integration.id
                    ),
                    accounts: formattedAccountData.filter(
                        (account) =>
                            account.organizationIntegrationId === integration.id
                    ),
                }));

            setData(integrationWithReviewsAndAccounts);
        }
    }, [integrationData, securityReviews, accountReviews]);

    const handleAgreementUpload = async (file, integrationId) => {
        const token = getSessionToken();
        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "2c793912-ae2c-4b55-acfa-fa172a5ffc34"
            ) {
                const evidence = `Added BAA signed with integration ID ${integrationId}.`;
                posthog.capture("Added Evidence for Task", { evidence });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        const formData = new FormData();
        formData.append("integration_id", integrationId);
        formData.append("agreement", file);

        try {
            const response = await axios.post(
                `${API_BASE_URL}core/org/integration/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: "File uploaded successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            refetch();
            posthog.capture("Uploaded File for Integration", {
                integrationId: integrationId,
                fileName: file.name,
            });
        } catch (error) {
            console.error("Error uploading file:", error);
            posthog.capture("Error Uploading File for Integration", {
                error: error,
            });
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("review"); // 'review' or 'account'
    const [title, setTitle] = useState(new Date().toLocaleDateString());
    const [reviews, setReviews] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [activeRow, setActiveRow] = useState(0);
    const [modalReviewData, setModalReviewData] = useState(null);
    const [modalAccountData, setModalAccountData] = useState(null);

    const openModal = (content, index) => {
        setModalContent(content);
        setActiveRow(index);
        setIsModalOpen(true);
    };

    const openReviewModal = (content, index, reviewData = null) => {
        setModalContent(content);
        setActiveRow(index);
        setModalReviewData(reviewData); // Set the review data for the modal
        setIsModalOpen(true);
    };

    const openAccountModal = (content, index, accountData = null) => {
        setModalContent(content);
        setActiveRow(index);
        setModalAccountData(accountData);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalReviewData(null); // Clear the review data when closing the modal
        setModalAccountData(null);
    };

    const relevantTaskIDs = [
        "15e66f71-008c-460e-b24c-47d6ae56409b",
        "2c793912-ae2c-4b55-acfa-fa172a5ffc34",
        "85f54cf5-8740-4fa6-875b-905174a8df53",
    ];
    const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
    const [taskData, setTaskData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);
    const { updateTaskData } = useUpdateTaskData();

    useEffect(() => {
        setTaskData(fetchedData);
        const allTasksComplete = fetchedData.every((task) => task.complete);
        setIsSubmitted(allTasksComplete);
        setIsSubmittedChecked(true);
    }, [fetchedData]);

    const addReview = async () => {
        const token = getSessionToken();
        const newReviews = [...reviews];
        newReviews[activeRow] = newReviews[activeRow]
            ? [...newReviews[activeRow], title]
            : [title];
        setReviews(newReviews);

        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "85f54cf5-8740-4fa6-875b-905174a8df53"
            ) {
                const evidence = `Added vendor security review for ${data[activeRow].name} with risk level: ${riskLevel}, existing security practices of the vendor: ${securityPractices}, and remediation: ${remediation}. Marked test as complete.`;
                posthog.capture("Added Evidence for Task", { evidence });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        try {
            const response = await axios.post(
                `${API_BASE_URL}core/org/review/security/`,
                {
                    organization_integration_id: data[activeRow].id,
                    risk_level: riskLevel,
                    security_practices: securityPractices,
                    remediation: remediation,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchSecurityReviews();
            toast({
                title: "Security review added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            posthog.capture("Added Security Review for Integration", {
                integrationId: data[activeRow].id,
                riskLevel: riskLevel,
                securityPractices: securityPractices,
                remediation: remediation,
            });
        } catch (error) {
            posthog.capture("Error Adding Security Review for Integration", {
                error: error,
            });
        } finally {
            closeModal();
        }
    };

    const addAccount = async () => {
        const token = getSessionToken();
        const newAccounts = [...accounts];
        newAccounts[activeRow] = newAccounts[activeRow]
            ? [...newAccounts[activeRow], title]
            : [title];
        setAccounts(newAccounts);

        const formData = new FormData();
        formData.append("organization_integration_id", data[activeRow].id);
        formData.append("before_accounts_upload", beforeFile);
        formData.append("account_changes", accountChanges);
        formData.append("after_accounts_upload", afterFile);

        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "15e66f71-008c-460e-b24c-47d6ae56409b"
            ) {
                const evidence = `Completed access request ticket for ${data[activeRow].name} and marked test as complete.`;
                posthog.capture("Task Completed", {
                    taskId: task.global_task_id,
                    evidence,
                });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        try {
            const response = await axios.post(
                `${API_BASE_URL}core/org/review/account/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchAccountReviews();
            toast({
                title: "Account review added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            posthog.capture("Added Account Review for Integration", {
                integrationId: data[activeRow].id,
                beforeAccountsUpload: beforeFile.name,
                accountsToBeAddedRemoved: accountChanges,
                afterAccountsUpload: afterFile.name,
            });
        } catch (error) {
            console.error("Error adding account review:", error);
            toast({
                title: "Error adding account review",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            closeModal();
        }
    };

    const removeReview = async (reviewId) => {
        const token = getSessionToken();
        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "85f54cf5-8740-4fa6-875b-905174a8df53"
            ) {
                const evidence = `Removed vendor security review for review ID ${reviewId}.`;
                posthog.capture("Removed Security Review for Integration", {
                    evidence,
                });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        try {
            const response = await axios.delete(
                `${API_BASE_URL}core/org/review/security/`,
                {
                    data: { security_review_id: reviewId },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchSecurityReviews();
            toast({
                title: "Security review removed",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            posthog.capture("Removed Security Review for Integration", {
                integrationId: data[activeRow].id,
                reviewId: reviewId,
            });
        } catch (error) {
            posthog.capture("Error Removing Security Review for Integration", {
                error: error,
            });
        }
    };

    const removeAccount = async (accountId) => {
        const token = getSessionToken();
        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "15e66f71-008c-460e-b24c-47d6ae56409b"
            ) {
                const evidence = `Removed vendor access request ticket for account ID ${accountId}.`;
                posthog.capture("Removed Account Review for Integration", {
                    evidence,
                });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        try {
            const response = await axios.delete(
                `${API_BASE_URL}core/org/review/account/`,
                {
                    data: { account_review_id: accountId },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchAccountReviews();
            toast({
                title: "Account review removed",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            posthog.capture("Removed Account Review for Integration", {
                integrationId: data[activeRow].id,
                accountId: accountId,
            });
        } catch (error) {
            posthog.capture("Error Removing Account Review for Integration", {
                error: error,
            });
        }
    };

    const handleDeleteAgreement = async (integrationId) => {
        const token = getSessionToken();
        const updatedTasks = taskData.map((task) => {
            if (
                task.global_task_id === "2c793912-ae2c-4b55-acfa-fa172a5ffc34"
            ) {
                const evidence = `Removed BAA signed with integration ID ${integrationId}.`;
                posthog.capture("Removed Agreement for Integration", {
                    evidence,
                });
                return { ...task, complete: true, evidence };
            }
            return task;
        });
        updateTaskData(updatedTasks);

        try {
            await axios.post(
                `${API_BASE_URL}core/org/integration/`,
                {
                    integration_id: integrationId,
                    delete_agreement: true,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: "Agreement deleted successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            refetch();
            posthog.capture("Deleted Agreement for Integration", {
                integrationId: integrationId,
            });
        } catch (error) {
            console.error("Error deleting agreement:", error);
            posthog.capture("Error Deleting Agreement for Integration", {
                error: error,
            });
        }
    };

    const getModalHeader = () => {
        return (
            <SubtitleText>
                {modalContent === "review"
                    ? "Review security"
                    : "Review accounts"}
            </SubtitleText>
        );
    };

    const getModalBody = () => {
        return (
            <VStack spacing={6} align="left">
                {modalContent === "review" && modalReviewData && (
                    <>
                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Risk level
                            </RegularText>
                            <CustomDropdown
                                items={[
                                    "Low (minimal impact if they go down)",
                                    "Medium (you rely on them a fair amount)",
                                    "High (your business significantly depends on them)",
                                ]}
                                placeholder="Select risk level"
                                value={modalReviewData.riskLevel}
                                // disabled={true}
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                What security practices does the vendor have in
                                place?
                            </RegularText>
                            <CustomInput
                                placeholder="Conducts annual SOC2 & HIPAA audits"
                                value={modalReviewData.securityPractices}
                                // disabled={true}
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                How will you make sure you are using the vendor
                                securely?
                            </RegularText>
                            <CustomInput
                                placeholder="Only pass in certain PHI"
                                value={modalReviewData.remediation}
                                // disabled={true}
                            />
                        </VStack>
                    </>
                )}

                {modalContent === "account" && modalAccountData && (
                    <>
                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Who currently has an account? Upload a "before"
                                screenshot.
                            </RegularText>
                            <HStack
                                mt={2}
                                spacing={2}
                                alignItems="center"
                                display="inline-flex"
                                variant="unstyled"
                                borderRadius="xl"
                                border="1px solid #222222"
                                bg="#171719"
                                w="fit-content"
                                h="35px"
                                p={3}
                                color="#222222"
                            >
                                <Link
                                    fontSize="sm"
                                    href={modalAccountData.beforeAccountsUpload}
                                    isExternal
                                    color="blue.500"
                                >
                                    View image
                                </Link>
                            </HStack>
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Do any accounts need to be added/removed? Why?
                            </RegularText>
                            <CustomInput
                                placeholder="Remove contractor IAM role"
                                value={
                                    modalAccountData.accountsToBeAddedRemoved
                                }
                                // disabled={true}
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                After you've made the changes, upload an "after"
                                screenshot.
                            </RegularText>
                            <HStack
                                mt={2}
                                spacing={2}
                                alignItems="center"
                                display="inline-flex"
                                variant="unstyled"
                                borderRadius="xl"
                                border="1px solid #222222"
                                bg="#171719"
                                w="fit-content"
                                h="35px"
                                p={3}
                                color="#222222"
                            >
                                <Link
                                    fontSize="sm"
                                    href={modalAccountData.afterAccountsUpload}
                                    isExternal
                                    color="blue.500"
                                >
                                    View image
                                </Link>
                            </HStack>
                        </VStack>
                    </>
                )}

                {modalContent === "account" && modalAccountData == null && (
                    <>
                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Who currently has an account? Upload a "before"
                                screenshot.
                            </RegularText>
                            <CustomSingleUpload onFileSelect={setBeforeFile} />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Do any accounts need to be added/removed? Why?
                            </RegularText>
                            <CustomInput
                                placeholder="Remove contractor IAM role"
                                onChange={(event) =>
                                    setAccountChanges(event.target.value)
                                }
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                After you've made the changes, upload an "after"
                                screenshot.
                            </RegularText>
                            <CustomSingleUpload onFileSelect={setAfterFile} />
                        </VStack>
                    </>
                )}

                {modalContent === "review" && modalReviewData == null && (
                    <>
                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                Risk level
                            </RegularText>
                            <CustomDropdown
                                items={[
                                    "Low (minimal impact if they go down)",
                                    "Medium (you rely on them a fair amount)",
                                    "High (your business significantly depends on them)",
                                ]}
                                placeholder="Select risk level"
                                onChange={(event) =>
                                    setRiskLevel(event.target.value)
                                }
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                What security practices does the vendor have in
                                place?
                            </RegularText>
                            <CustomInput
                                placeholder="Conducts annual SOC2 & HIPAA audits"
                                onChange={(event) =>
                                    setSecurityPractices(event.target.value)
                                }
                            />
                        </VStack>

                        <VStack align="left">
                            <RegularText sx={{ color: "#dddddd" }}>
                                How will you make sure you are using the vendor
                                securely?
                            </RegularText>
                            <CustomInput
                                placeholder="Only pass in certain PHI"
                                onChange={(event) =>
                                    setRemediation(event.target.value)
                                }
                            />
                        </VStack>
                    </>
                )}
            </VStack>
        );
    };

    const getModalFooter = () => {
        if (modalAccountData !== null || modalReviewData !== null) {
            return <CustomButton onClick={closeModal}>Close</CustomButton>;
        }
        return (
            <CustomButton
                onClick={modalContent === "review" ? addReview : addAccount}
            >
                Add
            </CustomButton>
        );
    };

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (taskData && taskData.length > 0) {
            // Check that data is not just present but also has length
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [taskData]);

    return (
        <>
            {isLoading ? (
                <Box>
                    <Table variant="simple" colorScheme="white">
                        <Thead>
                            <Tr>
                                <Th color="#222222" width="70%">
                                    <RegularText sx={{ fontSize: "xs" }}>
                                        Configuration
                                    </RegularText>
                                </Th>
                                <Th color="#222222" width="30%">
                                    <RegularText sx={{ fontSize: "xs" }}>
                                        Screenshot
                                    </RegularText>
                                </Th>
                            </Tr>
                        </Thead>
                    </Table>
                    <Skeleton
                        height="2rem"
                        ml="20px"
                        mr="20px"
                        mt="20px"
                        mb="20px"
                        startColor="#333333"
                        endColor="#222222"
                    />
                </Box>
            ) : (
                <Table variant="simple" colorScheme="white" sx={sx}>
                    <Thead>
                        <Tr>
                            <Th color="#222222" width="40%">
                                <RegularText sx={{ fontSize: "xs" }}>
                                    Security Reviews
                                </RegularText>
                            </Th>
                            <Th color="#222222" width="40%">
                                <RegularText sx={{ fontSize: "xs" }}>
                                    Account Reviews
                                </RegularText>
                            </Th>
                            <Th color="#222222" width="5%">
                                <RegularText sx={{ fontSize: "xs" }}>
                                    Agreement (BAA/DPA)
                                </RegularText>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((integration, index) => {
                            return clickedIntegration === integration.name && (
                                <Tr key={index} color="#222222">
                                    <Td>
                                        <CustomOutlineButton
                                            text="+ Review security"
                                            onClick={() => {
                                                openModal("review");
                                                setActiveRow(index);
                                            }}
                                        />
                                        <VStack mt={2} align="start">
                                            {Array.isArray(integration.reviews) &&
                                                integration.reviews.map(
                                                    (review, reviewIndex) => (
                                                        <HStack
                                                            key={reviewIndex}
                                                            alignItems="center"
                                                            display="inline-flex"
                                                            variant="unstyled"
                                                            borderRadius="xl"
                                                            border="1px solid #222222"
                                                            bg="transparent"
                                                            w="auto"
                                                            h="35px"
                                                            p={3}
                                                            color="#222222"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                openReviewModal(
                                                                    "review",
                                                                    index,
                                                                    review
                                                                )
                                                            }
                                                        >
                                                            <RegularText
                                                                sx={{
                                                                    color: "#dddddd",
                                                                    cursor: "pointer",
                                                                }}
                                                                mr={2}
                                                            >
                                                                {review.date}
                                                            </RegularText>
                                                            <Icon
                                                                as={FaTimes}
                                                                w={3}
                                                                h={3}
                                                                color="#848484"
                                                                cursor="pointer"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    removeReview(
                                                                        review.id
                                                                    );
                                                                }}
                                                            />
                                                        </HStack>
                                                    )
                                                )}
                                        </VStack>
                                    </Td>
                                    <Td>
                                        <CustomOutlineButton
                                            text="+ Review accounts"
                                            onClick={() => {
                                                openModal("account");
                                                setActiveRow(index);
                                            }}
                                        />
                                        <VStack mt={2} align="start">
                                            {Array.isArray(integration.accounts) &&
                                                integration.accounts.map(
                                                    (account, accountIndex) => (
                                                        <HStack
                                                            key={accountIndex}
                                                            alignItems="center"
                                                            display="inline-flex"
                                                            variant="unstyled"
                                                            borderRadius="xl"
                                                            border="1px solid #222222"
                                                            bg="transparent"
                                                            w="auto"
                                                            h="35px"
                                                            p={3}
                                                            color="#222222"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                openAccountModal(
                                                                    "account",
                                                                    index,
                                                                    account
                                                                )
                                                            }
                                                        >
                                                            <RegularText
                                                                sx={{
                                                                    color: "#dddddd",
                                                                    cursor: "pointer",
                                                                }}
                                                                mr={2}
                                                            >
                                                                {account.date}
                                                            </RegularText>
                                                            <Icon
                                                                as={FaTimes}
                                                                w={3}
                                                                h={3}
                                                                color="#848484"
                                                                cursor="pointer"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    removeAccount(
                                                                        account.id
                                                                    );
                                                                }}
                                                            />
                                                        </HStack>
                                                    )
                                                )}
                                        </VStack>
                                    </Td>
                                    <Td>
                                        {integration.agreement ? (
                                            <HStack>
                                                {!imageError[integration.id] ? (
                                                    <>
                                                        <Image
                                                            src={
                                                                integration.agreement
                                                            }
                                                            alt="Agreement Thumbnail"
                                                            boxSize="50px"
                                                            objectFit="cover"
                                                            onError={() =>
                                                                handleImageError(
                                                                    integration.id
                                                                )
                                                            }
                                                        />
                                                        <Icon
                                                            as={FaTimes}
                                                            w={3}
                                                            h={3}
                                                            color="#848484"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                handleDeleteAgreement(
                                                                    integration.id
                                                                )
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <HStack
                                                        mt={2}
                                                        spacing={2}
                                                        alignItems="center"
                                                        display="inline-flex"
                                                        variant="unstyled"
                                                        borderRadius="xl"
                                                        border="1px solid #222222"
                                                        bg="transparent"
                                                        w="auto"
                                                        h="35px"
                                                        p={3}
                                                        color="#222222"
                                                    >
                                                        <Link
                                                            fontSize="sm"
                                                            href={
                                                                integration.agreement
                                                            }
                                                            isExternal
                                                            color="blue.500"
                                                        >
                                                            View agreement
                                                        </Link>
                                                        <Icon
                                                            as={FaTimes}
                                                            w={3}
                                                            h={3}
                                                            color="#848484"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                handleDeleteAgreement(
                                                                    integration.id
                                                                )
                                                            }
                                                        />
                                                    </HStack>
                                                )}
                                            </HStack>
                                        ) : (
                                            <CustomSingleUpload
                                                placeholder="Select"
                                                sx={{ bg: "#1C1C1E" }}
                                                onFileSelect={(file) =>
                                                    handleAgreementUpload(
                                                        file,
                                                        integration.id
                                                    )
                                                }
                                            />
                                        )}
                                    </Td>
                                </Tr>
                            )
})}
                    </Tbody>
                </Table>
            )}
            <CustomModal
                isOpen={isModalOpen}
                onClose={closeModal}
                modalHeader={getModalHeader()}
                modalBody={getModalBody()}
                modalFooter={getModalFooter()}
            />
        </>
    );
};

export default TeamTable;
