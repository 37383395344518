import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { API_BASE_URL } from "../utils/Constants";
import { useToast } from "@chakra-ui/react";
import posthog from "posthog-js";

const useFetchTaskData = (taskIDs) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const memoizedTaskIDs = useMemo(() => taskIDs, [JSON.stringify(taskIDs)]);

  const fetchTaskIDData = async () => {
    const token = await getSessionToken();
    const queryParams = memoizedTaskIDs.map((id) => `task_ids=${id}`).join("&");
    const url = `${API_BASE_URL}core/org/task/?${queryParams}`;

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching task data:", error);
      setError(error);
      posthog.capture("Error Fetching Task Data", {
        error: error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskIDData();
  }, [memoizedTaskIDs]);

  return { data, error, loading, refetch: fetchTaskIDData };
};

export default useFetchTaskData;
