import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, VStack, HStack, Collapse, Icon, Box, Link, Skeleton } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';
import { FaChevronDown, FaCheck } from 'react-icons/fa';
import CustomUpload from '../../general/customupload';
import { FaTimes } from 'react-icons/fa';
import useHandleFiles from '../../../hooks/useHandleFiles';
import CustomOutlineButton from '../../general/customoutlinebutton';

const TeamTable = ({ taskData = [], sx, onTaskDataChange, updateTaskData, refetch }) => {
  const [openTask, setOpenTask] = useState(null);
  const [agreedTasks, setAgreedTasks] = useState({});
  const [displayData, setDisplayData] = useState([...taskData]);
  const { handleFileUpload, handleRemoveFile, refreshData } = useHandleFiles(taskData, onTaskDataChange);

  const handleFileSelection = (file, taskId, task) => {
    handleFileUpload(file, taskId);
    const updatedTasks = taskData.map(task => ({ ...task, complete: true, evidence: `Marked task ${task.name} as complete` }));
    updateTaskData(updatedTasks);
  };

  const [forceUpdate, setForceUpdate] = useState(false);

  // useEffect(() => {
  //   if (refetch) {
  //       refetch();
  //   }
  // }, [refetch]);

  // useEffect(() => {
  //   setForceUpdate(prev => !prev);
  // }, [refreshData]);

  useEffect(() => {
    const initialAgreedTasks = {};
    taskData.forEach(task => {
      initialAgreedTasks[task?.title || ''] = task?.complete || false;
    });
    setAgreedTasks(initialAgreedTasks);
  }, [agreedTasks]);

  const toggleTask = (task) => {
    setOpenTask(openTask === task ? null : task);
  };

  const toggleAgreement = (task) => {
    if (!task) return; 
    const newAgreementStatus = !agreedTasks[task];
    setAgreedTasks(prev => ({
      ...prev,
      [task]: newAgreementStatus
    }));
  
    const updatedTaskData = taskData.map(t => 
      (t && t.title === task) ? { ...t, complete: newAgreementStatus, evidence: 'Procedure agreed to.' } : t
    );
  
    onTaskDataChange(updatedTaskData);
    updateTaskData(updatedTaskData);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      if (taskData && taskData.length > 0) {
          setIsLoading(false);
      } else {
          setIsLoading(true);
      }
  }, [taskData]);

  

  return (
    <>
      {isLoading ? (
        <Box>
            <Table variant="simple" colorScheme='white'>
                <Thead>
                    <Tr>
                        <Th color="#222222" width="70%">
                            <RegularText sx={{ fontSize: 'xs' }}>Configuration</RegularText>
                        </Th>
                        <Th color="#222222" width="30%">
                            <RegularText sx={{ fontSize: 'xs' }}>Screenshot</RegularText>
                        </Th>
                    </Tr>
                </Thead>
            </Table>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222" />
        </Box>
      ) : (
      <Table variant="simple" colorScheme='white' sx={sx}>
        <Thead>
          <Tr>
            <Th color="#222222" width="70%">
              <RegularText sx={{fontSize: 'xs'}}>Procedure</RegularText>
            </Th>
            <Th color="#222222" width="30%">
              <RegularText sx={{fontSize: 'xs'}}>I agree to follow this procedure</RegularText>
            </Th>
            <Th color="#222222" width="30%">
              <RegularText sx={{fontSize: 'xs'}}>Evidence</RegularText>
            </Th>
          </Tr>
        </Thead>
        
        <Tbody>
        {taskData.filter(task => task).map((task, index) => (
    <Tr key={`${task?.id || index}-${agreedTasks[task?.title]}`} color="#222222">
      <Td>
        <VStack align="start" spacing={0} width="100%">
          <RegularText sx={{ color: '#dddddd'}}>{task?.title}</RegularText>
          <HStack variant="unstyled" p={0} m={0} onClick={() => toggleTask(task?.title)} display="flex" alignItems="center" cursor='pointer'>
            <RegularText sx={{ fontWeight: 'normal'}}>
              {openTask === task?.title ? 'Hide procedure' : 'View procedure'}
            </RegularText>
            <Icon 
              as={FaChevronDown} 
              transform={openTask === task?.title ? 'rotate(180deg)' : 'rotate(0deg)'} 
              transition="transform 0.2s"
              w={2}
              h={2}
              color="#848484"
            />
          </HStack>
          <Collapse in={openTask === task?.title}>
            <RegularText sx={{ color: '#848484', whiteSpace: 'pre-wrap', mt:3 }}>
              {task?.description}
            </RegularText>
            {task?.document_html && (
              <CustomOutlineButton sx={{mt:3}} text='Template' onClick={() => window.open(task?.document_html, '_blank')}></CustomOutlineButton>
            )}
          </Collapse>
        </VStack>
      </Td>
      <Td>
        <HStack cursor="pointer" onClick={() => toggleAgreement(task?.title)}>
          <Box
            w='18px'
            h='18px'
            borderRadius='md'
            border='1px solid'
            borderColor={agreedTasks[task?.title] ? '#D73601' : '#D73601'}
            bg={agreedTasks[task?.title] ? '#D73601' : 'transparent'}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {agreedTasks[task?.title] && (
              <Icon as={FaCheck} color='#1c1c1e' w={3} h={3} />
            )}
          </Box>
          <RegularText sx={{color: '#dddddd'}}>I agree</RegularText>
        </HStack>
      </Td>
      <Td>
        <CustomUpload
            placeholder="Select"
            sx={{ bg: '#1C1C1E' }}
            onFileSelect={(file) => handleFileSelection(file, task?.id, task)}
            customRemoveFile={(file) => handleRemoveFile(task?.id, file.id)}
            text="Upload"
        />
        {task?.files && task?.files.length > 0 && task?.files.map((file, fileIndex) => (
            <HStack key={`${task?.id}-${fileIndex}`} mt={2} spacing={2} alignItems="center" display="inline-flex"
                variant="unstyled"
                borderRadius="xl"
                border="1px solid #222222"
                bg="transparent"
                w='auto'
                h='35px'
                p={3}
                color="#222222"
            >
                <Link fontSize='sm' href={file.file_url} isExternal color="blue.500">
                    {file.file_name.split('/').pop()}
                </Link>
                <Icon as={FaTimes} w={3} h={3} color="#848484" cursor="pointer" onClick={() => handleRemoveFile(task.id, file.id)} />
            </HStack>
        ))}
      </Td>
    </Tr>
  ))}
</Tbody>
        </Table>
      )}
    </>
  );
};

export default TeamTable;