import React, { useState, useEffect } from 'react';
import { Box, HStack, Spacer } from '@chakra-ui/react';
import IntegrationTestView from './integrationtestview';
import CustomButton from '../../general/custombutton';
import RegularText from '../../text/regulartext';
import WhiteSubtitleText from '../../text/whitesubtitletext';

import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import posthog from "posthog-js";
import { API_BASE_URL } from "../../../utils/Constants";

const AWSTestsView = ({ clickedResource, selectedResourceTests, setSelectedResourceTests, clickedIntegration, setSelectedTest, setIsTestModalOpen }) => {

    const [lastTestRun, setLastTestRun] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [testButtonText, setTestButtonText] = useState("Run tests");
    const [integrationResources, setIntegrationResources] = useState([]);


    const fetchTests = async (resourceID, resourceName) => {
        // setSelectedResourceTests([]);
        setIsLoading(true);
        const token = await getSessionToken();
        try {
            const response = await axios.get(
                `${API_BASE_URL}compliance/integration/test/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                    params: {
                        organization_service_id: resourceID,
                    },
                }
            );

            // Log the entire response to check the data structure

            const testsWithResourceName = response.data.map(test => ({
                ...test,
                resourceName: resourceName  // Add the resource name to each test object
            }));
            setSelectedResourceTests(prevTests => [...prevTests, ...testsWithResourceName]);

            // Log all test run dates
            const testRunDates = response.data.map(test => test.latest_test_run?.created);
            console.log("Test run dates:", testRunDates);

            // Use the specific line to get the second test's run date
            const lastTestRunDate = response.data[1]?.latest_test_run?.created;
            console.log("Selected test run date:", lastTestRunDate);

            const formattedDate = lastTestRunDate
                ? new Date(lastTestRunDate).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      timeZoneName: "short",
                  })
                : "Never";

            if (formattedDate === "Never") {
                setLastTestRun("Click the \"Run tests\" button on the right to run these tests.");
            } else {
                setLastTestRun(`Tests last run on ${formattedDate}`);
            }

            posthog.capture("Fetched Tests for Integration", {
                integrationId: resourceID,
            });
        } catch (error) {
            console.error("Error during request:", error);
            setLastTestRun("Error fetching test data");
            posthog.capture("Error Fetching Tests for Integration", {
                error: error,
            });
        }
        setIsLoading(false);
    };


    const runTests = async (formattedResource) => {
        const token = await getSessionToken();
        setTestButtonText("Running tests...");
        try {
            await axios.post(
                `${API_BASE_URL}compliance/integration/test/`,
                { organization_service_id: formattedResource.id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                }
            );
            fetchTests(formattedResource.id, formattedResource.name);
            console.log('fetching tests for resource', formattedResource.id, formattedResource.name);
            posthog.capture("Ran Tests for Integration Resource", {
                resourceId: formattedResource.id,
            });
            console.log("Test initiated for resource:", formattedResource.id);
            setTestButtonText("Re-run tests");
        } catch (error) {
            console.error("Error during request:", error);
            setTestButtonText("Run tests");
            posthog.capture("Error Running Test for Resource", {
                error: error,
            });
        }
    };



    useEffect(() => {
        if (clickedResource) {
            fetchTests(clickedResource.id, clickedResource.name);
        }
    }, [clickedResource]);



  return (
    <Box
      mt="2vh"
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      gap={2}
    >
      <WhiteSubtitleText>{clickedResource.name}</WhiteSubtitleText>
      <HStack mb={2}>
        <RegularText>{lastTestRun}</RegularText>
        <Spacer />
        <CustomButton
          text={testButtonText}
          onClick={() => runTests(clickedResource)}
        />
      </HStack>
      {Array.from(new Set(
        (clickedResource ? selectedResourceTests.filter(test => test.resourceName === clickedResource.name) : selectedResourceTests)
        ?.map(test => test.name)
      )).map((uniqueName, index) => (
        <IntegrationTestView
          key={index}
          onSelect={() => {
            const selectedTest = selectedResourceTests.find(test => test.name === uniqueName);
            if (selectedTest) {
              setSelectedTest(selectedTest);
              setIsTestModalOpen(true);
            } else {
              console.error('No test found with name:', uniqueName);
            }
          }}
          infraName={uniqueName}
          infraDescription={selectedResourceTests.find(test => test.name === uniqueName)?.description}
          status={selectedResourceTests.find(test => test.name === uniqueName)?.latest_test_run?.status || ""}
          resourceName={selectedResourceTests.find(test => test.name === uniqueName)?.resourceName}
          showProgress={false}
        />
      ))}
    </Box>
  );
};

export default AWSTestsView;