import { useState, useEffect } from "react";
import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { API_BASE_URL } from "../utils/Constants";
import { useToast } from "@chakra-ui/react";
import posthog from "posthog-js";

const useFetchTeamData = () => {
  const [teamData, setTeamData] = useState([]);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchTeamData = async (retryCount = 0) => {
      const sessionToken = await getSessionToken();
      const maxRetries = 5;
      const delay = Math.min(2 ** retryCount * 1000, 60000); // Exponential backoff with a cap at 1 minute

      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel(`Request timed out after ${delay / 1000} seconds`);
      }, 3000);

      try {
        const response = await axios.get(`${API_BASE_URL}core/org/team/`, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            Accept: "application/json",
          },
          cancelToken: source.token,
        });
        clearTimeout(timeout);
        setTeamData(response.data);
      } catch (error) {
        clearTimeout(timeout);
        if (axios.isCancel(error)) {
          console.warn("Request canceled:", error.message);
        } else {
          console.error("Error during request:", error);
          posthog.capture("Error Fetching Team Data", { error: error });
          setError(error);
        }

        if (retryCount < maxRetries) {
          setTimeout(() => fetchTeamData(retryCount + 1), delay);
        }
      }
    };

    fetchTeamData();
  }, [toast]);

  return { teamData, error };
};

export default useFetchTeamData;
