import React from 'react';
import { HStack, VStack, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import TeamTable from './teamtable';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ teamData }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
        <TitleText>Add team members</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            Invite all of your team members and contractors.
          </RegularText>
        </VStack>
      </HStack>
      <TeamTable teamData={teamData}/>
    </Box>
  );
};

export default AddTeamMember;