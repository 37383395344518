import React, { useState, useEffect } from 'react';
import { HStack, VStack, Spacer, Skeleton, Box, Grid } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import DocumentsBox from './documentsbox';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import TitleText from '../../text/titletext';

const SignDocuments = () => {

  const [taskData, setTaskData] = useState([]);

  const relevantTaskIDs = [
    "5e2537c0-9065-401d-901c-14d546b7cea6",
    "fa8fa5dd-0c3c-4377-ac3d-eb7be47aac57",
    "e80bdaf4-6c13-4584-84e7-b33c5c61ebfc",
    "e3823cac-700c-4f8c-b623-2c8fbfeec35d",
    "e1c3d329-1399-4a1f-8a92-5b129c7d1c1b",
    "d141a5f8-afde-4d2b-b45c-0ca287624977",
    "ce60f286-e51e-4d03-b308-1e66201e2ac4",
    "b5732b9d-a5b1-4587-94e7-05382cc2319e",
    "b10f0d34-de63-43b7-8cc5-6b1d7db5920b",
    "af6debdc-c0bc-4beb-a67c-e6f4a2498cad",
    "a9e1497b-cf0d-4e39-a199-213b2bed2d8b",
    "a4ee1235-e349-4278-85e3-2eaa52a68eca",
    "82e312c9-47a6-4aa2-aa17-69b5eae2af55",
    "7a5d8c15-9a62-4c0d-b116-7de5a8761e13",
    "63ab8411-48b1-4db3-91d2-5992e18a8ed0",
    "58d0a1e9-57d9-4ed0-8db0-f4801c510b9b",
    "4123b9e5-db05-43eb-bb71-202792ef81a2",
    "3f448dc6-ad93-40ba-97db-dc05c20ff8bd",
    "3c5c0400-c322-4d66-97e4-772221bd9252",
    "319e1b03-b487-421a-a824-9ac2bb8da8ad",
    "3037386d-a84e-4209-b81c-f0b1d697afd1",
    "2e44f7b5-02c5-44df-a4a8-0d63eb54771f",
    "1eaa84aa-fed4-4b9e-a973-fe5876da7db0",
    "07fb15da-c7fa-48cc-9be6-c8c59e199e19",
    "003f8d18-f301-405e-b430-ea1306670929",
    "cfbafc40-ac2c-470b-be24-d2ef435c885b",
  ];

  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setTaskData(fetchedData);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [fetchedData]);

  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
  }, [fetchedData]);

  const handleTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  const handleDoneClick = () => {
    updateTaskData(taskData);
  };

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
            <TitleText>Sign documents</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            Review and sign the following documents. They outline the steps you will complete with Delve to get compliant.
          </RegularText>
        </VStack>
      </HStack>
      {isLoading ? (
        <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={10}>
          {Array.from({ length: 9 }).map((_, index) => (
            <Skeleton h='200px' key={index} w='100%' borderRadius='xl' startColor="#333333" endColor="#222222">
            </Skeleton>
          ))}
        </Grid>
      ) : (
        <>
          <DocumentsBox onDoneClick={handleDoneClick} taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
        </>
      )}
    </Box>
  );
};

export default SignDocuments;