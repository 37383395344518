import React from 'react';
import { Box, Progress, Text, HStack } from '@chakra-ui/react';
import RegularText from '../text/regulartext';

const CustomProgressBar = ({ completedTasks, totalTasks }) => {
  const percentage = (completedTasks / totalTasks) * 100;

  return (
    <HStack w="100%" alignItems="center">
      <Box w="90%">
        <Progress
          value={percentage}
          size="xs"
          borderRadius="md"
          sx={{
            '& > div': {
              bgGradient: 'linear(to-r, #AD2224, #D54E23)',
              boxShadow: '0 0 10px rgba(255, 78, 0, 0.5)',
            },
            bg: '#222222',
          }}
        />
      </Box>
      <RegularText color="#848484" fontSize="md" ml={2}>
        {Math.round(percentage)}%
      </RegularText>
    </HStack>
  );
};

export default CustomProgressBar;