import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchHacksData = () => {
    const [hacksData, setHacksData] = useState([]);
    const [error, setError] = useState(null);
    const toast = useToast();

    const fetchHacksData = useCallback(async () => {
        const sessionToken = await getSessionToken();
        try {
            const response = await axios.get(`${API_BASE_URL}core/org/incident/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Accept': 'application/json',
                },
            });
            setHacksData(response.data);
        } catch (error) {
            console.error('Error during request:', error);
            setError(error);
            posthog.capture('Error Fetching Security Incident Data', {
                error: error
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchHacksData();
    }, [fetchHacksData]);

    return { hacksData, error, refetch: fetchHacksData };
};

export default useFetchHacksData;