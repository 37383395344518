import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import Routes from './routes'; // Import AppRoutes instead of App
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@descope/react-sdk';
import posthog from 'posthog-js'
import { TeamDataProvider } from './contexts/TeamDataContext';
import { IntegrationDataProvider } from './contexts/IntegrationDataContext';
import { CompanyInfoProvider } from './contexts/CompanyInfoContext';

process.env.REACT_APP_MODE === "prod" ? posthog.init('phc_rYAYMHbvAVvy8iuhrwx2CrPCsdhAdgBoY1mGlEOSiDO',
  {
    api_host: 'https://us.i.posthog.com',
    "opt_in_site_apps": true,
  }
) : posthog.init('phc_9W5TEtknyGT3AZe2FRZwAgac7608XgrNCEwCb5p9Qkd',
  {
    api_host: 'https://us.i.posthog.com',
    "opt_in_site_apps": true,
  }
)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TeamDataProvider>
      <IntegrationDataProvider>
        <CompanyInfoProvider>
          {process.env.REACT_APP_MODE === "prod" ? (
            <AuthProvider projectId='P2hFLqB2TA5csXsqCBvhbvb2lcAI' baseUrl='https://auth.dashboard.getdelve.com'>
              <ChakraProvider>
                <Routes />
              </ChakraProvider>
            </AuthProvider>
          ) : (
            <AuthProvider projectId='P2hMWPWreILLIc8Jm9mmdmC1M1n4'>
              <ChakraProvider>
                <Routes />
              </ChakraProvider>
            </AuthProvider>
          )}
        </CompanyInfoProvider>
      </IntegrationDataProvider>
    </TeamDataProvider>
  </React.StrictMode>
);

reportWebVitals();