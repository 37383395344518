import React from 'react';
import { Box } from '@chakra-ui/react';

const SectionBox = ({ children }) => {
  return (
    <Box w='100%' bg='#101012' color='white' pl={4} pr={4} pt={4} pb={14}>
      {children}
    </Box>
  );
};

export default SectionBox;