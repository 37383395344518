import React, { useState } from 'react';
import { Box, VStack, HStack, Image, Input, Icon, Spacer } from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';
import RegularText from '../../text/regulartext';
import SubtitleText from '../../text/subtitletext';
import TitleText from '../../text/titletext';
import CustomButton from '../../general/custombutton';
import CustomInput from '../../general/custominput';
import { FaCheck } from 'react-icons/fa';
import { useEffect } from 'react';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../../../utils/Constants';
import axios from 'axios';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import CustomLoadingInput from '../../general/customloadinginput';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';
import { useCompanyInfo } from '../../../contexts/CompanyInfoContext';
import WhiteSubtitleText from '../../text/whitesubtitletext';


const TeamTab = ({ companyInformationRef }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [hover, setHover] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [companyLegalName, setCompanyLegalName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [hipaa, setHipaa] = useState(false);
  const [soc2, setSoc2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const { companyInfo, loadCompanyInfo } = useCompanyInfo();

  useEffect(() => {
    if (companyInfo.isLoading) {
      loadCompanyInfo();
    }
  }, [companyInfo.isLoading, loadCompanyInfo]);

  useEffect(() => {
    if (!companyInfo.isLoading) {
      setCompanyName(companyInfo.companyName || '');
      setCompanyLegalName(companyInfo.companyLegalName || '');
      setCompanyDescription(companyInfo.companyDescription || '');
      setImage(companyInfo.image || '');
      setHipaa(companyInfo.hipaa || false);
      setSoc2(companyInfo.soc2 || false);
    }
  }, [companyInfo]); // Depend on companyInfo to update local states

  useEffect(() => {
    if (!companyInfo.isLoading) { // Ensure data is loaded before attempting to save.
      saveCompanyInfo();
      handleDoneClick();
    }
  }, [companyName, companyLegalName, companyDescription, hipaa, soc2, image]); // Dependencies array

  
  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const saveCompanyInfo = async () => {
    try {
      const token = await getSessionToken();
      const formData = new FormData();
      
      // Append data to FormData and log each step
      formData.append('name', companyName);
  
      formData.append('legal_name', companyLegalName);
  
      formData.append('description', companyDescription);
  
      if (image && image.file) {
        formData.append('logo', image.file);
      }
  
      const response = await axios.post(
        `${API_BASE_URL}core/org/company/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      posthog.capture('Saved Company Information');
    } catch (error) {
      posthog.capture('Error Saving Company Information', {
        error: error
      });
    }
  };

  const handleNextClick = async () => {
    navigate('/compliance?page=2');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({ file, preview: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const [taskData, setTaskData] = useState([]);
  const relevantTaskIDs = [
    "94dd46dc-907a-4071-b09e-5ad21ef12897",
  ];

  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
    setIsSubmittedChecked(true);
  }, [fetchedData]);

  const handleDoneClick = () => {
    const updatedTasks = taskData.map(task => ({ ...task, complete: true, evidence: `Marked task ${task.name} as complete` }));
    updateTaskData(updatedTasks);
  };

  return (
    <Box w='100%'>
      <div ref={companyInformationRef}>
      <HStack mb={4}>
        <VStack align='left' >
          <TitleText>Provide company information</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            This will be used to autofill your legal documents and compliance report.
          </RegularText>
        </VStack>
        <Spacer />
      </HStack>

      <HStack spacing={6} mb={10}>
        <Box
          w='30%'
          h='180px'
          borderRadius='xl'
          bg='#1C1C1E'
          display='flex'
          alignItems='center'
          justifyContent='center'
          position='relative'
          overflow='hidden'
          border='2px dashed #222222'
          borderStyle='dashed'
          borderWidth='2px'
          borderColor='#222222'
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          _hover={{
            cursor: 'pointer',
            bg: '#18181A',
          }}
          onClick={() => document.getElementById('fileInput').click()}
        >
          {image ? (
            <>
              <Image src={image.preview || image} alt="Uploaded" objectFit="cover" w="100%" h="100%" />
              {hover && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bg="rgba(0, 0, 0, 0.5)"
                >
                  <Box as="span" fontSize="2xl" mt={0} bg='#1c1c1e' borderRadius='xl' pl={3} pr={3} pt={0} pb={1}>
                    <Icon as={FaUpload} w={4} h={4} color="#848484" />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box textAlign="center" color="white">
              <Box as="span" fontSize="2xl" bg='#272728' borderRadius='xl' pl={3} pr={3} pt={1} pb={1}>
                <Icon as={FaUpload} w={4} h={4} color="#848484" />
              </Box>
              <Box mt={3}><RegularText sx={{ color: '#dddddd' }}>Click to upload company logo</RegularText></Box>
            </Box>
          )}
          <Input
            type="file"
            id="fileInput"
            display="none"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleImageUpload}
          />
        </Box>

        <VStack w='70%' spacing={6}>
          <HStack w='100%'>
            <VStack w='50%' align="start">
              <RegularText sx={{ color: '#dddddd' }}>
                Company name
              </RegularText>
              {companyInfo.isLoading ? <CustomLoadingInput /> : <CustomInput sx={{ background: '#1C1C1E' }} placeholder='Delve' onChange={(e) => setCompanyName(e.target.value)} value={companyName || ''} />}
            </VStack>

            <VStack w='50%' align="start">
              <RegularText sx={{ color: '#dddddd' }}>
                Company legal name
              </RegularText>
              {companyInfo.isLoading ? <CustomLoadingInput /> : <CustomInput sx={{ background: '#1C1C1E' }} placeholder='Delve Technologies Inc.' onChange={(e) => setCompanyLegalName(e.target.value)} value={companyLegalName || ''} />}
            </VStack>
          </HStack>

          <VStack w='100%' align="start">
            <RegularText sx={{ color: '#dddddd' }}>
              Company description
            </RegularText>
            {companyInfo.isLoading ? <CustomLoadingInput /> : <CustomInput sx={{ background: '#1C1C1E' }} placeholder='Software company that helps startups get compliant.' onChange={(e) => setCompanyDescription(e.target.value)} value={companyDescription || ''} />}
          </VStack>
        </VStack>
      </HStack>

      <RegularText sx={{ color: '#dddddd' }}>
        Compliance
      </RegularText>
      <HStack mt={2} spacing={4}>
        {companyInfo.isLoading ? <HStack w='100%' spacing={4}>
          <CustomLoadingInput />
        </HStack> : (
          <>
            {hipaa ? (
              <Box
                key="hipaa"
                w='50%'
                borderRadius='xl'
                h='110px'
                bg='#1C1C1E'
                border='0.5px solid'
                borderColor='#D73601'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                p={4}
                cursor='not-allowed'
              >
                <HStack>
                  <Box
                    w='18px'
                    h='18px'
                    borderRadius='md'
                    border='1px solid'
                    borderColor='#D73601'
                    bg='#D73601'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Icon as={FaCheck} color='#1c1c1e' w={3} h={3} />
                  </Box>
                  <RegularText sx={{ color: '#dddddd' }}>HIPAA</RegularText>
                </HStack>
                <RegularText sx={{ mt: '2' }}>
                  Ensures secure processing and storage of protected health information.
                </RegularText>
              </Box>
            ) : (
              <Box
                w='50%'
                borderRadius='xl'
                h='110px'
                bg='rgba(28, 28, 30, 1)'
                border='0.5px solid'
                borderColor='#222222'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                p={4}
                cursor='not-allowed'
              >
                <HStack>
                  <Box
                    w='18px'
                    h='18px'
                    borderRadius='md'
                    border='1px solid'
                    borderColor='#252525'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                  </Box>
                  <RegularText sx={{ color: 'rgba(221, 221, 221, 0.5)' }}>HIPAA</RegularText>
                </HStack>
                <RegularText sx={{ mt: '2', opacity: '0.5' }}>
                  Ensures secure processing and storage of protected health information.
                </RegularText>
              </Box>
            )}

            {soc2 ? (
              <Box
                key="soc2"
                w='50%'
                borderRadius='xl'
                h='110px'
                bg='#1C1C1E'
                border='0.5px solid'
                borderColor='#D73601'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                p={4}
                cursor='not-allowed'
              >
                <HStack>
                  <Box
                    w='18px'
                    h='18px'
                    borderRadius='md'
                    border='1px solid'
                    borderColor='#D73601'
                    bg='#D73601'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Icon as={FaCheck} color='#1c1c1e' w={3} h={3} />
                  </Box>
                  <RegularText sx={{ color: '#dddddd' }}>SOC 2</RegularText>
                </HStack>
                <RegularText sx={{ mt: '2' }}>
                  Ensures strong cybersecurity and prepares you for enterprise contracts.
                </RegularText>
              </Box>
            ) : (
              <Box
                w='50%'
                borderRadius='xl'
                h='110px'
                bg='rgba(28, 28, 30, 1)'
                border='0.5px solid'
                borderColor='#222222'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                p={4}
                cursor='not-allowed'
              >
                <HStack>
                  <Box
                    w='18px'
                    h='18px'
                    borderRadius='md'
                    border='1px solid'
                    borderColor='#252525'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                  </Box>
                  <RegularText sx={{ color: 'rgba(221, 221, 221, 0.5)' }}>SOC 2</RegularText>
                </HStack>
                <RegularText sx={{ mt: '2', opacity: '0.5' }}>
                  Ensures strong cybersecurity and prepares you for enterprise contracts.
                </RegularText>
              </Box>
            )}
          </>
        )}

      
      </HStack>
      </div>
    </Box>
  );
};

export default TeamTab;