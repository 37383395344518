import React, { useState, useEffect } from 'react';
import { Table, Thead, Skeleton, Tbody, Tr, Th, Td, VStack, useToast, Box, HStack, Spacer } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomBadge from '../../general/custombadge';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomModal from '../../modal/custommodal';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomButton from '../../general/custombutton';
import SubtitleText from '../../text/subtitletext';
import { useTeamData } from '../../../contexts/TeamDataContext';

import useFetchResourceData from '../../../hooks/useFetchResourceData';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';

const categoryColors = {
    'Hardware': 'orange',
    'Software': 'pink',
    'File': 'purple',
};

const TeamTable = ({ sx }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resourceInput, setResourceInput] = useState('');
  const [categoryInput, setCategoryInput] = useState('');
  const [ownerInput, setOwnerInput] = useState('');

  const toast = useToast();
  
  const { resourceData, refetch: refetchResourceData } = useFetchResourceData();

  const { teamData } = useTeamData();
  const names = teamData.map(member => member.name);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setResourceInput('');
    setCategoryInput('');
    setOwnerInput('');
    setIsModalOpen(false);
  };

  const relevantTaskIDs = [
    "57393ba6-5c73-4122-a874-1576c8da6ea3",
    "3054dc89-2730-4008-9baa-3a6f547ec181",
    "234eeec2-344c-4415-a827-6b413557984c",
  ];
  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const [taskData, setTaskData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);
  const { updateTaskData } = useUpdateTaskData();

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
    setIsSubmittedChecked(true);
  }, [fetchedData]);


  const handleAddResource = async () => {
    const token = await getSessionToken();


    const selectedMember = teamData.find(member => member.name === ownerInput);
    
    const updatedTasks = taskData.map(task => {
      const evidence = `Marked task ${task.name} as complete by ${selectedMember.name} for asset inventory item ${resourceInput} in description category ${categoryInput}.`;
      posthog.capture('Added Evidence for Task', { evidence });
      return {
        ...task, 
        complete: true, 
        evidence
      };
    });
    updateTaskData(updatedTasks);

    
    if (resourceInput && categoryInput && ownerInput) {
      try {
        await axios.post(`${API_BASE_URL}core/org/company/resource/`, {
          resource_name: resourceInput,
          category: categoryInput,
          associated_user: selectedMember.id,
          associated_user_name: selectedMember.name
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        toast({
          title: 'Resource added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeModal();
        refetchResourceData();
        posthog.capture('Added Resource to Integration');

      } catch (error) {
        console.error('Error adding resource:', error);
        posthog.capture('Error Adding Resource to Integration', {
          error: error
        });
      }
    }
  };
  
  const handleDeleteResource = async (resourceId) => {

    const updatedTasks = taskData.map(task => {
      const evidence = `Removed asset inventory item with ID ${resourceId} from asset inventory list.`;
      posthog.capture('Deleted Evidence for Task', { evidence });
      return {
        ...task, 
        evidence
      };
    });
    updateTaskData(updatedTasks);



    const token = await getSessionToken();
    try {
      await axios.delete(`${API_BASE_URL}core/org/company/resource/`, {
        data: {
          id: resourceId
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      toast({
        title: 'Resource deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refetchResourceData();
      posthog.capture('Deleted Resource from Integration');
    } catch (error) {
      console.error('Error deleting resource:', error);
      posthog.capture('Error Deleting Resource from Integration', {
        error: error
      });
    }
  };

  const modalHeader = <SubtitleText>Add resource</SubtitleText>;

  const modalBody = (
    <VStack spacing={6} align='left'>
    <VStack align='left'>
      <RegularText sx={{color: '#dddddd'}}>
        Resource
      </RegularText>
      <CustomInput placeholder="Apple MacBook Pro (16-inch, 2023)" value={resourceInput} onChange={(e) => setResourceInput(e.target.value)} />
    </VStack>

    <VStack align='left'>
      <RegularText sx={{color: '#dddddd'}}>
        Category
      </RegularText>
      <CustomDropdown items={['Software', 'Hardware', 'File']} placeholder="Select category" value={categoryInput} onChange={(e) => setCategoryInput(e.target.value)} />
    </VStack>

    <VStack align='left'>
      <RegularText sx={{color: '#dddddd'}}>
        Owner
      </RegularText>
      <CustomDropdown                                   
          items={['Select', ...names]}
          value={ownerInput}
          onChange={(e) => setOwnerInput(e.target.value)}
        />
    </VStack>

  </VStack>
  );

  const modalFooter = <CustomButton onClick={handleAddResource} text='Add'></CustomButton>;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      if (taskData && taskData.length > 0) {  // Check that data is not just present but also has length
          setIsLoading(false);
      } else {
          setIsLoading(true);
      }
  }, [taskData]);

  return (
    <>
                { isLoading ? (
            <Box>
                <Table variant="simple" colorScheme='white'>
                <Thead>
                    <Tr>
                        <Th color="#222222" width="70%">
                            <RegularText sx={{fontSize: 'xs'}}>Configuration</RegularText>
                        </Th>
                        <Th color="#222222" width="30%">
                            <RegularText sx={{fontSize: 'xs'}}>Screenshot</RegularText>
                        </Th>
                    </Tr>
                </Thead>
            </Table>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
            <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
        </Box>
       ) : (


    <Table variant="simple" colorScheme='white' sx={sx}>
        <Thead>
            <Tr>
            {['Resource', 'Category', 'Owner', 'Status'].map((header, index) => (
                <Th key={index} color="#222222">
                    <RegularText sx={{fontSize: 'xs'}}>{header}</RegularText>
                </Th>
            ))}
            </Tr>
        </Thead>
        <Tbody>
          {resourceData.map((resource, index) => (
            <Tr key={index} color="#222222">
                <Td>
                      <RegularText sx={{color: '#DDDDDD'}}>{resource.resource_name}</RegularText>
                </Td>
                <Td>
                    <CustomBadge text={resource.category} colorScheme={categoryColors[resource.category]} />
                </Td>
                <Td>
                <RegularText>{resource.associated_user_name}</RegularText>
                </Td>
                <Td>
                    <HStack>
                      <RegularText>Added {new Date(resource.created).toLocaleDateString('en-US', { 
                      year: 'numeric', 
                      month: 'short', 
                      day: 'numeric' 
                    })}</RegularText>
                        <Spacer/>
                        <ThreeDots deleteFunction={() => handleDeleteResource(resource.id)} />
                    </HStack>
                </Td>
            </Tr>
            ))}
        </Tbody>
    </Table>
       )}

    <Box mt={5}>
        <CustomOutlineButton onClick={openModal} text='+ Add resource'/>
    </Box>

    <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
    </>
  );
};

export default TeamTable;