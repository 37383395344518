import React from 'react';
import { Skeleton } from '@chakra-ui/react';

const CustomLoadingInput = ({ sx }) => {
  return (
    <>
        <Skeleton borderRadius="xl" w='100%' sx={sx} height="40px" startColor="#333333" endColor="#222222"/>
    </>
  );
};

export default CustomLoadingInput;