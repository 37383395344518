import React, { useState, useEffect } from 'react';
import { Box, HStack, Spacer, Button, Collapse, VStack, Skeleton, useToast } from '@chakra-ui/react';
import TitleText from '../../text/titletext';
import SubtitleText from '../../text/subtitletext';
import AuditTable from './audittable';
import { ChevronRightIcon } from '@chakra-ui/icons';
import CustomButton from '../../general/custombutton';
import posthog from 'posthog-js';
import useFetchTeamData from '../../../hooks/useFetchTeamData';
import WhiteSubtitleText from '../../text/whitesubtitletext';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import { saveAs } from 'file-saver';
import { useCompanyInfo } from '../../../contexts/CompanyInfoContext';

import { Document, Page, pdf } from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';
import JSZip from 'jszip';

import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../../../utils/Constants';

const TeamTab = ({ soc2AuditRef }) => {
  const { teamData: users, isLoading, error } = useFetchTeamData();
  const [controlEnvironments, setControlEnvironments] = useState([]);
  const [openPanels, setOpenPanels] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingPolicies, setIsDownloadingPolicies] = useState(false);
  const { companyInfo, loadCompanyInfo } = useCompanyInfo();

  const [selectedControls, setSelectedControls] = useState({});
  const toast = useToast();


  const togglePanel = async (id, relevantSpecificCategories) => {
    setOpenPanels((prev) => ({ ...prev, [id]: !prev[id] }));
    if (!selectedControls[id]) {
      await fetchSpecificControlData(id, relevantSpecificCategories);
    }
  };

  const fetchControlEnvironments = async () => {
    const token = await getSessionToken();
    try {
      const response = await axios.get(`${API_BASE_URL}compliance/map/controls/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
      });
      setControlEnvironments(response.data);
      posthog.capture('Fetched Control Categories in Audit Tab')
    } catch (error) {
      console.error('Error fetching control environments:', error);
      posthog.capture('Error Fetching Control Categories in Audit Tab', {
        error: error
      });
    }
  };

  const fetchSpecificControlData = async (id, relevantSpecificCategories) => {
    try {
      const token = await getSessionToken();
      const response = await axios.post(`${API_BASE_URL}compliance/map/controls/`, {
        relevantSpecificCategories: relevantSpecificCategories
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSelectedControls((prev) => ({ ...prev, [id]: response.data }));
      posthog.capture('Fetched Specific Controls in Audit Tab', {
        relevantSpecificCategories: relevantSpecificCategories,
        count: response.data.length,
      });
    } catch (error) {
      console.error('There was an error!', error);
      posthog.capture('Error Fetching Specific Controls in Audit Tab', {
        error: error
      });
    }
  };

  const exportToCSV = async () => {
    setIsDownloading(true);
    const token = await getSessionToken();
    try {
      const response = await axios.post(`${API_BASE_URL}compliance/map/controls/export/`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
      });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'control_environments.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast({
        title: "Download completed",
        description: "Your CSV file has been downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      posthog.capture('Downloaded CSV in Audit Tab');
    } catch (error) {
      console.error('Error exporting control environments:', error);
      posthog.capture('Error Downloading CSV in Audit Tab', {
        error: error
      });
    } finally {
      setIsDownloading(false);
    }
  };



  const [so2ComplianceOfficer, setSo2ComplianceOfficer] = useState('');

  const relevantTaskIDs = [
    "d040e1f3-9a14-4f1c-bb43-833c7e2f4c00",
  ];
  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);

  useEffect(() => {
    fetchControlEnvironments();
  }, []);

  useEffect(() => {
    if (fetchedData && fetchedData[0]) {
      setSo2ComplianceOfficer(fetchedData[0].associated_user_name);
    }
  }, [fetchedData]);


    const policyTaskIDs = [
      "5e2537c0-9065-401d-901c-14d546b7cea6",
      "fa8fa5dd-0c3c-4377-ac3d-eb7be47aac57",
      "e80bdaf4-6c13-4584-84e7-b33c5c61ebfc",
      "e3823cac-700c-4f8c-b623-2c8fbfeec35d",
      "e1c3d329-1399-4a1f-8a92-5b129c7d1c1b",
      "d141a5f8-afde-4d2b-b45c-0ca287624977",
      "ce60f286-e51e-4d03-b308-1e66201e2ac4",
      "b5732b9d-a5b1-4587-94e7-05382cc2319e",
      "b10f0d34-de63-43b7-8cc5-6b1d7db5920b",
      "af6debdc-c0bc-4beb-a67c-e6f4a2498cad",
      "a9e1497b-cf0d-4e39-a199-213b2bed2d8b",
      "a4ee1235-e349-4278-85e3-2eaa52a68eca",
      "82e312c9-47a6-4aa2-aa17-69b5eae2af55",
      "7a5d8c15-9a62-4c0d-b116-7de5a8761e13",
      "63ab8411-48b1-4db3-91d2-5992e18a8ed0",
      "58d0a1e9-57d9-4ed0-8db0-f4801c510b9b",
      "4123b9e5-db05-43eb-bb71-202792ef81a2",
      "3f448dc6-ad93-40ba-97db-dc05c20ff8bd",
      "3c5c0400-c322-4d66-97e4-772221bd9252",
      "319e1b03-b487-421a-a824-9ac2bb8da8ad",
      "3037386d-a84e-4209-b81c-f0b1d697afd1",
      "2e44f7b5-02c5-44df-a4a8-0d63eb54771f",
      "1eaa84aa-fed4-4b9e-a973-fe5876da7db0",
      "07fb15da-c7fa-48cc-9be6-c8c59e199e19",
      "003f8d18-f301-405e-b430-ea1306670929",
      "cfbafc40-ac2c-470b-be24-d2ef435c885b",
    ];    

    const { data: policyFetchedData } = useFetchTaskData(policyTaskIDs);



    
const handleDownloadPolicies = async (policyFetchedData) => {
  setIsDownloadingPolicies(true);
  console.log("Starting download process for policies...");
  if (policyFetchedData.length === 26) {
    const zip = new JSZip();
    const pdfPromises = policyFetchedData.map(async (task) => {
      console.log(`Processing task: ${task.title}`);
      try {
        let htmlContent = task.document_html
          .replace(/\[COMPANY\]/g, companyInfo.companyLegalName)
          .replace("[Approver]", task.associated_user_name)
          .replace("[Version]", "1.0")
          .replace("[Effective Date]", "June 1, 2024");
        htmlContent = htmlContent.replace(/<!DOCTYPE html>/gi, ''); // Remove DOCTYPE declaration
        htmlContent = htmlContent.replace(
          /<h2>\s*Purpose:\s*<\/h2>/gi,
          `<h1>${task.title}</h1><h2>Purpose:</h2>`
        );

        htmlContent = `<style>
        body, h1, h2, p { font-size: 14pt; }
        h1 { font-size: 24pt; }
        h2 { font-size: 20pt; }
      </style>` + htmlContent;


        const MyDocument = (
          <Document>
            <Page size="A4">
              <Html>{htmlContent}</Html>
            </Page>
          </Document>
        );

        // Generate PDF blob
        const blob = await pdf(MyDocument).toBlob();
        zip.file(`${task.title}.pdf`, blob);
      } catch (error) {
        console.error("Error generating PDF for task:", task.title, error);
        throw new Error(`Failed to generate PDF for ${task.title}: ${error.message}`);
      }
    });

    // Wait for all PDFs to be processed and added to the ZIP
    try {
      await Promise.all(pdfPromises);
      // Generate the ZIP file and trigger download
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'policies.zip');
      console.log("ZIP file with policies has been downloaded.");
    } catch (error) {
      console.error("Failed to generate ZIP file:", error);
    }
  } else {
    console.log(`Expected 26 policies, but got ${policyFetchedData.length}`);
  }
  setIsDownloadingPolicies(false);
};



  return (
    <>
        <div w='100%' bg='red' ref={soc2AuditRef} >
        <HStack w="100%" mb={8}>
          <TitleText>SOC 2 audit dashboard</TitleText>
          <Spacer></Spacer>
          <CustomButton onClick={() => handleDownloadPolicies(policyFetchedData)} isDisabled={isDownloadingPolicies}>
            {isDownloadingPolicies ? "Downloading..." : "Download Policies"}
          </CustomButton>
          {/* <CustomButton onClick={exportToCSV} isDisabled={isDownloading}>
            {isDownloading ? "Downloading..." : "Download CSV"}
          </CustomButton> */}
        </HStack>

        { controlEnvironments.length === 0 && (
          <>
          <Skeleton height="4rem" borderRadius='xl' mt='15px' mb='15px' startColor="#333333" endColor="#222222"/>
          <Skeleton height="4rem" borderRadius='xl' mt='15px' mb='15px' startColor="#333333" endColor="#222222"/>
          <Skeleton height="4rem" borderRadius='xl' mt='15px' mb='15px' startColor="#333333" endColor="#222222"/>
          </>
        )}

        <VStack spacing={4} w='100%'>
          {controlEnvironments.map((env) => (
            <React.Fragment key={env.id} w='100%'>
              <Button
                variant="unstyled"
                p={0}
                w='100%'
                h="auto"
                borderRadius='xl' 
                fontWeight="normal"
                onClick={() => togglePanel(env.id, env.relevantSpecificCategories)}
              >
                <HStack borderWidth={1} borderRadius='xl' borderColor="#222222" w='100%' bg='#1C1C1E' p={4}>
                  <SubtitleText>{env.name}</SubtitleText>
                  <WhiteSubtitleText sx={{ pl: 1 }}>{env.customName}</WhiteSubtitleText>
                  <Spacer />
                  <Box w='20vw'>
                    {/* <CustomProgressBar completedTasks={4} totalTasks={10}/> */}
                  </Box>
                  <ChevronRightIcon 
                    transition="transform 0.2s"
                    transform={openPanels[env.id] ? "rotate(90deg)" : "rotate(0deg)"}
                  />
                </HStack>
              </Button>

              <Collapse in={openPanels[env.id]} animateOpacity>
                <AuditTable users={users} controls={selectedControls[env.id]} so2ComplianceOfficer={so2ComplianceOfficer}/>
              </Collapse>
            </React.Fragment>
          ))} 
        </VStack>
        </div>
    </>
  );
};

export default TeamTab;