import React from 'react';
import ComplianceNavbar from '../components/compliance/compliancenavbar';
import SectionBox from '../components/box/sectionbox';
import { Box } from '@chakra-ui/react';
import TeamCompliance from '../components/teamcompliance/teamcompliancetab';

const Compliance = () => {
  return (
    <Box
      w='100%'
      minH='100vh'
      bg='#101012'
    >
      <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, mb: '6vh', zIndex: 1 }}>
        <ComplianceNavbar />
      </Box>
      <SectionBox>
        <Box w='60%' ml='20%' mr='20%' mt='14vh'>
                <TeamCompliance />
        </Box>
      </SectionBox>
    </Box>
  );
};

export default Compliance;