import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';

const CustomModal = ({ isOpen, onClose, modalHeader, modalBody, modalFooter }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg='#1C1C1E' borderColor='#222222' borderWidth={1} borderRadius='md'>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton color="#848484" />
        <ModalBody bg="#1C1C1E" color="#DDDDDD">{modalBody}</ModalBody>
        <ModalFooter display="flex" justifyContent="center">{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;