import React, {useState} from 'react';
import RegularText from '../../text/regulartext';
import SubtitleText from '../../text/subtitletext';
import { Box, HStack, Spacer, Grid } from '@chakra-ui/react';
import TitleText from '../../text/titletext';
import CustomAlert from '../../general/customalert';
import DocumentsBox from './documentsbox';
import CustomButton from '../../general/custombutton';
import ComplianceUnsubmit from '../complianceunsubmit';
import SignDocuments from './signdocuments';
import AdditionalTemplates from './additionaltemplates';

const DocumentsTab = ({ signDocumentsRef, approveTemplatesRef }) => {

  return (
        <>

          <div mt={6} ref={signDocumentsRef}><SignDocuments/></div>
          <div mt={6} ref={approveTemplatesRef}><AdditionalTemplates/></div>

        </>
     );
   };

export default DocumentsTab;

