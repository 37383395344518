import React, { useState } from 'react';
import { Table,Skeleton, Thead, Tbody, Tr, Th, Td, useToast, Box, HStack, Spacer, VStack } from '@chakra-ui/react';

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomButton from '../../general/custombutton';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomModal from '../../modal/custommodal';
import SubtitleText from '../../text/subtitletext';
import useFetchSimulateData from '../../../hooks/useFetchSimulateData';
import CustomTextarea from '../../general/customtextarea';

import axios from 'axios';
import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';

import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';

const SimulateTable = ({ sx }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [ scenarioInput, setScenarioInput ] = useState('');
    const [ attendeesInput, setAttendeesInput ] = useState('');
    const [ planInput, setPlanInput ] = useState('');

    const toast = useToast();
    const { simulateData, loading, refetch: refetchSimulateData } = useFetchSimulateData();

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
      setScenarioInput('');
      setAttendeesInput('');
      setPlanInput('');
      setIsModalOpen(false);
      setSelectedScenarioIndex(null);
    };


    const [selectedScenarioIndex, setSelectedScenarioIndex] = useState(null);

    const handleScenarioChange = (event) => {
        // Assuming the dropdown returns the label of the selected item
        const selectedLabel = event.target.value;
        // Find the index of the scenario that matches the label
        const index = scenarios.findIndex(scenario => `Scenario ${scenarios.indexOf(scenario) + 1}` === selectedLabel);
        setSelectedScenarioIndex(index);
        setScenarioInput(scenarios[index]);
    };

    const scenarios = [
        'Your data gets encrypted by Chinese hackers, who demand payment in Bitcoin for the decryption key',
        'Employees receive emails from "Prince Ali" offering a fortune, but clicking the link gives hackers access to your data',
        'An employee shares their screen during a virtual happy hour, accidentally exposing sensitive data instead of team photos'
    ];

    const scenarioLabels = scenarios.map((_, index) => `Scenario ${index + 1}`);
    const relevantTaskIDs = [
        "8b7a93cf-8bde-4592-8a1d-ccd8d104f2be",
      ];
      const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
      const [taskData, setTaskData] = useState([]);
      const [isSubmitted, setIsSubmitted] = useState(false);
      const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);
      const { updateTaskData } = useUpdateTaskData();
    

    const handleAddScenario = async () => {
        const token = await getSessionToken();        
    

        const updatedTasks = taskData.map(task => ({
            ...task, 
            complete: true, 
            evidence: `Marked task ${task.name} as complete for simulated incident with scenario: ${scenarioInput}, attendees: ${attendeesInput}, plan: ${planInput}.`
          }));

          updateTaskData(updatedTasks);
          
          
        if (scenarioInput && attendeesInput && planInput) {
          try {
            await axios.post(`${API_BASE_URL}core/org/incident/simulated/`, {
              scenario: scenarioInput,
              attendees: attendeesInput,
              response: planInput,
              date: new Date().toISOString().split('T')[0],
            }, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            toast({
              title: 'Incident simulation added',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            closeModal();
            refetchSimulateData();
            posthog.capture('Added Incident Simulation', {
              scenarioInput: scenarioInput,
              attendeesInput: attendeesInput,
              planInput: planInput
            });
          } catch (error) {
            posthog.capture('Error Adding Incident Simulation', {
              error: error
            });
            console.error('Error adding incident simulation:', error);
          }
        }
      };
      
      const handleDeleteResource = async (resourceId) => {

        const updatedTasks = taskData.map(task => ({
            ...task, 
            evidence: `Removed simulated incident with ID ${resourceId} from simulated incident list.`
          }));
          updateTaskData(updatedTasks);

          

        const token = await getSessionToken();
        try {
          await axios.delete(`${API_BASE_URL}core/org/incident/simulated/`, {
            data: {
              id: resourceId
            },
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          toast({
            title: 'Incident simulation deleted',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          refetchSimulateData();
          posthog.capture('Removed Incident Simulation', {
            resourceId: resourceId
          });
        } catch (error) {
          posthog.capture('Error Deleting Incident Simulation', {
            error: error
          });
          console.error('Error deleting incident simulation:', error);
        }
      };
    


  const modalHeader = <SubtitleText>Simulate a security incident</SubtitleText>;
  const modalBody = (
    <VStack spacing={6} align='left'>
            <VStack align='left'>
                <RegularText sx={{color: '#dddddd'}}>
                    Incident
                </RegularText>
                <CustomDropdown 
                    items={scenarioLabels} 
                    placeholder="Select a random scenario" 
                    onChange={(e) => {
                        handleScenarioChange(e);
                    }}
                    value={scenarioInput}
                />
                {selectedScenarioIndex !== null && <RegularText sx={{color: '#dddddd'}}>{scenarios[selectedScenarioIndex]}</RegularText>}
            </VStack>

        <VStack align='left'>
            <RegularText sx={{color: '#dddddd'}}>
                Names of attendees
            </RegularText>
            <CustomInput placeholder="Sam Altman, Garry Tan" value={attendeesInput} onChange={(e) => setAttendeesInput(e.target.value)}/>
        </VStack>

        <VStack align='left'>
            <RegularText sx={{color: '#dddddd'}}>
                Response plan
            </RegularText>
            <CustomTextarea placeholder="Type here..." value={planInput} onChange={(e) => setPlanInput(e.target.value)}/>
        </VStack>

    </VStack>
  );
  const modalFooter = <CustomButton onClick={handleAddScenario} text='Add'></CustomButton>;

  

  return (
    <>
        { loading ? (
                        <Box>
                        <Table variant="simple" colorScheme='white'>
                        <Thead>
                            <Tr>
                                <Th color="#222222" width="70%">
                                    <RegularText sx={{fontSize: 'xs'}}>Configuration</RegularText>
                                </Th>
                                <Th color="#222222" width="30%">
                                    <RegularText sx={{fontSize: 'xs'}}>Screenshot</RegularText>
                                </Th>
                            </Tr>
                        </Thead>
                    </Table>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                </Box>
               ) : (
    

    <Table variant="simple" colorScheme='white' sx={sx}>
        <Thead>
            <Tr>
                <Th color="#222222" width='25%'>
                    <RegularText sx={{fontSize: 'xs'}}>Scenario</RegularText>
                </Th>
                <Th color="#222222" width='20%'>
                    <RegularText sx={{fontSize: 'xs'}}>Date</RegularText>
                </Th>
                <Th color="#222222" width='20%'>
                    <RegularText sx={{fontSize: 'xs'}}>Attendees</RegularText>
                </Th>
                <Th color="#222222" width='35%'>
                    <RegularText sx={{fontSize: 'xs'}}>Response plan</RegularText>
                </Th>

            </Tr>
        </Thead>
        <Tbody>
            {simulateData.map((scenario, index) => (
            <Tr key={index} color="#222222">
                <Td>
                    <RegularText sx={{color: '#DDDDDD'}}>{scenario.scenario}</RegularText>
                </Td>
                <Td >
                    <RegularText>Added {new Date(scenario.created).toLocaleDateString('en-US', { 
                        year: 'numeric', 
                        month: 'short', 
                        day: 'numeric' 
                    })}</RegularText>
                </Td>
                <Td>
                    <RegularText>{scenario.attendees}</RegularText>
                </Td>
                <Td>
                    <HStack>
                        <RegularText>{scenario.response}</RegularText>
                        <Spacer/>
                        <ThreeDots deleteFunction={() => handleDeleteResource(scenario.id)}/>
                    </HStack>
                </Td>
            </Tr>
            ))}
        </Tbody>
    </Table>
    )}
    <HStack mt={5}>
        <CustomOutlineButton
          onClick={openModal}
          text="+ Simulate incident"
        />
    </HStack>

    <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
   
    </>
  );
};

export default SimulateTable;