import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Image, Spacer, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import useFetchTaskData from "../../../hooks/useFetchTaskData";
import AWS from "../../../assets/AWS.png";
import AWS_CloudTrail from "../../../assets/AWS_CloudTrail.png";
import AWS_Documentdb from "../../../assets/AWS_Documentdb.jpeg";
import AWS_Dynamodb from "../../../assets/AWS_DynamoDB.png";
import AWS_EC2 from "../../../assets/AWS_EC2.png";
import AWS_ECS from "../../../assets/AWS_ECS.png";
import AWS_ELB from "../../../assets/AWS_ELB.png";
import AWS_IAM from "../../../assets/AWS_IAM.webp";
import AWS_Lambda from "../../../assets/AWS_Lambda.png";
import AWS_RDS from "../../../assets/AWS_RDS.png";
import AWS_S3 from "../../../assets/AWS_S3.png";
import Anthropic from "../../../assets/Anthropic.png";
import Auth0 from "../../../assets/Auth0.png";
import Azure from "../../../assets/Azure.png";
import BaseTen from "../../../assets/BaseTen.jpeg";
import Cloudflare from "../../../assets/Cloudflare.png";
import Cohere from "../../../assets/Cohere.png";
import Datadog from "../../../assets/Datadog.png";
import Deepgram from "../../../assets/Deepgram.png";
import DefaultLogo from "../../../assets/DefaultLogo.png";
import DocSend from "../../../assets/DocSend.png";
import ElevenLabs from "../../../assets/ElevenLabs.png";
import GCP from "../../../assets/GCP.png";
import Github from "../../../assets/Github.png";
import Google from "../../../assets/Google.png";
import HubSpot from "../../../assets/HubSpot.jpeg";
import Linear from "../../../assets/Linear.png";
import Mixpanel from "../../../assets/Mixpanel.png";
import Namecheap from "../../../assets/Namecheap.png";
import NewRelic from "../../../assets/NewRelic.png";
import Notion from "../../../assets/Notion.png";
import OpenAI from "../../../assets/OpenAI.png";
import Railway from "../../../assets/Railway.png";
import Ramp from "../../../assets/Ramp.png";
import Rippling from "../../../assets/Rippling.jpg";
import Slack from "../../../assets/Slack.png";
import Supabase from "../../../assets/Supabase.png";
import Twilio from "../../../assets/Twilio.png";
import Vercel from "../../../assets/Vercel.png";
import Vonage from "../../../assets/Vonage.png";
import Zoom from "../../../assets/Zoom.png";
import CustomBadge from "../../general/custombadge";
import CustomProgressBar from "../../general/customprogressbar";
import RegularText from "../../text/regulartext";
import Sentry from '../../../assets/Sentry.png';
import Pinecone from '../../../assets/Pinecone.png';
import Helicone from '../../../assets/Helicone.png';
import Fly from '../../../assets/Fly.png';
import Redis from '../../../assets/Redis.png';
import Axiom from '../../../assets/Axiom.jpg';
import Bland from '../../../assets/Bland.jpg';
import Retell from '../../../assets/Retell.png';
import CrunchyData from '../../../assets/CrunchyData.png';

const integrationLogos = {
    AWS: AWS,
    GCP: GCP,
    Mixpanel: Mixpanel,
    Github: Github,
    Slack: Slack,
    Zoom: Zoom,
    Notion: Notion,
    Vercel: Vercel,
    Cloudflare: Cloudflare,
    Auth0: Auth0,
    Azure: Azure,
    Datadog: Datadog,
    Supabase: Supabase,
    Google: Google,
    Twilio: Twilio,
    Cohere: Cohere,
    Vonage: Vonage,
    OpenAI: OpenAI,
    Anthropic: Anthropic,
    Rippling: Rippling,
    Ramp: Ramp,
    DocSend: DocSend,
    HubSpot: HubSpot,
    Railway: Railway,
    BaseTen: BaseTen,
    ElevenLabs: ElevenLabs,
    Namecheap: Namecheap,
    Linear: Linear,
    Deepgram: Deepgram,
    NewRelic: NewRelic,
    Sentry: Sentry,
    Pinecone: Pinecone,
    Helicone: Helicone,
    Fly: Fly,
    Redis: Redis,
    default: DefaultLogo,
    CrunchyData: CrunchyData,
    Axiom: Axiom,
    Bland: Bland,
    Retell: Retell,
};

const SelectInfrastructure = ({
    onSelect,
    infraName,
    infraDescription,
    showProgress,
    sx,
    isOpen,
    status,
    resourceName,
    test_id
}) => {
    const logo = integrationLogos[infraName] || integrationLogos["default"];

    const [taskData, setTaskData] = useState([]);
    const relevantTaskIDs = [test_id];
    const { data: fetchedData, refetch } = useFetchTaskData(relevantTaskIDs);

    useEffect(() => {
        if (fetchedData) {
            setTaskData(fetchedData);
        }
    }, [fetchedData]);


  const resourceLogos = {
    'AWS RDS': AWS_RDS,
    'AWS Cloudtrail': AWS_CloudTrail,
    'AWS Documentdb': AWS_Documentdb,
    'AWS Dynamodb': AWS_Dynamodb,
    'AWS EC2': AWS_EC2,
    'AWS ECS': AWS_ECS,
    'AWS IAM': AWS_IAM,
    'AWS Lambda': AWS_Lambda,
    'AWS S3': AWS_S3,
    'AWS ELB': AWS_ELB,
    'AWS General': AWS,
    'default': DefaultLogo
  };


    return (
        <Button
            variant="unstyled"
            p={0}
            h="auto"
            fontWeight="normal"
            onClick={() => onSelect(infraName)}
        >
            <HStack
                borderWidth={1}
                sx={sx}
                borderColor="#222222"
                bg="#1C1C1E"
                borderRadius="md"
                p={4}
            >
                <HStack w="100%">

                <Box
                    w="40px"
                    h="40px"
                    bg="white"
                    borderRadius="md"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Image
                        src={resourceLogos[resourceName] || resourceLogos["default"]} 
                        alt={resourceName}
                        w="32px"
                        h="auto"
                        objectFit="contain"
                    />
                </Box>


                    
                    <VStack align="start" pl={2} spacing={0} w="100%">
                        <HStack w='100%'>
                        <RegularText
                                sx={{
                                    color: "#DDDDDD",
                                }}
                            >
                                {infraName}
                            </RegularText>
                            
                            <Spacer></Spacer>
                            {status ? (
                                <CustomBadge
                                    text={status}
                                    colorScheme={
                                        status === "Coming soon"
                                            ? "yellow"
                                            : status === "Failing"
                                            ? "red"
                                            : "green"
                                    }
                                />
                            ) : (
                                taskData.length > 0 && taskData[0].files && taskData[0].files.length > 0 ? (
                                    <CustomBadge
                                        text='Evidence uploaded'
                                        colorScheme='green'
                                    />
                                ) : (
                                    <CustomBadge
                                        text='Evidence missing'
                                        colorScheme='red'
                                    />
                                )
                            )}
                        </HStack>
                    

                        <RegularText
                            sx={{
                                mt:1,
                                color: "#848484",
                                textAlign: "left",
                                overflow: "visible",
                                whiteSpace: "normal",
                                width: "100%",
                                overflowWrap: "break-word",
                            }}
                        >
                            {infraDescription}
                        </RegularText>
                    </VStack>
                </HStack>
                <Spacer></Spacer>
                <ChevronRightIcon
                    transition="transform 0.2s"
                    transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
                />
            </HStack>
        </Button>
    );
};

export default SelectInfrastructure;
