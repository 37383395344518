import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';

const CustomButton = ({ text, onClick, isDisabled, children, key, sx }) => {
  const [buttonText, setButtonText] = useState(text);
  const [disabled, setDisabled] = useState(isDisabled);

  useEffect(() => {
    setButtonText(text);
    setDisabled(isDisabled);
  }, [text, isDisabled]);

  const handleClick = () => {
    if (text === 'Add' || text === 'Invite') {
      setButtonText(text === 'Add' ? 'Adding...' : 'Inviting...');
      setDisabled(true);
    }
    onClick();
  };

  return (
    <Button
      key={key}
      sx={sx}
      bgGradient="linear(to-b, #DDDDDD, #777777)"
      boxShadow="0 0 20px rgba(255, 255, 255, 0.2)"
      border="0.5px solid"
      borderRadius="xl"
      borderColor="white"
      _hover={{
        bgGradient: "linear(to-b, #EEEEEE, #888888)",
        backgroundPosition: "center",
      }}
      w='auto'
      h='35px'
      fontSize='sm'
      p={3}
      onClick={handleClick}
      isDisabled={disabled}
    >
      {children || buttonText}
    </Button>
  );
};

export default CustomButton;