import React from 'react';

const HTMLRenderer = ({ html }) => {
  // Define the CSS for the font and color
  const fontCSS = `<style>
    body { 
      font-family: 'Inter', sans-serif; 
      color: #848484; 
      font-weight: 300;
      line-height: 1.5;
      font-size: 0.9em;
    }
    h1, h2, h3, h4 {
      color: #dddddd; /* Set color of headings to white */
      font-size: 1.1em; /* Smaller font size for headings */
      font-weight: 590;
    }
  </style>`;

  // Include the font and color CSS in the HTML content
  const fullHtml = fontCSS + html;

  // Create a blob URL for the full HTML content (including the font and color CSS)
  const blob = new Blob([fullHtml], { type: 'text/html' });
  const url = URL.createObjectURL(blob);

  return (
    <iframe src={url} style={{ width: '100%', height: '100%', padding: '0' }} frameBorder="0"></iframe>
  );
};

export default HTMLRenderer;