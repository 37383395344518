import React, { createContext, useContext } from 'react';
import useFetchIntegrationData from '../hooks/useFetchIntegrationData';

// Create the context
const IntegrationDataContext = createContext();

// Export the use of this context
export const useIntegrationData = () => useContext(IntegrationDataContext);

// Provider component
export const IntegrationDataProvider = ({ children }) => {
    const { integrationData, error, fetchIntegrationData } = useFetchIntegrationData();

    return (
        <IntegrationDataContext.Provider value={{ integrationData, error, fetchIntegrationData }}>
            {children}
        </IntegrationDataContext.Provider>
    );
};