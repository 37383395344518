import React, { useState } from 'react';
import Navbar from '../components/general/navbar';
import SectionBox from '../components/box/sectionbox';
import PageBox from '../components/box/pagebox';
import { HStack, Spacer, Box } from '@chakra-ui/react';
import TitleText from '../components/text/titletext';
import CustomButton from '../components/general/custombutton';
import Integration from '../components/general/integration';
import IntegrationModal from '../components/modal/integrationmodal';

const Integrations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <PageBox>
      <Navbar />
      <SectionBox>
        <HStack>
          <TitleText>Integrations</TitleText>
          <Spacer />
          <CustomButton text="+ Add integration" onClick={openModal} />
        </HStack>
        <Box mt='4vh' display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
          {[...Array(3)].map((_, index) => (
            <Integration key={index} />
          ))}
        </Box>
      </SectionBox>
      <IntegrationModal isOpen={isModalOpen} onClose={closeModal} />
    </PageBox>
  );
};

export default Integrations;