import React, { useState } from 'react';
import { Button, Box, Icon, Input, HStack, Text } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import { FaUpload, FaTimes } from 'react-icons/fa';

const CustomSingleUpload = ({ text, sx, onFileSelect, customRemoveFile }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (allowedTypes.includes(file.type)) {
            setSelectedFile(file);
            onFileSelect(file); // Call the callback with the selected file
        } else {
            alert('Only PDF, PNG, and JPG files are allowed.');
            event.target.value = ''; // Reset the input value
        }
    };

    const handleRemoveFile = () => {
        customRemoveFile();
        setSelectedFile(null);
        onFileSelect(null);
        // Call the callback with null to clear the file
    };

    return (
        <Box>
            <Button
                as="label"
                bg='#101012'
                border="0.5px solid #222222"
                color='#848484'
                borderRadius="xl"
                mr={2}
                boxShadow='none'
                _hover={{ borderColor: '#222222', boxShadow: 'none', cursor: 'pointer' }}
                _active={{ borderColor: '#222222', boxShadow: 'none' }}
                fontSize='sm'
                sx={sx}
            >
                <Box as="span" fontSize="2xl" bg='#272728' borderRadius='xl' pl={2} pr={2} pt={0} pb={0.3} mr={2}>
                    <Icon as={FaUpload} w={4} h={4} color="#848484" />
                </Box>
                <RegularText sx={{ fontWeight: 'normal' }}>{text}</RegularText>
                <Input
                    type="file"
                    accept=".pdf, .png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    display="none"
                />
                <RegularText sx={{ fontWeight: 'normal' }}>Upload a file</RegularText>
            </Button>
            {selectedFile && (
                <HStack mt={2} spacing={2} alignItems="center" display="inline-flex"
                    variant="unstyled"
                    borderRadius="xl"
                    border="1px solid #222222"
                    bg="transparent"
                    w='auto'
                    h='35px'
                    p={3}
                    color="#222222"
                >
                    <RegularText sx={{ color: '#dddddd' }} mr={2}>{selectedFile.name}</RegularText>
                    <Icon as={FaTimes} w={3} h={3} color="#848484" cursor="pointer" onClick={handleRemoveFile} />
                </HStack>
            )}
        </Box>
    );
};

export default CustomSingleUpload;