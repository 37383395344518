import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Table, Thead, Input, Skeleton, Collapse, Th, Grid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image, VStack, Button, Box, HStack, Spacer, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks from react-router-dom

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomBadge from '../../general/custombadge';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomModal from '../../modal/custommodal';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomButton from '../../general/custombutton';
import SubtitleText from '../../text/subtitletext';
import TitleText from '../../text/titletext';
import InfrastructureTask from './infrastructuretask';
import WhiteSubtitleText from '../../text/whitesubtitletext';
import AWSTestsView from './awstestsview';
import { useIntegrationData } from '../../../contexts/IntegrationDataContext';
import useFetchGlobalIntegrations from '../../../hooks/useFetchGlobalIntegrations';

import AWS from '../../../assets/AWS.png';
import GCP from '../../../assets/GCP.png';
import Mixpanel from '../../../assets/Mixpanel.png';
import Slack from '../../../assets/Slack.png';
import Github from '../../../assets/Github.png';
import Zoom from '../../../assets/Zoom.png';
import Notion from '../../../assets/Notion.png';
import Vercel from '../../../assets/Vercel.png';
import Cloudflare from '../../../assets/Cloudflare.png';
import Auth0 from '../../../assets/Auth0.png';
import Azure from '../../../assets/Azure.png';
import Datadog from '../../../assets/Datadog.png';
import Supabase from '../../../assets/Supabase.png';
import Google from '../../../assets/Google.png';
import Twilio from '../../../assets/Twilio.png';
import Vonage from '../../../assets/Vonage.png';
import OpenAI from '../../../assets/OpenAI.png';
import Anthropic from '../../../assets/Anthropic.png';
import DefaultLogo from '../../../assets/DefaultLogo.png';
import AWS_CloudTrail from '../../../assets/AWS_CloudTrail.png';
import AWS_Documentdb from '../../../assets/AWS_Documentdb.jpeg';
import AWS_Dynamodb from '../../../assets/AWS_DynamoDB.png';
import AWS_ECS from '../../../assets/AWS_ECS.png';
import AWS_IAM from '../../../assets/AWS_IAM.webp';
import AWS_Lambda from '../../../assets/AWS_Lambda.png';
import AWS_RDS from '../../../assets/AWS_RDS.png';
import AWS_S3 from '../../../assets/AWS_S3.png';
import AWS_ELB from '../../../assets/AWS_ELB.png';
import Cohere from '../../../assets/Cohere.png';
import Rippling from '../../../assets/Rippling.jpg';
import Ramp from '../../../assets/Ramp.png';
import DocSend from '../../../assets/DocSend.png';
import HubSpot from '../../../assets/HubSpot.jpeg';
import Railway from '../../../assets/Railway.png';
import BaseTen from '../../../assets/BaseTen.jpeg';
import ElevenLabs from '../../../assets/ElevenLabs.png';
import Namecheap from '../../../assets/Namecheap.png';
import Linear from '../../../assets/Linear.png';
import Deepgram from '../../../assets/Deepgram.png';
import NewRelic from '../../../assets/NewRelic.png';
import Sentry from '../../../assets/Sentry.png';
import Pinecone from '../../../assets/Pinecone.png';
import Helicone from '../../../assets/Helicone.png';
import Fly from '../../../assets/Fly.png';
import Redis from '../../../assets/Redis.png';
import CrunchyData from '../../../assets/CrunchyData.png';
import Axiom from '../../../assets/Axiom.jpg';
import Bland from '../../../assets/Bland.jpg';
import Retell from '../../../assets/Retell.png';

import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';
import UpdatedIntegrationCard from './updatedintegrationcard';
import UpdatedIntegrationView from './updatedintegrationview';

const TeamTable = ({ sx, addIntegrationsRef }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [arnValue, setArnValue] = useState('');
  const [copyText, setCopyText] = useState('Copy');
  const [category, setCategory] = useState('');
  const toast = useToast();
  const inputRef = useRef(null);
  
  const integrationActionBtnTextMap = {
    'AWS': 'Copy',
    'Github': 'Authorize',
    'Slack': 'Add',
    'Zoom': 'Add',
    'Notion': 'Add',
    'Vercel': 'Add',
    'Cloudflare': 'Add',
    'Auth0': 'Add',
    'Azure': 'Add',
    'Datadog': 'Add',
    'Supabase': 'Add',
    'Google': 'Add',
    'App': 'Add',
    'Pending': 'Awaiting Approval',
    'Deprecated': 'No Longer Supported',
  };

  const { integrationData, fetchIntegrationData } = useIntegrationData();
  const { globalIntegrations, loading, error } = useFetchGlobalIntegrations();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInputValue('');
    setSuggestions([]);
    setSelectedIntegration(null);
    setArnValue('');
    setCategory('');
  };

  useEffect(() => {
    if (inputValue.length > 1 && globalIntegrations) {
      const filteredSuggestions = globalIntegrations.filter(integration =>
        integration.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, globalIntegrations]);

  const handleSuggestionClick = (integration) => {
    setInputValue(integration.name);
    setSelectedIntegration(integration); // Set the selected integration
    setSuggestions([]);
    if (inputRef.current) {
      inputRef.current.blur(); // Blur the input field using the ref
    }
  };

  const handleIntegrationAction = () => {
    switch (selectedIntegration.name) {
      case 'AWS':
        handleCopyClick();
        break;
      case 'Github':
        handleGithubAuthorization();
        break;
      default:
        break;
    }
  };

  const handleGithubAuthorization = async () => {
    const token = await getSessionToken();
    try {
      const response = await axios.get(`${API_BASE_URL}compliance/integration/github/oauth/init/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      window.open(response.data.redirect_url, '_blank', 'noopener,noreferrer');
      posthog.capture('Initiated GitHub Authorization');
    } catch (error) {
      posthog.capture('Error Initiating GitHub Authorization', {
        error: error
      });
      console.error('Error initiating GitHub authorization:', error.message);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(selectedIntegration.onboarding_code);
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    setCopyText('Copied!');
    setTimeout(() => {
      setCopyText('Copy');
    }, 3000);
  };


  const relevantTaskIDs = [
    "89cdc8e7-1eb6-45bb-931b-32513cf81cb2",
    "e7ab1bae-5d0f-4388-95eb-e082f8bfcc2a",
    "e5e87f6e-3856-4e4c-8d51-1345f9ccd645",
    "1a8e03b2-1806-4133-927c-ecf7cb90c41f",
  ];
  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const [taskData, setTaskData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedChecked, setIsSubmittedChecked] = useState(false);
  const { updateTaskData } = useUpdateTaskData();

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
    setIsSubmittedChecked(true);
  }, [fetchedData]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (taskData && taskData.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [taskData]);


  const handleAddIntegration = async () => {
    const token = await getSessionToken();

    const updatedTasks = taskData.map(task => {
      if (task.global_task_id === "89cdc8e7-1eb6-45bb-931b-32513cf81cb2") {
        const evidence = `Added vendor ${selectedIntegration.name} and marked test as complete.`;
        posthog.capture('Added Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "e7ab1bae-5d0f-4388-95eb-e082f8bfcc2a") {
        const evidence = `Added vendor ${selectedIntegration.name} and marked test as complete.`;
        posthog.capture('Added Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "e5e87f6e-3856-4e4c-8d51-1345f9ccd645" && (selectedIntegration.name === "AWS" || selectedIntegration.name === "GCP")) {
        const evidence = `Added vendor ${selectedIntegration.name} and marked test as complete.`;
        posthog.capture('Added Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "1a8e03b2-1806-4133-927c-ecf7cb90c41f" && (selectedIntegration.name === "Github")) {
        const evidence = `Added vendor ${selectedIntegration.name} as version control system and marked test as complete.`;
        posthog.capture('Added Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      return task;
    });
    updateTaskData(updatedTasks);

    if (selectedIntegration) {
      try {
        await axios.post(`${API_BASE_URL}core/org/integration/`, {
          global_integration_id: selectedIntegration.id,
          credential_key: selectedIntegration.onboarding_instructions ? 'arn' : '',
          credential_value: selectedIntegration.onboarding_instructions ? arnValue : '',
          category: category
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        closeModal();
        fetchIntegrationData();
        posthog.capture('Added Integration', {
          integrationId: selectedIntegration.id,
          integrationName: selectedIntegration.name,
          category: category
        });
        closeModal();
      } catch (error) {
        posthog.capture('Error Adding Integration', {
          error: error
        });
        console.error('Error adding integration:', error);
      }
    }
  };

  const handleDeleteIntegration = async (integrationId) => {
    const token = await getSessionToken();

    const updatedTasks = taskData.map(task => {
      if (task.global_task_id === "89cdc8e7-1eb6-45bb-931b-32513cf81cb2") {
        const evidence = `Removed vendor with ID ${integrationId} and marked test as incomplete.`;
        posthog.capture('Removed Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "e7ab1bae-5d0f-4388-95eb-e082f8bfcc2a") {
        const evidence = `Removed vendor with ID ${integrationId} and marked test as incomplete.`;
        posthog.capture('Removed Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "e5e87f6e-3856-4e4c-8d51-1345f9ccd645" && (selectedIntegration?.name === "AWS" || selectedIntegration?.name === "GCP")) {
        const evidence = `Removed vendor with ID ${integrationId} and marked test as incomplete.`;
        posthog.capture('Removed Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      if (task.global_task_id === "1a8e03b2-1806-4133-927c-ecf7cb90c41f" && (selectedIntegration?.name === "Github")) {
        const evidence = `Removed vendor with ID ${integrationId} as version control system and marked test as incomplete.`;
        posthog.capture('Removed Integration', { evidence });
        return { ...task, complete: true, evidence };
      }
      return task;
    });
    updateTaskData(updatedTasks);

    try {
      await axios.delete(`${API_BASE_URL}core/org/integration/`, {
        data: {
          integration_id: integrationId
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      fetchIntegrationData();
      posthog.capture('Deleted Integration', {
        integrationId: integrationId
      });
      fetchIntegrationData();
    } catch (error) {
      posthog.capture('Error Deleting Integration', {
        error: error
      });
      console.error('Error deleting integration:', error);
    }
  };

  const modalHeader = <SubtitleText>Add integration</SubtitleText>;

  const integrationLogos = {
    'AWS': AWS,
    'GCP': GCP,
    'Mixpanel': Mixpanel,
    'Github': Github,
    'Slack': Slack,
    'Zoom': Zoom,
    'Notion': Notion,
    'Vercel': Vercel,
    'Cloudflare': Cloudflare,
    'Auth0': Auth0,
    'Azure': Azure,
    'Datadog': Datadog,
    'Supabase': Supabase,
    'Google': Google,
    'Twilio': Twilio,
    'Vonage': Vonage,
    'OpenAI': OpenAI,
    'Anthropic': Anthropic,
    'Cohere': Cohere,
    'AWS Cloudtrail': AWS_CloudTrail,
    'AWS Documentdb': AWS_Documentdb,
    'AWS Dynamodb': AWS_Dynamodb,
    'AWS ECS': AWS_ECS,
    'AWS IAM': AWS_IAM,
    'AWS Lambda': AWS_Lambda,
    'AWS RDS': AWS_RDS,
    'AWS S3': AWS_S3,
    'AWS ELB': AWS_ELB,
    'Cohere': Cohere,
    'Rippling': Rippling,
    'Ramp': Ramp,
    'DocSend': DocSend,
    'HubSpot': HubSpot,
    'Railway': Railway,
    'BaseTen': BaseTen,
    'ElevenLabs': ElevenLabs,
    'Namecheap': Namecheap,
    'Linear': Linear,
    'Deepgram': Deepgram,
    'NewRelic': NewRelic,
    'Fly': Fly,
    'Sentry': Sentry,
    'Pinecone': Pinecone,
    'Helicone': Helicone,
    'Redis': Redis,
    'CrunchyData': CrunchyData,
    'Axiom': Axiom,
    'Bland': Bland,
    'Retell': Retell,
    'default': DefaultLogo
  };

  useEffect(() => {
    if (!isModalOpen) {
        fetchIntegrationData();
    }
  }, [isModalOpen, fetchIntegrationData]);


  const [searchQuery, setSearchQuery] = useState('');

  const filteredIntegrations = useMemo(() => {
    return globalIntegrations.filter(integration =>
      integration.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      integration.name !== "General" && integration.name !== "Trash" && integration.name !== "App"
    );
  }, [globalIntegrations, searchQuery]);


  const modalBody = (
    <VStack spacing={6} align='left'>
      <VStack align='left'>


      <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        borderWidth="0"
        fontSize="sm"
        _placeholder={{ color: '#848484' }}
        _focus={{ borderWidth: 0, boxShadow: 'none' }}
        _hover={{ borderWidth: 0 }}
        pl={0}
    />



      <Grid templateColumns="repeat(1, 1fr)" gap={2} overflowY="auto" maxHeight="300px" css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',  /* IE and Edge */
          'scrollbar-width': 'none'  /* Firefox */
        }}>
        {globalIntegrations.map((integration, index) => (
        <Collapse key={index} in={filteredIntegrations.includes(integration)} w='100%'> {/* Assuming you want all to be initially expanded */}
            <Button
              key={integration.id}
              onClick={() => handleSuggestionClick(integration)}
              bg={selectedIntegration && selectedIntegration.id === integration.id ? '#353536' : 'transparent'} // Conditional background color
              borderRadius='xl'
              borderWidth={0.5}
              borderColor='transparent'
              p={1}
              w='100%'
              h='auto'
              justifyContent='flex-start'
              _hover={{ bg: '#353536' }}
              flexDirection="row"
            >
            <Box
            w="40px"
            h="40px"
            bg="white"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Image
                src={integrationLogos[integration.name] || integrationLogos["default"]} 
                alt={integration.name}
                w="32px"
                h="auto"
                objectFit="contain"
            />
        </Box>
        <RegularText sx={{ fontWeight: 'normal', ml:2, color: '#dddddd' }}>{integration.name}</RegularText>
        </Button>
        </Collapse>
          ))}
        </Grid>

        {selectedIntegration && selectedIntegration.onboarding_instructions && (
          <VStack align='start' border='1px' borderColor='#D73601' p={3} borderRadius='xl' mt='15px'>
            <RegularText>
              {selectedIntegration.onboarding_instructions}
            </RegularText>
            <CustomButton onClick={handleIntegrationAction}>
              {integrationActionBtnTextMap[selectedIntegration.name]}
            </CustomButton>

            {selectedIntegration.name === 'AWS' && <RegularText sx={{ color: '#dddddd', mt: '15px' }}>ARN</RegularText>}
            {selectedIntegration.name === 'AWS' && <CustomInput placeholder="arn:aws:iam::123456789012:role/my-role" value={arnValue} onChange={(e) => setArnValue(e.target.value)} />}
          </VStack>
        )}
      </VStack>

      <VStack align='left'>
        <RegularText sx={{ color: '#dddddd' }}>
          Category
        </RegularText>
        <CustomDropdown items={['Technical', 'Communication', 'Productivity', 'HR & payroll']} placeholder="Select category" onChange={(e) => setCategory(e.target.value)} />
      </VStack>
    </VStack>
  );

  const modalFooter = <CustomButton onClick={handleAddIntegration}>
    Add
  </CustomButton>;


    const [clickedIntegration, setClickedIntegration] = useState(null);
    const [clickedResource, setClickedResource] = useState(null);
    const [selectedTest, setSelectedTest] = useState(null);

    const [selectedResourceTests, setSelectedResourceTests] = useState([]);
    const [isTestModalOpen, setIsTestModalOpen] = useState(false);

    const closeTestModal = () => {
      setIsTestModalOpen(false);
      setSelectedTest(null);
    };

    

  return (
    <>
    <div ref={addIntegrationsRef}>



      {isLoading ? (
        <Box>
            <HStack>
                <TitleText>Add integrations</TitleText>
            </HStack>

            <Box bg="#222222" height="0px" mb={8} />
            <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={10}>
                {Array.from({ length: 9 }).map((_, index) => (
                    <Skeleton h='85px' key={index} w='100%' borderRadius='xl' startColor="#333333" endColor="#222222">
                    </Skeleton>
                ))}
            </Grid>
        </Box>
      ) : (
        <>
        {clickedIntegration && clickedResource ? (
          <Box>
            <Box w='fit-content'>
              <RegularText 
                  sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', _hover: { textDecoration: "underline" } }} 
                  onClick={() => {
                    setClickedResource(null);
                }}
              >
                  ← Back to all resources
              </RegularText>
            </Box>
            <AWSTestsView clickedResource={clickedResource} selectedResourceTests={selectedResourceTests} clickedIntegration={clickedIntegration} setSelectedTest={setSelectedTest} setIsTestModalOpen={setIsTestModalOpen} setSelectedResourceTests={setSelectedResourceTests}/>
          </Box>
        ) : clickedIntegration ? (
          <Box>

            <Box w='fit-content'>
              <RegularText 
                  sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', _hover: { textDecoration: "underline" } }} 
                  onClick={() => {
                      setClickedIntegration(null);
                      setClickedResource(null);
                      setSelectedResourceTests([]);
                  }}
              >
                  ← Back to all integrations
              </RegularText>
            </Box>
            <UpdatedIntegrationView clickedIntegration={clickedIntegration} integrationData={integrationData} setClickedResource={setClickedResource} selectedResourceTests={selectedResourceTests} setSelectedResourceTests={setSelectedResourceTests} clickedResource={clickedResource} />
          </Box>
        ) : (
            <>
              <HStack>
                  <TitleText>Add integrations</TitleText>
              </HStack>
              <Box bg="#222222" height="0px" mb={8} />
                <UpdatedIntegrationCard
                  integrationData={integrationData}
                  handleDeleteIntegration={handleDeleteIntegration}
                  handleIntegrationClick={(integration) => setClickedIntegration(integration)}
                />
                
                <Box mt={0}>
                <CustomOutlineButton onClick={openModal} text='+ Add integration' />
              </Box>
            </>
        )}
        </>
      )}
      </div>

      {selectedTest && (
        <Modal size="6xl" isOpen={isTestModalOpen} onClose={closeTestModal}>
            <ModalOverlay />
            <ModalContent bg='#1C1C1E' borderColor='#222222' borderWidth={1} borderRadius='md'>
                <ModalHeader>
                    <WhiteSubtitleText>{selectedTest.name}</WhiteSubtitleText>
                    <RegularText sx={{fontWeight:'normal'}}>{selectedTest.description}</RegularText>
                </ModalHeader>
                <ModalCloseButton color="#848484" />
                <ModalBody>
                    <InfrastructureTask
                        test_id={selectedTest.global_task_id}
                    /> 
                </ModalBody>
                <ModalFooter>
                    <CustomButton onClick={closeModal}>
                        Close
                    </CustomButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
      )}





      <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
    </>
  );
};

export default TeamTable;