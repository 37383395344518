import React from 'react';
import { HStack, VStack, Spacer, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import CustomDoneButton from '../../general/customdonebutton';
import SimulateTable from './simulatetable';
import TitleText from '../../text/titletext';

const SimulateIncident = ({ teamData }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
        <TitleText>Simulate a security incident</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            For each of the following scenarios, meet with at least one other team member and practice how you'd respond.
          </RegularText>
        </VStack>
      </HStack>

      <SimulateTable />

    </Box>
  );
};

export default SimulateIncident;