import React from 'react';
import { HStack, VStack, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import CompanyResourcesTable from './companyresourcestable';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
          <TitleText>List company resources</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            Keep track of which resources your company has. This will help you stay on top of what things touch PHI and help trace back in the case of a hack.
          </RegularText>
        </VStack>
      </HStack>


        <CompanyResourcesTable/>
    </Box>
  );
};

export default AddTeamMember;