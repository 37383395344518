import React from 'react';
import { HStack, VStack, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import HacksTable from './hackstable';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
        <TitleText>List previous hacks and security incidents</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            List any previous hacks or security incidents that happened at your company. Going forward, anytime a hack or security incident occurs, record it here.
          </RegularText>
        </VStack>
      </HStack>

      <HacksTable />

    </Box>
  );
};

export default AddTeamMember;