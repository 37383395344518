import React from 'react';

import Navbar from '../components/general/navbar';

import SectionBox from '../components/box/sectionbox';
import PageBox from '../components/box/pagebox';
import SectionTitle from '../components/text/titletext';

const Dashboard = () => {
  return (
    <PageBox>
        <Navbar />
        <SectionBox>
            <SectionTitle>Dashboard</SectionTitle>
        </SectionBox>
    </PageBox>
  );
};

export default Dashboard;