import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Skeleton,
  Th,
  Td,
  VStack,
  Box,
  Collapse,
  HStack,
  Icon,
  useToast,
  Link,
} from "@chakra-ui/react";
import RegularText from "../text/regulartext";
import CustomUpload from "../general/customupload";
import { FaChevronDown } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import useHandleFiles from "../../hooks/useHandleFiles";
import useUpdateTaskData from "../../hooks/useUpdateTaskData";
import { useTeamData } from "../../contexts/TeamDataContext";

const TeamRolesTable = ({ taskData = [], sx, onTaskDataChange, refetch }) => {
  const { teamData } = useTeamData();
  const names = teamData.map((member) => member.name);
  const [openTask, setOpenTask] = useState(null);
  const toast = useToast();
  const [forceUpdate, setForceUpdate] = useState(false);

  const toggleTask = (task) => {
    setOpenTask(openTask === task ? null : task);
  };

  const { handleFileUpload, handleRemoveFile, refreshData } = useHandleFiles(taskData, onTaskDataChange);

  const { updateTaskData } = useUpdateTaskData();

  const handleFileSelection = (file, taskId, task) => {
    handleFileUpload(file, taskId);
    const updatedTasks = taskData.map((task) => ({
      ...task,
      complete: true,
      evidence: `Marked task ${task.name} as complete`,
    }));
    updateTaskData(updatedTasks);
  };

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refreshData]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (taskData && taskData.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [taskData]);

  useEffect(() => {
    setForceUpdate((prev) => !prev);
  }, [refreshData]);

  return (
    <Box>
      {isLoading ? (
        <Box>
          <Table variant="simple" colorScheme="white">
            <Thead>
              <Tr>
                <Th color="#222222" width="70%">
                  <RegularText sx={{ fontSize: "xs" }}>Configuration</RegularText>
                </Th>
                <Th color="#222222" width="30%">
                  <RegularText sx={{ fontSize: "xs" }}>Screenshot</RegularText>
                </Th>
              </Tr>
            </Thead>
          </Table>
          <Skeleton height="2rem" ml="20px" mr="20px" mt="20px" mb="20px" startColor="#333333" endColor="#222222" />
          <Skeleton height="2rem" ml="20px" mr="20px" mt="20px" mb="20px" startColor="#333333" endColor="#222222" />
        </Box>
      ) : (
        <Table variant="simple" colorScheme="white" sx={sx}>
          <Thead>
            <Tr>
              <Th color="#222222" w="70%">
                <RegularText sx={{ fontSize: "xs" }}>Configuration</RegularText>
              </Th>
              <Th color="#222222" w="30%">
                <RegularText sx={{ fontSize: "xs" }}>Screenshot</RegularText>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {taskData
              .filter((task) => task !== undefined)
              .map((task, index) => (
                <Tr key={index} color="#222222">
                  <Td>
                    <VStack align="start" spacing={0} width="100%">
                      <RegularText sx={{ color: "#dddddd" }}>{task?.title}</RegularText>

                      <HStack
                        variant="unstyled"
                        p={0}
                        m={0}
                        onClick={() => toggleTask(task?.title)}
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                      >
                        <RegularText sx={{ fontWeight: "normal" }}>
                          {openTask === task?.title ? "Hide instructions" : "View instructions"}
                        </RegularText>
                        <Icon
                          as={FaChevronDown}
                          transform={openTask === task?.title ? "rotate(180deg)" : "rotate(0deg)"}
                          transition="transform 0.2s"
                          w={2}
                          h={2}
                          color="#848484"
                        />
                      </HStack>

                      <Collapse in={openTask === task?.title} animateOpacity>
                        <RegularText sx={{ color: "#848484", whiteSpace: "pre-wrap" }}>{task.description}</RegularText>
                      </Collapse>
                    </VStack>
                  </Td>
                  <Td>
                    <CustomUpload
                      placeholder="Select"
                      sx={{ bg: "#1C1C1E" }}
                      onFileSelect={(file) => handleFileSelection(file, task.id, task)}
                      customRemoveFile={(file) => handleRemoveFile(task.id, file.id, task)}
                      text=" "
                    />
                    {task?.files &&
                      task.files.length > 0 &&
                      task.files.map((file, fileIndex) => (
                        <HStack
                          key={`${task.id}-${fileIndex}`}
                          mt={2}
                          spacing={2}
                          alignItems="center"
                          display="inline-flex"
                          variant="unstyled"
                          borderRadius="xl"
                          border="1px solid #222222"
                          bg="transparent"
                          w="auto"
                          h="35px"
                          p={3}
                          color="#222222"
                        >
                          <Link fontSize="sm" href={file.file_url} isExternal color="blue.500">
                            {file.file_name.split("/").pop()}
                          </Link>
                          <Icon
                            as={FaTimes}
                            w={3}
                            h={3}
                            color="#848484"
                            cursor="pointer"
                            onClick={() => handleRemoveFile(task.id, file.id)}
                          />
                        </HStack>
                      ))}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default TeamRolesTable;
