import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import posthog from 'posthog-js';

const useFetchAccountReviews = () => {
    const [accountReviews, setAccountReviews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchAccountReviews = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let token = await getSessionToken();
            let retries = 0;
            const maxRetries = 5;

            while (!token && retries < maxRetries) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                token = await getSessionToken();
                retries++;
            }

            if (!token) {
                throw new Error("No session token available after retries");
            }

            const response = await axios.get(`${API_BASE_URL}core/org/review/account/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });
            setAccountReviews(response.data);
        } catch (error) {
            console.error('Error fetching account reviews:', error);
            setError(error);
            posthog.capture('Error Fetching Account Reviews', {
                error: error.message,
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAccountReviews();
    }, [fetchAccountReviews]);

    return { accountReviews, isLoading: loading, error, refetch: fetchAccountReviews };
};

export default useFetchAccountReviews;