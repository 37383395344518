import React, { useState, useEffect } from 'react';
import { Box, HStack, VStack, Spacer, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { ChevronRightIcon } from "@chakra-ui/icons";
import RegularText from '../../text/regulartext';
import IntegrationTestView from './integrationtestview';
import CustomButton from '../../general/custombutton';
import WhiteSubtitleText from '../../text/whitesubtitletext';
import InfrastructureTask from './infrastructuretask';
import SecurityReview from './securityreview';
import AccountReview from './accountreview';
import TitleText from '../../text/titletext';
import IntegrationSecurityTable from './integrationsecuritytable';
import useFetchTaskData from "../../../hooks/useFetchTaskData";
import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import posthog from "posthog-js";
import { API_BASE_URL } from "../../../utils/Constants";
import ResourceCard from "./resourcecard";

// const useTestStatusCheck = (testDetails) => {
//     const [taskData, setTaskData] = useState([]);
//     const relevantTaskIDs = [testDetails.global_task_id];
//     const { data: fetchedData, refetch } = useFetchTaskData(relevantTaskIDs);

//     useEffect(() => {
//         if (fetchedData) {
//             setTaskData(fetchedData);
//         }
//     }, [fetchedData]);

//     if (taskData.length > 0 && taskData[0].files && taskData[0].files.length > 0) {
//         return "Passing";
//     } else {
//         return "Failing";
//     }
// };

const UpdatedIntegrationView = ({ clickedIntegration, integrationData, setClickedResource, clickedResource, selectedResourceTests, setSelectedResourceTests }) => {
    const [lastTestRun, setLastTestRun] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [testButtonText, setTestButtonText] = useState("Run tests");
    const [integrationResources, setIntegrationResources] = useState([]);


    useEffect(() => {
        if (clickedIntegration) {
            fetchResources(clickedIntegration.id);
        }
    }, [clickedIntegration]);


    const fetchResources = async (integration_id) => {
        setIsLoading(true);

        const sessionToken = await getSessionToken();
        try {
            const response = await axios.post(
                `${API_BASE_URL}core/org/service/`,
                {
                    integration_id: integration_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionToken}`,
                        Accept: "application/json",
                    },
                }
            );
            const filteredData = response.data.map((service) => ({
                ...service,
                id: service.id,
                name:
                    service.name === "general"
                        ? "General"
                        : service.name.replace(/_/g, " ").length < 8
                        ? service.name.replace(/_/g, " ").toUpperCase()
                        : service.name
                              .replace(/_/g, " ")
                              .slice(0, 5)
                              .toUpperCase() +
                          service.name
                              .replace(/_/g, " ")
                              .slice(5)
                              .toLowerCase(),
            }));
            setIntegrationResources(filteredData);
            posthog.capture("Fetched Services for Integration", {
                integrationId: integration_id,
            });

            // Fetch tests for each resource
            filteredData.forEach(resource => {
                fetchTests(resource.id, resource.name);
            });


        } catch (error) {
            posthog.capture("Error Fetching Services for Integration", {
                error: error,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTests = async (resourceID, resourceName) => {
        setSelectedResourceTests([]);
        setIsLoading(true);
        const token = await getSessionToken();
        try {
            const response = await axios.get(
                `${API_BASE_URL}compliance/integration/test/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                    params: {
                        organization_service_id: resourceID,
                    },
                }
            );
            const testsWithResourceName = response.data.map(test => ({
                ...test,
                resourceName: resourceName  // Add the resource name to each test object
            }));
            setSelectedResourceTests(prevTests => [...prevTests, ...testsWithResourceName]);

            const lastTestRunDate = response.data[1]?.latest_test_run?.created;
            const formattedDate = lastTestRunDate
                ? new Date(lastTestRunDate).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      timeZoneName: "short",
                  })
                : "Never";
                if (formattedDate === "Never") {
                    setLastTestRun("Click the \"Run tests\" button on the right to run these tests.");
                } else {
                    setLastTestRun(`Tests last run on ${formattedDate}`);
                }

            posthog.capture("Fetched Tests for Integration", {
                integrationId: resourceID,
            });
        } catch (error) {
            console.error("Error during request:", error);
            setLastTestRun("Error fetching test data");
            posthog.capture("Error Fetching Tests for Integration", {
                error: error,
            });
        }
        setIsLoading(false);
    };




    const runTests = async (integration_id) => {
        const token = await getSessionToken();
        setTestButtonText("Running tests...");
        try {
            // Fetch the single resource for the given integrationId
            const response = await axios.post(
                `${API_BASE_URL}core/org/service/`,
                {
                    integration_id: integration_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                }
            );
    
            const resource = response.data[0]; // Assume only one resource is returned
            const formattedResource = {
                ...resource,
                id: resource.id,
                name:
                    resource.name === "general"
                        ? "General"
                        : resource.name.replace(/_/g, " ").length < 8
                        ? resource.name.replace(/_/g, " ").toUpperCase()
                        : resource.name
                              .replace(/_/g, " ")
                              .slice(0, 5)
                              .toUpperCase() +
                          resource.name
                              .replace(/_/g, " ")
                              .slice(5)
                              .toLowerCase(),
            };
            setIntegrationResources([formattedResource]);
            posthog.capture("Fetched Services for Integration", {
                integrationId: integration_id,
            });
    
            // Run test for the single resource
            await axios.post(
                `${API_BASE_URL}compliance/integration/test/`,
                { organization_service_id: formattedResource.id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                }
            );
            fetchTests(formattedResource.id, formattedResource.name); // Fetch tests for the resource
            posthog.capture("Ran Tests for Integration Resource", {
                integrationId: integration_id,
                resourceId: formattedResource.id,
            });
            console.log("Test initiated for resource:", formattedResource.id); // Log test initiation
    
            setTestButtonText("Re-run tests");
            console.log("Test initiated for integration:", integration_id); // Log completion of test initiation
        } catch (error) {
            console.error("Error during request:", error);
            setTestButtonText("Run tests");
            posthog.capture("Error Running Test for Resource", {
                error: error,
                integrationId: integration_id,
            });
        }
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTest, setSelectedTest] = useState(null); // Add state to hold the selected test

    const openModal = (test) => {
        setSelectedTest(test);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTest(null);
    };


    

    
    return (
        <Box w="100%">
            {selectedTest && (
            <Modal size="6xl" isOpen={isModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent bg='#1C1C1E' borderColor='#222222' borderWidth={1} borderRadius='md'>
                    <ModalHeader>
                        <WhiteSubtitleText>{selectedTest.name}</WhiteSubtitleText>
                        <RegularText sx={{fontWeight:'normal'}}>{selectedTest.description}</RegularText>
                    </ModalHeader>
                    <ModalCloseButton color="#848484" />
                    <ModalBody>
                        <InfrastructureTask
                            test_id={selectedTest.global_task_id}
                        /> 
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton onClick={closeModal}>
                            Close
                        </CustomButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            )}

            <TitleText>{clickedIntegration.name}</TitleText>

            <WhiteSubtitleText sx={{mb:4, mt:8}}>Integration security</WhiteSubtitleText>

            <IntegrationSecurityTable clickedIntegration={clickedIntegration.name}/>



            <WhiteSubtitleText sx={{mt:12}}>Integration tests</WhiteSubtitleText>

            {clickedIntegration.name === 'AWS' ? (
                <ResourceCard integrationResources={integrationResources} setClickedResource={setClickedResource}/>
            ) : (
                <>

                {clickedIntegration.name === 'Github' && (
                    <HStack>
                        <RegularText>{lastTestRun}</RegularText>
                        <Spacer />
                        <CustomButton
                            text={testButtonText}
                            onClick={() => runTests(clickedIntegration.id)}
                        />
                    </HStack>
                )}

                <Box
                    mt="2vh"
                    display="grid"
                    gridTemplateColumns="repeat(1, 1fr)"
                    gap={2}
                >
              {Array.from(new Set(
                  (clickedResource ? selectedResourceTests.filter(test => test.resourceName === clickedResource.name) : selectedResourceTests)
                  ?.map(test => test.name)
              )).map((uniqueName, index) => {
                  const testDetails = selectedResourceTests.find(test => test.name === uniqueName);
                  return (
                      <IntegrationTestView
                          key={index}
                          onSelect={() => openModal(testDetails)}
                          infraName={uniqueName}
                          infraDescription={testDetails.description}
                          status={testDetails.latest_test_run?.status || ""}
                          resourceName={testDetails.resourceName}
                          showProgress={false}
                          test_id={testDetails.global_task_id}
                      />
                  );
              })}
                </Box>
            </>)}
        </Box>
    );
};

export default UpdatedIntegrationView;
