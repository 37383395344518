import CustomModal from './custommodal';
import React, { useState, useEffect } from 'react';
import SubtitleText from '../text/subtitletext';
import ModalSelector from './modalselector';
import AddIntegration from './addintegration';
import CustomButton from '../general/custombutton';

const IntegrationModal = ({ isOpen, onClose }) => {
  const [showAddIntegration, setShowAddIntegration] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const IntegrationOptions = [
    'AWS',
    'GCP',
    'Azure',
    'Vercel',
    'Github',
    'Supabase',
    'Cloudflare',
    'Auth0',
    'Mixpanel',
    'Datadog',
    'Slack',
    'Zoom',
    'Notion',
    'Google Workspace'
  ];
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowAddIntegration(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setShowAddIntegration(false);
      setSelectedOption(null);
    }
  }, [isOpen]);

  const ModalHeader = () => (
    <SubtitleText>{showAddIntegration ? 'Connect integration' : 'Add integration'}</SubtitleText>
  );

  const ModalBody = () => (
    showAddIntegration ? <AddIntegration selectedOption={selectedOption} /> : <ModalSelector options={IntegrationOptions} onOptionClick={handleOptionClick} />
  );

  const ModalFooter = () => (
    showAddIntegration ? <CustomButton text='Submit' /> : null
  );

  return (
    <CustomModal 
      isOpen={isOpen} 
      onClose={onClose} 
      modalHeader={<ModalHeader />}
      modalBody={<ModalBody />}
      modalFooter={<ModalFooter />}
    />
  );
};

export default IntegrationModal;