import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';
import posthog from 'posthog-js';

const useHandleFiles = (taskData, onTaskDataChange) => {
    const toast = useToast();
    const [refreshData, setRefreshData] = useState(false);

    const handleFileUpload = async (files, taskid) => {
        if (files && files.length > 0) {
            const token = await getSessionToken();
            const formData = new FormData();
            
            for (const file of files) {
                formData.append('files', file);
            }
            formData.append('organization_task_id', taskid);

            try {
                const response = await axios.post(`${API_BASE_URL}core/org/task/upload/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                toast({
                    title: "Files Uploaded",
                    description: "The files have been successfully uploaded.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setRefreshData(prev => !prev); // Toggle refreshData to signal change
                posthog.capture('Uploaded Files', {
                    files: files
                });

            } catch (error) {
                toast({
                    title: "Upload Failed",
                    description: `Failed to upload files: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                posthog.capture('Error Uploading Files', {
                    error: error
                });
            }
        }
    };

    const handleRemoveFile = async (taskid, fileid) => {
        const token = await getSessionToken();
        try {
            const response = await axios.delete(`${API_BASE_URL}core/org/task/upload/`, {
                data: {
                    organization_task_id: taskid,
                    file_id: fileid
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            toast({
                title: "File Removed",
                description: "The file has been successfully removed.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            const updatedTaskData = taskData.map(task => {
                if (task.id === taskid) {
                    return { ...task, file_url: null };
                }
                return task;
            });
            onTaskDataChange(updatedTaskData);
            setRefreshData(prev => !prev); // Toggle refreshData to signal change
            posthog.capture('Removed File', {
                fileId: fileid
            });
    
        } catch (error) {
            console.log(error);
            posthog.capture('Error Removing File', {
                error: error
            });
        }
    };

    return { handleFileUpload, handleRemoveFile, refreshData };
};

export default useHandleFiles;
