import React, { useState, useEffect } from 'react';
import { Modal, Spacer, ModalOverlay, Box, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, HStack, VStack } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import CustomDropdown from '../general/customdropdown';
import CustomButton from '../general/custombutton';
import HTMLRenderer from '../compliance/documents/htmlrenderer';

const CustomDocumentModal = ({ isOpen, onClose, modalFooter, doc, frameworks, onDoneClick, names, teamData, handleSelectionChange }) => {
    const [selectedUser, setSelectedUser] = useState('Select');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent bg='#1C1C1E' borderColor='#222222' borderWidth={1} borderRadius='md'>
        <ModalHeader>
            <SubtitleText sx={{bgClip: 'text', bgGradient: 'linear(to-b, #dddddd, #777777)'}}>{doc.title}</SubtitleText>
            <RegularText sx={{fontWeight:'normal'}}>
                Required for {frameworks}
            </RegularText>
        </ModalHeader>
        <ModalCloseButton color="#848484" />
        <ModalBody bg="#1C1C1E" color="red">
            <HStack spacing={6} align='left'>
                <VStack w='70%'  bg='#101012' borderColor='#222222' borderWidth={1} borderRadius='xl' pl={3} pr={3} h='70vh' overflowY='auto' overflowX='hidden' style={{ scrollbarWidth: 'none' }}>
                    <HTMLRenderer html={doc.document_html} />
                </VStack>
                <VStack w='30%' align='left'>
                    <Box p={3} borderColor='#D73601' borderWidth={1} borderRadius='xl'>
                        <SubtitleText >
                            Summary
                        </SubtitleText>
                        <RegularText sx={{color: '#dddddd', whiteSpace: 'pre-wrap'}}>{doc.description}</RegularText>
                    </Box>

                    <Spacer></Spacer>

                    <RegularText sx={{color: '#dddddd', mt: '2vh'}}>
                        I agree to this policy
                    </RegularText>
                    <RegularText>
                        You have reviewed this policy and agree to following it.
                    </RegularText>
                    <CustomDropdown                                   
                        items={['Select', ...names]}
                        value={selectedUser}
                        onChange={(event) => {
                        setSelectedUser(event.target.value);
                        handleSelectionChange(doc.title, event);
                        }}
                    />

                    <HStack mt='2vh' justifyContent='center'>
                        <CustomButton text='Submit' onClick={() => { onClose(); onDoneClick(); }} />
                    </HStack>
                </VStack>

            </HStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomDocumentModal;