import React from 'react';
import { HStack, VStack, Spacer, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import RiskAssessmentTable from './riskassessmenttable';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
        <TitleText>List the risks to your company</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            List 5-10 risks that pose a threat to your company.
          </RegularText>
        </VStack>
      </HStack>

      <RiskAssessmentTable />

    </Box>
  );
};

export default AddTeamMember;