import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Thead, Tbody, Tr, Th, Td, useToast, Box, HStack, Spacer, VStack } from '@chakra-ui/react';

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomBadge from '../../general/custombadge';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomButton from '../../general/custombutton';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomModal from '../../modal/custommodal';
import SubtitleText from '../../text/subtitletext';
import { useTeamData } from '../../../contexts/TeamDataContext';
import useFetchRiskData from '../../../hooks/useFetchRiskData';
import axios from 'axios';
import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';

import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';

const riskColors = {
    '1': 'green',
    '2': 'yellow',
    '3': 'orange',
    '4': 'pink',
    '5': 'purple',
  };

  const RiskAssessmentTable = ({ sx }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [riskInput, setRiskInput] = useState('');
    const [impactInput, setImpactInput] = useState('');
    const [likelihoodInput, setLikelihoodInput] = useState('');
    const [resolutionInput, setResolutionInput] = useState('');
    const [addedByInput, setAddedByInput] = useState('');
    const [taskData, setTaskData] = useState([]);

    const toast = useToast();
    const { riskData, refetch: refetchRiskData } = useFetchRiskData();
    const { teamData } = useTeamData();
    const names = teamData.map(member => member.name);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
      setRiskInput('');
      setImpactInput('');
      setLikelihoodInput('');
      setResolutionInput('');
      setAddedByInput('');
      setIsModalOpen(false);
    };

    const relevantTaskIDs = [
      "56aa4674-953d-4ec9-aec8-3357531da1cb",
    ];

    const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
    const { updateTaskData } = useUpdateTaskData();

    useEffect(() => {
      setTaskData(fetchedData);
    }, [fetchedData]);

    const handleAddRisk = async () => {
        const token = await getSessionToken();
        const selectedMember = teamData.find(member => member.name === addedByInput);
        const updatedTasks = taskData.map(task => ({
          ...task, 
          complete: true, 
          evidence: `Marked task ${task.name} as complete by ${selectedMember.name} for risk assessment with description: ${riskInput}, impact: ${impactInput}, likelihood: ${likelihoodInput}, resolution: ${resolutionInput}.`
        }));
        updateTaskData(updatedTasks);

        if (riskInput && impactInput && likelihoodInput && resolutionInput && addedByInput) {
          try {
            await axios.post(`${API_BASE_URL}core/org/risk/`, {
              description: riskInput,
              impact: impactInput,
              likelihood: likelihoodInput,
              resolution: resolutionInput,
              associated_user: selectedMember.id,
              associated_user_name: selectedMember.name
            }, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            toast({
              title: 'Risk added',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            closeModal();
            refetchRiskData();
            posthog.capture('Added Risk', {
              riskInput: riskInput,
              impactInput: impactInput,
              likelihoodInput: likelihoodInput,
              resolutionInput: resolutionInput,
              addedByInput: addedByInput
            });
          } catch (error) {
            posthog.capture('Error Adding Risk', {
              error: error
            });
            console.error('Error adding risk:', error);
          }
        }
    };

      const handleDeleteRisk = async (riskId) => {

        const updatedTasks = taskData.map(task => ({
          ...task, 
          evidence: `Removed risk with ID ${riskId} from risk assessment list.`
        }));
        updateTaskData(updatedTasks);

        

        const token = await getSessionToken();
        try {
          await axios.delete(`${API_BASE_URL}core/org/risk/`, {
            data: {
              id: riskId
            },
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          toast({
            title: 'Risk deleted',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          refetchRiskData();
          posthog.capture('Removed Risk', {
            riskId: riskId
          });
        } catch (error) {
          toast({
            title: 'Error deleting risk',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          console.error('Error deleting risk:', error);
        }
      };



  const modalHeader = <SubtitleText>Add risk</SubtitleText>;
  const modalBody = (
    <VStack spacing={6} align='left'>
        <VStack align='left'>
            <RegularText sx={{color: '#dddddd'}}>
                Risk
            </RegularText>
            <CustomInput placeholder="Sending sensitive info to the wrong recipient" value={riskInput} onChange={(e) => setRiskInput(e.target.value)}/>
        </VStack>


        <HStack w='100%'>
            <VStack align='left' w='50%'>
                <RegularText sx={{color: '#dddddd'}}>
                    Impact
                </RegularText>
                <CustomDropdown items={['1', '2', '3','4','5']} placeholder="Select" value={impactInput} onChange={(e) => setImpactInput(e.target.value)}/>
            </VStack>

            <VStack align='left' w='50%'>
                <RegularText sx={{color: '#dddddd'}}>
                    Likelihood
                </RegularText>
                <CustomDropdown items={['1', '2', '3','4','5']} placeholder="Select" value={likelihoodInput} onChange={(e) => setLikelihoodInput(e.target.value)}/>
            </VStack>
        </HStack>


        <VStack align='left'>
                <RegularText sx={{color: '#dddddd'}}>
                    Resolution
                </RegularText>
                <CustomInput placeholder="Enable undo send in Gmail for up to 30 seconds" value={resolutionInput} onChange={(e) => setResolutionInput(e.target.value)}/>
            </VStack>

        <VStack align='left'>
            <RegularText sx={{color: '#dddddd'}}>
                Who will be responsible for this risk?
            </RegularText>
            <CustomDropdown                                   
          items={['Select', ...names]}
          value={addedByInput}
          onChange={(e) => setAddedByInput(e.target.value)}
        />
                </VStack>

    </VStack>
  );
  const modalFooter = <CustomButton onClick={handleAddRisk} text='Add'></CustomButton>;


  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      if (taskData && taskData.length > 0) {  // Check that data is not just present but also has length
          setIsLoading(false);
      } else {
          setIsLoading(true);
      }
  }, [taskData]);




  return (
    <>

        { isLoading ? (
                        <Box>
                        <Table variant="simple" colorScheme='white'>
                        <Thead>
                            <Tr>
                                <Th color="#222222" width="70%">
                                    <RegularText sx={{fontSize: 'xs'}}>Configuration</RegularText>
                                </Th>
                                <Th color="#222222" width="30%">
                                    <RegularText sx={{fontSize: 'xs'}}>Screenshot</RegularText>
                                </Th>
                            </Tr>
                        </Thead>
                    </Table>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                </Box>
               ) : (
    

    <Table variant="simple" colorScheme='white' sx={sx}>
        <Thead>
            <Tr>
            {['Risk', 'Impact','Likelihood','Resolution','Added by'].map((header, index) => (
                <Th key={index} color="#222222">
                    <RegularText sx={{fontSize: 'xs'}}>{header}</RegularText>
                </Th>
            ))}
            </Tr>
        </Thead>
        <Tbody>
            {riskData.map((risk, index) => (
            <Tr key={index} color="#222222">
                <Td>
                    <RegularText sx={{color: '#DDDDDD'}}>{risk.description}</RegularText>
                </Td>
                <Td>
                <CustomBadge text={risk.impact} colorScheme={riskColors[risk.impact]} />
                </Td>
                <Td>
                    <CustomBadge text={risk.likelihood} colorScheme={riskColors[risk.likelihood]} />
                </Td>
                <Td>
                    <RegularText>{risk.resolution}</RegularText>
                </Td>
                <Td>
                    <HStack>
                        <RegularText>{risk.associated_user_name}</RegularText>
                        <Spacer/>
                        <ThreeDots deleteFunction={() => handleDeleteRisk(risk.id)}/>
                    </HStack>
                </Td>
            </Tr>
            ))}
        </Tbody>
    </Table>

          )}

    <HStack mt={5}>
        <CustomOutlineButton
          onClick={openModal}
          text="+ Risk"
        />
    </HStack>

    <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
    </>
  );
};

export default RiskAssessmentTable;