import React, { useState, useEffect } from 'react';
import { HStack, Box, Tabs, TabList, Table, Thead, Tr, Th, Skeleton, TabPanel, TabPanels, Tab } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import useFetchTaskData from '../../hooks/useFetchTaskData';
import CustomUploadFileTable from '../compliance/customuploadfiletable';

const AddTeamMember = ({ teamData }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const macTaskIDs = [
      "c337417b-5a21-4466-8de9-907cdb6bb000",
      "0c3a4a04-2395-4dcb-87af-b6ae8d8ced56",
      "16950dfa-b6e8-4e30-b6fb-41c839bdacb3",
      "2b82fdb0-e036-41a3-a0b4-fb970d324198",
    ];
    const windowsTaskIDs = [
      "2b82fdb0-e036-41a3-a0b4-fb970d324198",
      "c8fefaf2-17ac-4485-bdda-9eef806858c2",
      "4e522e75-6ba8-4563-aa43-29be76410e2d",
      "92915e32-84a7-4aa0-8f15-f4925ff1cb6e",
      "40ce9a04-7fc5-40fc-88d0-1f296d2862d2",
    ];

    const taskIDs = tabIndex === 0 ? macTaskIDs : windowsTaskIDs;
    const { data: fetchedData, refetch } = useFetchTaskData(taskIDs);

    const [taskData, setTaskData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
      setIsLoading(true);
      // Simulate fetching data
      setTimeout(() => {
          setTaskData(fetchedData);
          setIsLoading(false);
          const allTasksComplete = fetchedData.every(task => task.complete);
          setIsSubmitted(allTasksComplete);
      }, 1000); // assuming fetching takes 1 second
  }, [fetchedData]);

    const handleTaskDataChange = (updatedTaskData) => {
        setTaskData(updatedTaskData);
    };

    return (
        <Box mb={16} mt={4}>
            <Tabs variant='soft-rounded' mt={2} size='sm' onChange={(index) => {
              setTabIndex(index);
              setIsLoading(true);
            }} h='80%'>
                  <TabList justifyContent="flex-start">
                    <HStack borderWidth='1px' w='auto' bg='#1C1C1E' padding={0.5} borderRadius='full' borderColor='#222222'>
                        <Tab mr='-5px' _selected={{ bg: '#101012', border: '1px solid', borderColor: '#222222', boxShadow: 'sm' }}>
                        {tabIndex === 0 && (
                            <Box as="span" mr={1} ml={-1}>
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="#D73601"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="5" cy="5" r="5" />
                              </svg>
                            </Box>
                          )}
                            <RegularText sx={{fontWeight: 'normal'}}>Mac</RegularText>
                        </Tab>
                        <Tab _selected={{ bg: '#101012', border: '1px solid', borderColor: '#222222', boxShadow: 'sm' }}>
                          {tabIndex === 1 && (
                            <Box as="span" mr={1} ml={-1}>
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="#D73601"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="5" cy="5" r="5" />
                              </svg>
                            </Box>
                          )}
                            <RegularText sx={{fontWeight: 'normal'}}>Windows</RegularText>
                        </Tab>
                    </HStack>
                </TabList>

                <TabPanels h='100%'>
       <TabPanel h='100%' p={0} pt={2}>
           {isLoading ? (
               <Box>
                <Table variant="simple" colorScheme='white'>
                  <Thead>
                      <Tr>
                          <Th color="#222222" width="70%">
                              <RegularText sx={{fontSize: 'xs'}}>Configuration</RegularText>
                          </Th>
                          <Th color="#222222" width="30%">
                              <RegularText sx={{fontSize: 'xs'}}>Screenshot</RegularText>
                          </Th>
                      </Tr>
                  </Thead>
              </Table>
              <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
              <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
              <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
              </Box>
           ) : (
               <CustomUploadFileTable taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
           )}
       </TabPanel>
       <TabPanel h='100%' p={0} pt={2}>
           {isLoading ? (
               <Box>
                  <Table variant="simple" colorScheme='white'>
                    <Thead>
                        <Tr>
                            <Th color="#222222" width="70%">
                                <RegularText sx={{fontSize: 'xs'}}>Configuration</RegularText>
                            </Th>
                            <Th color="#222222" width="30%">
                                <RegularText sx={{fontSize: 'xs'}}>Screenshot</RegularText>
                            </Th>
                        </Tr>
                    </Thead>
                </Table>
                <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
               </Box>
           ) : (
               <CustomUploadFileTable taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
           )}
       </TabPanel>
   </TabPanels>
            </Tabs>
        </Box>
    );
};

export default AddTeamMember;