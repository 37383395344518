import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchRiskData = () => {
    const [riskData, setRiskData] = useState([]);
    const [error, setError] = useState(null);
    const toast = useToast();

    const fetchRiskData = useCallback(async () => {
        const sessionToken = await getSessionToken();
        try {
            const response = await axios.get(`${API_BASE_URL}core/org/risk/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Accept': 'application/json',
                },
            });
            setRiskData(response.data);
        } catch (error) {
            console.error('Error during request:', error);
            setError(error);
            toast({
                title: "Error loading risk data",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            posthog.capture('Error Fetching Risk Data', {
                error: error
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchRiskData();
    }, [fetchRiskData]);

    return { riskData, error, refetch: fetchRiskData };
};

export default useFetchRiskData;