import React, { useState, useEffect } from 'react';
import { HStack, VStack, Skeleton, Box, Grid } from '@chakra-ui/react';
import useFetchTaskData from '../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../hooks/useUpdateTaskData';
import TeamComplianceDocumentsBox from './teamcompliancedocumentsbox';

const SignDocuments = ({ }) => {

  const [taskData, setTaskData] = useState([]);


  const relevantTaskIDs1 = [
    "5e2537c0-9065-401d-901c-14d546b7cea6",
    "fa8fa5dd-0c3c-4377-ac3d-eb7be47aac57",
    "e80bdaf4-6c13-4584-84e7-b33c5c61ebfc",
    "e3823cac-700c-4f8c-b623-2c8fbfeec35d",
  ];

  const relevantTaskIDs2 = [
    "e1c3d329-1399-4a1f-8a92-5b129c7d1c1b",
    "d141a5f8-afde-4d2b-b45c-0ca287624977",
    "ce60f286-e51e-4d03-b308-1e66201e2ac4",
    "b5732b9d-a5b1-4587-94e7-05382cc2319e",
  ];

  const relevantTaskIDs3 = [
    "b10f0d34-de63-43b7-8cc5-6b1d7db5920b",
    "af6debdc-c0bc-4beb-a67c-e6f4a2498cad",
    "a9e1497b-cf0d-4e39-a199-213b2bed2d8b",
    "a4ee1235-e349-4278-85e3-2eaa52a68eca",
  ];

  const relevantTaskIDs4 = [
    "82e312c9-47a6-4aa2-aa17-69b5eae2af55",
    "7a5d8c15-9a62-4c0d-b116-7de5a8761e13",
    "63ab8411-48b1-4db3-91d2-5992e18a8ed0",
    "58d0a1e9-57d9-4ed0-8db0-f4801c510b9b",
  ];

  const relevantTaskIDs5 = [
    "4123b9e5-db05-43eb-bb71-202792ef81a2",
    "3f448dc6-ad93-40ba-97db-dc05c20ff8bd",
    "3c5c0400-c322-4d66-97e4-772221bd9252",
    "319e1b03-b487-421a-a824-9ac2bb8da8ad",
  ];

  const relevantTaskIDs6 = [
    "3037386d-a84e-4209-b81c-f0b1d697afd1",
    "2e44f7b5-02c5-44df-a4a8-0d63eb54771f",
    "1eaa84aa-fed4-4b9e-a973-fe5876da7db0",
    "07fb15da-c7fa-48cc-9be6-c8c59e199e19",
  ];

  const relevantTaskIDs7 = [
    "003f8d18-f301-405e-b430-ea1306670929",
    "cfbafc40-ac2c-470b-be24-d2ef435c885b",
  ];

  const { data: fetchedData1 } = useFetchTaskData(relevantTaskIDs1);
  const { data: fetchedData2 } = useFetchTaskData(relevantTaskIDs2);
  const { data: fetchedData3 } = useFetchTaskData(relevantTaskIDs3);
  const { data: fetchedData4 } = useFetchTaskData(relevantTaskIDs4);
  const { data: fetchedData5 } = useFetchTaskData(relevantTaskIDs5);
  const { data: fetchedData6 } = useFetchTaskData(relevantTaskIDs6);
  const { data: fetchedData7 } = useFetchTaskData(relevantTaskIDs7);
  // const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    const allDataFetched = [fetchedData1, fetchedData2, fetchedData3, fetchedData4, fetchedData5, fetchedData6, fetchedData7]
      .every(data => data && data.length > 0); // Check that each is truthy and has length greater than 0
  
    if (allDataFetched) {
      const combinedData = [
        ...fetchedData1, ...fetchedData2, ...fetchedData3, ...fetchedData4,
        ...fetchedData5, ...fetchedData6, ...fetchedData7
      ];
      setTaskData(combinedData);
      setIsLoading(false); // Set loading to false only after all data is confirmed fetched
    } else {
      setIsLoading(true);
    }
  }, [fetchedData1, fetchedData2, fetchedData3, fetchedData4, fetchedData5, fetchedData6, fetchedData7]);

  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
  }, [fetchedData]);

  const handleTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  const handleDoneClick = () => {
    const updatedTasks = taskData.map(task => ({ ...task, complete: true, evidence: `Marked ${task.name} as complete` }));
    updateTaskData(updatedTasks);
  };


  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
        </VStack>
      </HStack>
      {isLoading ? (
        <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={10}>
          {Array.from({ length: 9 }).map((_, index) => (
            <Skeleton h='200px' key={index} w='100%' borderRadius='xl' startColor="#333333" endColor="#222222">
            </Skeleton>
          ))}
        </Grid>
      ) : (
        <>
          <TeamComplianceDocumentsBox onDoneClick={handleDoneClick} taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
        </>
      )}
    </Box>
  );
};

export default SignDocuments;