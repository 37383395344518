import React, { useState } from 'react';
import RegularText from '../../text/regulartext';
import SubtitleText from '../../text/subtitletext';
import { Spacer, HStack, Box, Button, VStack } from '@chakra-ui/react';
import TitleText from '../../text/titletext';
import AddSecurityColumn from './addsecuritycolumn';
import RecordAllHacks from './recordallhacks';
import RiskAssessment from './riskassessment';
import SimulateIncident from './simulateincident';
import ListCompanyResources from './listcompanyresources';

const SecurityTab = ({ trackSecurityTasksRef, listCompanyResourcesRef, listRisksRef, simulateSecurityIncidentRef, listPreviousHacksRef }) => {

  return (
        <>

          <div mt={6} ref={trackSecurityTasksRef}><AddSecurityColumn/></div>

          <div ref={listCompanyResourcesRef}><ListCompanyResources/></div>

          <div ref={listRisksRef}><RiskAssessment/></div>

          <div ref={simulateSecurityIncidentRef}><SimulateIncident/></div>

          <div ref={listPreviousHacksRef}><RecordAllHacks/></div>

        </>
     );
   };

export default SecurityTab;

