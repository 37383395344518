import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import posthog from 'posthog-js';

const CompanyInfoContext = createContext();

export const useCompanyInfo = () => useContext(CompanyInfoContext);

export const CompanyInfoProvider = ({ children }) => {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    companyLegalName: '',
    companyDescription: '',
    hipaa: false,
    soc2: false,
    image: null,
    isLoading: true,
  });

  const loadCompanyInfo = async () => {
    try {
      const token = await getSessionToken();
      const response = await axios.get(`${API_BASE_URL}core/org/company/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompanyInfo({
        ...companyInfo,
        hipaa: response.data.hipaa,
        soc2: response.data.soc2,
        companyName: response.data.name,
        companyLegalName: response.data.legal_name,
        companyDescription: response.data.description,
        image: response.data.logo_url,
        isLoading: false,
      });
      posthog.capture('Fetched Company Information');
    } catch (error) {
      posthog.capture('Error Fetching Company Information', {
        error: error
      });
      setCompanyInfo({ ...companyInfo, isLoading: false });
    }
  };

  return (
    <CompanyInfoContext.Provider value={{ companyInfo, loadCompanyInfo }}>
      {children}
    </CompanyInfoContext.Provider>
  );
};