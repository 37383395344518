import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ComplianceNavbar from '../components/compliance/compliancenavbar';
import SectionBox from '../components/box/sectionbox';
import CustomPagination from '../components/pagination/custompagination';
import ComplianceRouter from '../components/compliance/compliancerouter';
import { Box } from '@chakra-ui/react';


const Compliance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(() => {
    const query = new URLSearchParams(location.search);
    return query.get('page') ? Number(query.get('page')) : 1;
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = query.get('page');
    if (page) {
      setCurrentPage(Number(page));
    }
  }, [location.search]);

  const handlePageChange = (page) => {
    const query = new URLSearchParams(location.search);
    query.set('page', page);
    query.delete('infra'); // Remove the infra parameter
    query.delete('resource'); // Remove the resource parameter
    navigate(`/compliance?${query.toString()}`);
  };

  const setPage = (page) => {
    handlePageChange(page);
  };

  return (
    <Box
      w='100%'
      minH='100vh' // Ensures the Box takes at least the full height of the viewport
      bg='#101012' // Sets the background color to black
    >
      <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, mb: '6vh', zIndex: 1 }}>
        <ComplianceNavbar />
        <CustomPagination currentPage={currentPage} onPageChange={handlePageChange} totalPages={10} />
      </Box>
      <SectionBox>
        <Box mt="18vh">
          <ComplianceRouter currentPage={currentPage} setPage={setPage} />
        </Box>
      </SectionBox>
    </Box>
  );
};

export default Compliance;