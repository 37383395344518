import React, { useState, useEffect } from 'react';
import { VStack, HStack, Flex, Spacer, Button, Box, Radio, RadioGroup, Stack, useToast } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import CustomDoneButton from '../general/customdonebutton';
import useFetchTaskData from '../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../hooks/useUpdateTaskData';
// import HIPAA from '../../assets/HIPAA.mp4'
// import SOC2 from '../../assets/SOC2.mp4'

import axios from 'axios';
import { API_BASE_URL } from '../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';

import posthog from 'posthog-js';

const hipaaQuestions = [
  {
    question: "What does HIPAA stand for?",
    answers: [
      "Health Insurance Potability and Accountancy Act",
      "Health Interoperability and Patient Assistance Act",
      "Health Insurance Portability and Accountability Act"
    ],
    correctAnswer: "3"
  },
  {
    question: "As a SaaS startup and customer of Delve, what are you classified as under HIPAA?",
    answers: [
      "Covered Entity",
      "Business Associate",
      "Healthcare Clearinghouse"
    ],
    correctAnswer: "2"
  },
  {
    question: "What is Protected Health Information?",
    answers: [
      "Sensitive medical data that can be traced back to a patient",
      "Any sensitive data",
      "Encrypted data"
    ],
    correctAnswer: "1"
  },
  {
    question: "Why are policies required to get HIPAA compliant?",
    answers: [
      "They describe what technical and non-technical tasks your organization will complete to get and stay compliant",
      "You need to share these policies to demonstrate compliance to large enterprises",
      "HIPAA lawyers require it"
    ],
    correctAnswer: "1"
  },
  {
    question: "What technical standards does HIPAA require?",
    answers: [
      "Data encrypted in transit and at rest",
      "Public SSH access into EC2 instances",
      "No databases allowed"
    ],
    correctAnswer: "1"
  },
  {
    question: "What do you need to do with all 3rd party companies you work with that process your company's PHI?",
    answers: [
      "Sign BAAs",
      "Ensure they are HIPAA compliant",
      "Both of the above"
    ],
    correctAnswer: "3"
  },
  {
    question: "What does the 'Minimum Necessary' principle state?",
    answers: [
      "Only the smallest amount of PHI necessary should be used or disclosed to achieve the intended purpose",
      "The minimum amount of time should be taken to notify individuals about a data breach",
      "The minimum number of people should be hired onto the company"
    ],
    correctAnswer: "1"
  }
];








const soc2Questions = [
  {
    question: "Who created the SOC2 framework?",
    answers: [
      "International Standards Organization",
      "American Institute of Certified Public Accountants",
      "Federal Bureau of Investigation"
    ],
    correctAnswer: "2"
  },
  {
    question: "Under which condition are you legally obligated to get SOC2 compliant?",
    answers: [
      "When handling sensitive customer data",
      "When operating internationally",
      "There is no case where you must legally get SOC2 compliant. It is only needed when a company asks for it as part of a contract"
    ],
    correctAnswer: "3"
  },
  {
    question: "Which SOC2 Trust Services Criteria will you work on and comply with for a SOC2 audit?",
    answers: [
      "Privacy",
      "Availability",
      "Security"
    ],
    correctAnswer: "3"
  },
  {
    question: "What's the difference between SOC2 Type 1 and Type 2 audits?",
    answers: [
      "Type 1 is at a specific point in time, while Type 2 is over a period of time",
      "Type 1 is for internal use only, Type 2 is for public reporting",
      "Type 1 is less detailed than Type 2"
    ],
    correctAnswer: "1"
  },
  {
    question: "What should you do when you see an email and suspect it is phishing?",
    answers: [
      "Do not open it and report it to the team",
      "Open it to confirm your suspicions",
      "Reply to verify the sender"
    ],
    correctAnswer: "1"
  },
  {
    question: "If a contractor wants access to your AWS account, what should you do?",
    answers: [
      "Grant access immediately",
      "Determine if they truly need access and then grant it",
      "Deny access"
    ],
    correctAnswer: "2"
  },
  {
    question: "Which of the following is a best practice that you should follow?",
    answers: [
      "Code is directly pushed to the main branch",
      "Code requires PRs with secondary approval to merge",
      "Code is written without comments or documentation"
    ],
    correctAnswer: "2"
  }
];


const AddTeamMember = ({ }) => {

  const toast = useToast();

  const [taskData, setTaskData] = useState([]);
  const relevantTaskIDs = [
    "502c43e0-080c-4cb9-b7b6-83532b72d556",
    "2cb94081-ef36-4745-8d62-331d1eef097b",
  ];

  const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every(task => task.complete);
    setIsSubmitted(allTasksComplete);
    setShowHipaaTraining(fetchedData.some(task => task.global_task_id === "502c43e0-080c-4cb9-b7b6-83532b72d556"));
    setShowSoc2Training(fetchedData.some(task => task.global_task_id === "2cb94081-ef36-4745-8d62-331d1eef097b"));
  }, [fetchedData]);

  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedSoc2Answers, setSelectedSoc2Answers] = useState({});

  const [attempted, setAttempted] = useState(false);
  const [attemptedSoc2, setAttemptedSoc2] = useState(false);

  const [correctCount, setCorrectCount] = useState(0);
  const [correctCountSoc2, setCorrectCountSoc2] = useState(0);


  const handleHipaaAnswerChange = (questionIndex, answer) => {
    setSelectedAnswers(prev => ({ ...prev, [questionIndex]: answer }));
  };

  const handleSoc2AnswerChange = (questionIndex, answer) => {
    setSelectedSoc2Answers(prev => ({ ...prev, [questionIndex]: answer }));
  };

  const setTrainingComplete = async (hipaa, soc2) => {
    const token = await getSessionToken();
    const payload = {};
    if (hipaa !== undefined) payload.hipaa_training = hipaa;
    if (soc2 !== undefined) payload.soc2_training = soc2;

    const response = await axios.post(`${API_BASE_URL}core/user/training/`, payload, { headers: { Authorization: `Bearer ${token}` } });
    
    if (response.status === 200) {
      toast({
        title: "Training Complete",
        description: "Your training status has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      posthog.capture('Completed Training', {
        hipaa: hipaa,
        soc2: soc2
      });
    }
    else {
      posthog.capture('Error Updating Training Status', {
        error: response.data
      });
    }
  };

  const checkHipaaAnswers = async () => {
    const results = hipaaQuestions.map((question, index) => {
      return selectedAnswers[index] === question.correctAnswer;
    });
    const correctAnswers = results.filter(Boolean).length;
    setCorrectCount(correctAnswers);
    setAttempted(true);

    if (correctAnswers === hipaaQuestions.length) {
      await setTrainingComplete(true, undefined);

      const updatedTasks = taskData.map(task => {
        if (task.global_task_id === "502c43e0-080c-4cb9-b7b6-83532b72d556") {
          return { ...task, complete: true, evidence: `Completed and passed HIPAA Security training with score 7/7.` };
        }
        return task;
      });
      updateTaskData(updatedTasks);
    }
  };

  const checkSoc2Answers = async () => {
    const results = soc2Questions.map((question, index) => {
      return selectedSoc2Answers[index] === question.correctAnswer;
    });
    const correctAnswers = results.filter(Boolean).length;
    setCorrectCountSoc2(correctAnswers);
    setAttemptedSoc2(true);

    if (correctAnswers === soc2Questions.length) {
      await setTrainingComplete(undefined, true);

      const updatedTasks = taskData.map(task => {
        if (task.global_task_id === "2cb94081-ef36-4745-8d62-331d1eef097b") {
          return { ...task, complete: true, evidence: `Completed and passed SOC2 Security training with score 7/7.` };
        }
        return task;
      });
      updateTaskData(updatedTasks);
    }
  };

  const [showHipaaTraining, setShowHipaaTraining] = useState(false);
  const [showSoc2Training, setShowSoc2Training] = useState(false);

  return (
    <Box mb={16} w='100%' >
      {showHipaaTraining && (
      <Flex mt={2} mb={4} w='100%' align="stretch" alignItems='stretch' h='35vh'>

        <Box w='60%' mr={4}>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JENPmWgzXII" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>

        <Box w='40%' h='100%' overflowY='auto'>
          <VStack align='start' spacing={4}>
            {hipaaQuestions.map((question, index) => (
              <VStack key={index} align='start'>
                <RegularText sx={{ color: '#dddddd' }}>{question.question}</RegularText>
                <RadioGroup sx={{ mb: 2 }} onChange={(value) => handleHipaaAnswerChange(index, value)} value={selectedAnswers[index] || ''}>
                  <Stack direction="column">
                    {question.answers.map((answer, answerIndex) => (
                      <Radio key={answerIndex} size="sm" value={String(answerIndex + 1)}>
                        <RegularText>{answer}</RegularText>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </VStack>
            ))}
            <Button
              borderRadius='xl'
              pt={0}
              pb={0}
              pl={2}
              pr={2}
              h='30px'
              onClick={checkHipaaAnswers}
              colorScheme={correctCount === hipaaQuestions.length ? 'green' : 'red'}
            >
              <RegularText sx={{ color: '#dddddd' }}>
                {attempted ? (correctCount === hipaaQuestions.length ? '100% correct!' : `${correctCount}/${hipaaQuestions.length} correct, try again`) : 'Check answers'}
              </RegularText>
            </Button>
          </VStack>
        </Box>
      </Flex>
    )}

      {showSoc2Training && (
      <Flex mt={6} w='100%' align="stretch" alignItems='stretch' h='35vh'>
        <Box w='60%' mr={4}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/vSpBT9mXtOw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>
        <Box w='40%' h='100%' overflowY='auto'>  {/* This Box now controls the overflow */}
          <VStack align='start' spacing={4}>
            {soc2Questions.map((question, index) => (
              <VStack key={index} align='start'>
                <RegularText sx={{ color: '#dddddd' }}>{question.question}</RegularText>
                <RadioGroup sx={{ mb: 2 }} onChange={(value) => handleSoc2AnswerChange(index, value)} value={selectedSoc2Answers[index] || ''}>
                  <Stack direction="column">
                    {question.answers.map((answer, answerIndex) => (
                      <Radio key={answerIndex} size="sm" value={String(answerIndex + 1)}>
                        <RegularText>{answer}</RegularText>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </VStack>
            ))}
            <Button
              borderRadius='xl'
              pt={0}
              pb={0}
              pl={2}
              pr={2}
              h='30px'
              onClick={checkSoc2Answers}
              colorScheme={correctCountSoc2 === soc2Questions.length ? 'green' : 'red'}
            >
              <RegularText sx={{ color: '#dddddd' }}>
                {attemptedSoc2 ? (correctCountSoc2 === soc2Questions.length ? '100% correct!' : `${correctCountSoc2}/${soc2Questions.length} correct, try again`) : 'Check answers'}
              </RegularText>
            </Button>
          </VStack>
        </Box>
      </Flex>
      )}

    </Box>
  );
};

export default AddTeamMember;