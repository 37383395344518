import React from 'react';
import { Box, Text, HStack, VStack, Avatar, Icon, Tr, Td, Tbody, Th, Thead, Table } from "@chakra-ui/react";
import RegularText from './../text/regulartext.js';
import TitleText from './../text/titletext.js';
import WhiteSubtitleText from './../text/whitesubtitletext.js';
import DelveLogo from '../../assets/DelveLogo.png'

const ComplianceCompanyHeader = ({AccentColor}) => {
  return (
    <Box bg='#191919'>
      <Box borderTopRadius='lg' borderBottomRadius='0' ml={['1rem', '2rem', '10rem']} mr={['1rem', '2rem', '10rem']} pl={['0.5rem', '2rem', '4rem']} pr={['0.5rem', '2rem', '4rem']} pt='6rem' pb='6rem'>
        <HStack align="start">
          <VStack align="left">
            <HStack>
                <WhiteSubtitleText>Powered by Delve</WhiteSubtitleText>
                {/* <img src={DelveLogo} style={{ width: '80px', height: 'auto', marginTop: '-14px', marginLeft: '-2px' }}></img> */}
            </HStack>
            <RegularText mb='0.5rem'>Leading companies use Delve to get compliant. Delve provides compliance automation, security control implementation, and real-time risk mitigation.</RegularText>
            <HStack mt={6}>
              <RegularText>
                <a href="https://getdelve.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: AccentColor }}>Learn more</a>
              </RegularText>
            </HStack>
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};

export default ComplianceCompanyHeader;
