import React, { useState, useEffect } from 'react';
import { HStack, VStack, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import ManageEmployeeTable from './manageemployeetable';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import TitleText from '../../text/titletext';

const AddTeamMember = () => {

  const [taskData, setTaskData] = useState([]);
  const relevantTaskIDs = [
    "cb57b6be-d8eb-4542-8acd-383baa0033e1",
    "83d9eade-cf9d-4874-acaa-29bf1811aa96",
    "930ceecd-f0cc-4797-97be-60f23da66a91",
    "2e3bd614-3207-4399-b77c-f9cc3c014ac5",
    "07f8033f-c01e-42b3-b7e5-f778edc66c00",
    "f5615cd5-0347-4d07-8c28-a62187e9cb14",
    "b7b46428-1379-4a83-ac83-2a83635bbfa3",
    "faa31d85-4ccb-4ff5-88ac-1a3ac7394f76",
    "8f3550aa-1520-4e01-8ef4-9397290bffec",
    "1a073a66-b01e-47b3-93d9-65e2d268962c",
    "8f771442-6220-4cf8-b66a-d797bce06eab",
    "b2c1d99c-f087-43ae-8a70-ec53fce79a43",
    "253943d8-6882-45ab-bf60-4bb59e006e0a",
  ];

  const { data: fetchedData, refetch } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      const taskMap = new Map(fetchedData.map(task => [task.global_task_id, task]));
      const orderedData = relevantTaskIDs.map(id => taskMap.get(id));
      setTaskData(orderedData);
      const allTasksComplete = orderedData.every(task => task && task.complete);
      setIsSubmitted(allTasksComplete);
    }
  }, [fetchedData]);

  const handleTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
          <TitleText>Follow team management procedures</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            Anytime a team member joins, leaves, or changes positions within the company, major changes happen within the company, complaints or incidents arise, ensure that you follow the procedures outlined below. Upload evidence to demonstrate you have these procedures in place or have followed them in the past.
          </RegularText>
        </VStack>
      </HStack>

      <ManageEmployeeTable updateTaskData={updateTaskData} taskData={taskData} onTaskDataChange={handleTaskDataChange}/>
    </Box>
  );
};

export default AddTeamMember;