import React from 'react';
import { Button } from '@chakra-ui/react';
import RegularText from '../text/regulartext';

const CustomOutlineButton = ({ onClick, text, sx }) => {
  return (
    <Button
      variant="unstyled"
      borderRadius="xl"
      bg='#1C1C1E'
      border="0.5px solid #222222"
      color='#848484'
      w='auto'
      h='35px'
      sx={sx}
      p={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
    >
      <RegularText sx={{ fontWeight: 'normal' }}>{text}</RegularText>
    </Button>
  );
};

export default CustomOutlineButton;
