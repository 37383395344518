import React, { useState, useEffect } from 'react';
import { HStack, VStack, Spacer, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import TeamRolesTable from './teamrolestable';
import CustomDoneButton from '../../general/customdonebutton';
import TitleText from '../../text/titletext';


const AddTeamMember = ({ }) => {

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
          <TitleText>Assign team roles</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            To get compliant, you need to ensure the following roles are met by members of your team. For each role, select one person from your team to appoint. The same person can be appointed to multiple roles.
          </RegularText>
        </VStack>
      </HStack>
      <TeamRolesTable />
    </Box>
  );
};

export default AddTeamMember;