import React from 'react';
import { VStack } from '@chakra-ui/react';

const PageBox = ({ children }) => {
  return (
    <VStack
      w='100%'
      h='100vh'
      bg='#101012'
      spacing={0}
      align='center'
      justify='center'
    >
      {children}
    </VStack>
  );
};

export default PageBox;