import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { API_BASE_URL } from "../utils/Constants";
import posthog from "posthog-js";

const useFetchIntegrationData = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchIntegrationData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let token = await getSessionToken();
      let retries = 0;
      const maxRetries = 5;

      while (!token && retries < maxRetries) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        token = await getSessionToken();
        retries++;
      }

      if (!token) {
        throw new Error("No session token available after retries");
      }

      const url = `${API_BASE_URL}core/org/integration/`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching integration data:", error);
      setError(error);
      posthog.capture("Error Fetching Integration Data", {
        error: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, []);

    useEffect(() => {
        fetchIntegrationData();
    }, []);

    return { integrationData: data, error, fetchIntegrationData };
};

export default useFetchIntegrationData;