import React, { useState, useEffect } from "react";
import {
  InputGroup,
  Button,
  InputRightElement,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Link,
  useToast,
  Spacer,
  HStack,
  VStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { getSessionToken } from "@descope/react-sdk";
import { API_BASE_URL } from "../../../utils/Constants";
import axios from "axios";
import RegularText from "../../text/regulartext";
import CustomBadge from "../../general/custombadge";
import CustomInput from "../../general/custominput";
import CustomUpload from "../../general/customupload";
import { FaTimes } from "react-icons/fa";
import useHandleFiles from "../../../hooks/useHandleFiles";
import MessageView from "./MessageView";
import "./InfraTask.css";
import { useUser } from "@descope/react-sdk";
import { FaCode, FaBook, FaEllipsisV } from "react-icons/fa";
import posthog from "posthog-js";

import useFetchTaskData from "../../../hooks/useFetchTaskData";
import useUpdateTaskData from "../../../hooks/useUpdateTaskData";

const InfraTask = ({ test_id, sx }) => {
  const categoryColors = {
    Passing: "green",
    "Coming soon...": "yellow",
    Failing: "red",
  };

  const toast = useToast();

  const [taskData, setTaskData] = useState([]);
  const relevantTaskIDs = [test_id];

  const { data: fetchedData, refetch } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [tasks, setTasks] = useState([]);
  const [testData, setTestData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);

  const [messages, setMessages] = useState([
    { text: "Hey, I'm Dover, here to help with compliance!", isUserMessage: false },
    { text: "How would you like to tackle this task?", isUserMessage: false },
  ]);

  const [input, setInput] = useState("");
  const [thread, setThread] = useState({});
  const [threadId, setThreadId] = useState(null);
  const { user, isUserLoading } = useUser();

  const [codeGenerationPrompt, setCodeGenerationPrompt] = useState("");

  useEffect(() => {
    if (!isUserLoading && user) {
      setCodeGenerationPrompt(
        `Help ${user.givenName} resolve the compliance issue with code. Start by saying Hi and asking what language they prefer (e.g. Terraform, CDK, Pulumi, CLI, or any other).`
      );
    }
  }, [isUserLoading, user]);

  useEffect(() => {
    setTaskData(fetchedData);
    const allTasksComplete = fetchedData.every((task) => task.complete);
    setIsSubmitted(allTasksComplete);
  }, [fetchedData]);

  const onTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  const { handleFileUpload, handleRemoveFile, refreshData } = useHandleFiles(taskData, onTaskDataChange);

  useEffect(() => {
    refetch();
  }, [refreshData]);

  const handleSendMessage = async (prompt = null, isPrompt = false, isUserMessage = false) => {
    const messageToSend = prompt || input.trim();
    if (messageToSend) {
      const newMessage = { text: messageToSend, isUserMessage: isUserMessage };
      if (isPrompt) {
        setMessages([...messages, "..."]); // Add a placeholder message for the prompt
      } else {
        setMessages([...messages, newMessage, "..."]); // Add a placeholder message for user input
      }
      setInput("");

      try {
        const token = await getSessionToken();
        const response = await fetch(`${API_BASE_URL}compliance/remediation/chat/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            thread_id: threadId,
            message: messageToSend,
          }),
        });

        if (!response.body) {
          throw new Error("ReadableStream not supported in this browser.");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;
        let accumulatedMessage = "";

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              const jsonString = line.substring(6).trim();

              if (jsonString) {
                try {
                  const data = JSON.parse(jsonString);
                  if (data.type === "text_created" || data.type === "text_delta") {
                    accumulatedMessage += data.content;
                    const cleanedMessage = cleanMessage(accumulatedMessage);
                    setMessages((prevMessages) => {
                      const updatedMessages = [...prevMessages];
                      updatedMessages.splice(-1, 1, { text: cleanedMessage, isUserMessage: false }); // Replace the last placeholder with the actual message
                      return updatedMessages;
                    });
                  }
                } catch (e) {
                  console.error("Error parsing JSON:", e, "Raw string:", jsonString);
                }
              }
            }
          }
        }
        posthog.capture("Chat Message Sent", {
          message: messageToSend,
          thread_id: threadId,
        });
      } catch (error) {
        console.error("Error sending message:", error);
        setError(error);
        posthog.capture("Error Sending Chat Message", {
          error: error,
        });
      }
    }
  };

  const cleanMessage = (message) => {
    message = message.replace(/Text\(annotations=\[\], value='[^']*'\)/g, "");
    message = message.replace(/Text\(annotations=\[\], value="[^"]*"\)/g, "");
    return message;
  };

  const fetchTasks = async () => {
    if (test_id) {
      try {
        const token = await getSessionToken();
        const response = await axios.get(`${API_BASE_URL}compliance/integration/task/org/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          params: {
            global_task_id: test_id,
            provide_identifiers: true,
          },
        });
        setTasks(response.data);

        // Directly set the remediation steps as messages without parsing
        setMessages((prevMessages) => {
          const newMessage = {
            text: response.data.remediation
              .split("\n")
              .filter((step) => step.trim() !== "")
              .join("\n"),
            isUserMessage: false  // Set isUserMessage to false for API responses
          };
        
          // Check if the new message text is already included in the previous messages to avoid duplicates
          if (!prevMessages.some(msg => msg.text === newMessage.text)) {
            return [...prevMessages, newMessage];
          }
          return prevMessages;
        });

        
        posthog.capture("Fetched Tasks for Integration");
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setError(error);
        posthog.capture("Error Fetching Tasks for Integration", {
          error: error,
        });
      }
    }
  };

  const fetchTestResults = async () => {
    if (test_id) {
      try {
        const token = await getSessionToken();
        const response = await axios.get(`${API_BASE_URL}compliance/integration/test/results/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          params: {
            global_task_id: test_id,
          },
        });
        setTestData(response.data);
        posthog.capture("Fetched Test Results for Integration for " + test_id);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setError(error);
        posthog.capture("Error Fetching Test Results for Integration", {
          error: error,
        });
      }
    }
  };

  const fetchThread = async () => {
    if (test_id) {
      try {
        const token = await getSessionToken();
        const response = await axios.get(`${API_BASE_URL}compliance/remediation/chat/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          params: {
            task_id: test_id,
          },
        });
        setThread(response.data.thread);
        setThreadId(response.data.thread_id);
        posthog.capture("Fetched Thread for Integration for " + test_id);
      } catch (error) {
        console.error("Error fetching thread:", error);
        setError(error);
        posthog.capture("Error Fetching Thread for Integration", {
          error: error,
        });
      }
    }
  };

  const handleMarkAsIrrelevant = async (serviceId, taskId, identifierId, ignore) => {
    try {
      const token = await getSessionToken();
      const response = await axios.put(
        `${API_BASE_URL}your/api/endpoint/${serviceId}`,

        //{"<task_id>": {"ignore": True, "identifiers": {"identifier_1": {"ignore": True}}}}

        { [taskId]: { ignore: false, identifiers: { [identifierId]: { ignore: ignore } } } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API response:", response.data);
    } catch (error) {
      console.error("Error posting to API:", error);
    }
  };

  useEffect(() => {
    fetchTestResults();
    fetchThread();
  }, [test_id]);

  return (
    <VStack mt={7} align="start" w="100%">
      <HStack w="100%" align="start" sx={sx} spacing={10}>
        <VStack w="50%" spacing={1} align="start">

          {testData && !(testData.test_run_identifiers && testData.test_run_identifiers.length > 0) && (
            <VStack align="start">
              <RegularText sx={{ color: "#DDDDDD", mt: 2 }}>Evidence</RegularText>
              <RegularText>
                Upload evidence demonstrating that you've completed this task. You can upload multiple files.
              </RegularText>

              <CustomUpload
                placeholder="Select"
                sx={{ bg: "#1C1C1E" }}
                text=" "
                onFileSelect={(file) => {
                  if (taskData.length > 0 && taskData[0]) {
                    handleFileUpload(file, taskData[0].id);
                  } else {
                    console.error("No task data available.");
                    toast({
                      title: "Upload Error",
                      description: "No task data available to upload file.",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }}
                customRemoveFile={(file) => handleRemoveFile(taskData[0].id, file.id)}
              />

              {taskData.length > 0 && taskData[0].files && taskData[0].files.length > 0 ? (
                taskData[0].files.map((file, index) => (
                  <HStack
                    key={index}
                    mt={2}
                    spacing={2}
                    alignItems="center"
                    display="inline-flex"
                    variant="unstyled"
                    borderRadius="xl"
                    border="1px solid #222222"
                    bg="transparent"
                    w="auto"
                    h="35px"
                    p={3}
                    color="#222222"
                  >
                    <Link fontSize="sm" href={file.file_url} isExternal color="blue.500">
                      {file.file_name.split("/").pop()}
                    </Link>
                    <Icon
                      as={FaTimes}
                      w={3}
                      h={3}
                      color="#848484"
                      cursor="pointer"
                      onClick={() => handleRemoveFile(taskData[0].id, file.id)}
                    />
                  </HStack>
                ))
              ) : (
                <h1></h1>
              )}
            </VStack>
          )}

          {testData.test_run_identifiers && testData.test_run_identifiers.length > 0 ? (
            <VStack align="start" w='100%'>
            <RegularText sx={{ color: "#DDDDDD", mt: 2 }}>Live scanning</RegularText>
            <RegularText>
              Our live scanning shows the following evidence for this test.
            </RegularText>


            <Box maxH="55vh" overflow="scroll" w='100%'>
              <Table variant="simple" colorScheme="white" w="100%">
                <Thead>
                  <Tr>
                    {["Instance", "Region", "Status"].map((header, index) => (
                      <Th key={index} color="#222222">
                        <RegularText sx={{ fontSize: "xs" }}>{header}</RegularText>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {testData.test_run_identifiers.map((instance, index) => (
                    <Tr key={index} color="#222222" bg={selectedInstance === instance ? "#444444" : "transparent"}>
                      <Td>
                        <RegularText
                          sx={{
                            color: "#DDDDDD",
                            maxWidth: "7vw",
                            overflow: "visible",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                          }}
                        >
                          {instance.name}
                        </RegularText>
                      </Td>
                      <Td>
                        <RegularText sx={{ color: "#DDDDDD" }}>{instance.region}</RegularText>
                      </Td>
                      <Td>
                        <CustomBadge
                          text={instance.passing ? "Passing" : "Failing"}
                          colorScheme={categoryColors[instance.passing ? "Passing" : "Failing"]}
                        />
                      </Td>
                      {/* <Td>
                        <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<FaEllipsisV />}
                          variant="unstyled"
                        />
                        <MenuList>
                          <MenuItem onClick={() => handleMarkAsIrrelevant(test_id, instance.id, true)}>
                            Mark Irrelevant
                          </MenuItem>
                          <MenuItem onClick={() => handleMarkAsIrrelevant(test_id, instance.id, false)}>
                            Mark Relevant
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      </Td> */}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            </VStack>
          ) : (
            <HStack></HStack>
          )}
        </VStack>

        <VStack
          spacing={4}
          align="stretch"
          w="50%"
          p={4}
          bg="#101012"
          h="65vh"
          borderRadius="xl"
          border="1px"
          borderColor="#222222"
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <VStack
            overflowY="auto"
            h="100%"
            borderRadius="md"
            spacing={1}
            sx={{ "&::-webkit-scrollbar": { display: "none" } }}
          >
            {messages.map((msg, index) => (
              <React.Fragment key={index}>
                <MessageView message={msg.text} isUserMessage={msg.isUserMessage} />
                {index === 1 && (
                  <HStack p={1} alignSelf="center" mb={2}>
                    <Button
                      onClick={() => {
                        fetchTasks();
                      }}
                      bg="#101012"
                      color="#848484"
                      size="md"
                      variant="solid"
                      borderRadius="xl"
                      border="0.5px solid #848484"
                      _hover={{ bg: "#2C2C2E" }}
                      _focus={{ boxShadow: "none" }}
                      _active={{ boxShadow: "none" }}
                      leftIcon={<FaBook color="orange" />}
                    >
                      <RegularText sx={{ color: "#848484", fontWeight: "normal" }}>View Tutorial</RegularText>
                    </Button>
                    <Button
                      onClick={() => {
                        handleSendMessage(codeGenerationPrompt, true);
                      }}
                      bg="#101012"
                      color="#848484"
                      size="md"
                      variant="solid"
                      borderRadius="xl"
                      border="0.5px solid #848484"
                      _hover={{ bg: "#2C2C2E" }}
                      _focus={{ boxShadow: "none" }}
                      _active={{ boxShadow: "none" }}
                      leftIcon={<FaCode color="#00FF99" />}
                      ml={2}
                    >
                      <RegularText sx={{ color: "#848484", fontWeight: "normal" }}>Generate Code</RegularText>
                    </Button>
                  </HStack>
                )}
              </React.Fragment>
            ))}
          </VStack>

          <HStack color='#DDDDDD'>
            <InputGroup color='#DDDDDD'>
              <CustomInput
                placeholder="Ask Dover anything..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission if inside a form
                    handleSendMessage(input, false, true);
                  }
                }}
                color='#DDDDDD'
              />
              <InputRightElement>
                <IconButton
                  aria-label="Send message"
                  icon={<FaPaperPlane color="#848484" />}
                  onClick={() => handleSendMessage(input, false, true)}
                  variant="unstyled"
                />
              </InputRightElement>
            </InputGroup>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default InfraTask;
