import React, { useRef, useEffect } from 'react'; // Import useRef
import { HStack, Box } from '@chakra-ui/react';
import TitleText from '../../text/titletext';
import AddTeamMember from './addteammember';
import AssignTeamRoles from './assignteamroles';
import ManageEmployeeOnboardingOffboarding from './manageteamonboardingoffboarding';
import { useTeamData } from '../../../contexts/TeamDataContext';

const TeamTab = ({ addTeamMemberRef, assignTeamRolesRef, manageEmployeeRef }) => {
  const { teamData } = useTeamData();

  return (
    <>
      {/* <HStack>
        <TitleText>Team</TitleText>
      </HStack>
      <Box bg="#222222" height="1px" mt={6} mb={10}/> */}
      <div mt={6} ref={addTeamMemberRef}><AddTeamMember teamData={teamData} /></div>
      <div ref={assignTeamRolesRef}><AssignTeamRoles teamData={teamData} /></div>
      <div ref={manageEmployeeRef}><ManageEmployeeOnboardingOffboarding/></div>
    </>
  );
};

export default TeamTab;