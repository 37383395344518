import React from 'react';
import { VStack } from '@chakra-ui/react';

const LoginBox = ({ children }) => {
  return (
    <VStack
      w='100%'
      h='100vh'
      bg='#101012'
      spacing={2}
      p={6}
      align='center'
      justify='center'
      textAlign='center'
    >
      {children}
    </VStack>
  );
};

export default LoginBox;