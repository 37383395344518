import React from 'react';
import { Text } from '@chakra-ui/react';

const TitleText = ({ children, sx }) => {
  return (
    <Text fontWeight='bold' fontSize={['lg', 'xl', '2xl', '3xl']} bgClip='text' bgGradient='linear(to-b, #dddddd, #777777)' sx={sx}>
      {children}
    </Text>
  );
};

export default TitleText;