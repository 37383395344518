import React from 'react';
import { Button } from '@chakra-ui/react';

const tabNames = {
  1: 'Company',
  2: 'Team',
  3: 'Documents',
  4: 'Integrations',
  5: 'Security',
  6: 'Audits',
};

const PaginationButton = ({ text, onClick, isDisabled, children, key, sx }) => {
  const content = typeof children === 'number' && children >= 1 && children <= 10
    ? <>
        <span style={{
          width: '18px',
          height: '18px',
          backgroundColor: '#848484',
          color: '#080809',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          marginRight: '8px'
        }}>
          {children}
        </span>
        {tabNames[children]}
      </>
    : children || text;

  return (
    <Button
      key={key}
      sx={sx}
      bg='transparent'
      color='#848484'
      borderRadius="xl"
      _hover={{
        bg: '#252527'
      }}
      _active={{
        bg: '#252527'
      }}
      _focus={{
        bg: '#252527'
      }}
      h='35px'
      fontSize='sm'
      fontWeight='normal'
      p={3}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {content}
    </Button>
  );
};

export default PaginationButton;