import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchProgress = () => {
    const [progressData, setProgressData] = useState({ total_tasks: 0, complete_tasks: 0, progress: 0 });
    const [error, setError] = useState(null);
    const toast = useToast();

    const fetchProgressData = useCallback(async () => {
        const sessionToken = await getSessionToken();
        try {
            const response = await axios.get(`${API_BASE_URL}core/org/progress/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Accept': 'application/json',
                },
            });
            setProgressData(response.data);
        } catch (error) {
            console.error('Error during request:', error);
            setError(error);
            posthog.capture('Error Fetching Progress Data', {
                error: error
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchProgressData();
    }, [fetchProgressData]);

    return { ...progressData, error, refetch: fetchProgressData };
};

export default useFetchProgress;
