import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDescope, useSession, useUser, SignUpOrInFlow } from '@descope/react-sdk';

import LoginBox from '../components/box/loginbox';
import TitleText from '../components/text/titletext';
import RegularText from '../components/text/regulartext';
import RedLogo from '../assets/redlogo.png';
import { Box, Image, Spinner } from '@chakra-ui/react';

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();

  const handleSuccess = useCallback((e) => {
    navigate('/compliance');
  }, [navigate]);

  const handleError = useCallback((e) => {
    console.log('Could not log in!', e);
  }, []);

  if (isSessionLoading || isUserLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#101012",
          color: "white",
          fontSize: "24px"
        }}
      >
        <TitleText>Loading...</TitleText> <Spinner size="lg" color="white" />
      </div>
    );
  }

  if (isAuthenticated) {
    // Redirect based on role when already authenticated and page is reloaded
    if (user === 'Tenant Admin') {
      navigate('/compliance');
    } else {
      navigate('/team-compliance');
    }
    return null;
  }

  return (
    <LoginBox>
      <Image src={RedLogo} alt="Logo" w='40px' mb='20px' objectFit='contain' />
      <TitleText>Login to your account</TitleText>
      <RegularText>Delve into compliance.</RegularText>

      <SignUpOrInFlow
        onSuccess={handleSuccess}
        onError={handleError}
        theme="dark"
      />

      <RegularText>
        Don't have an account?
        <span style={{ fontWeight: 'bold' }}>
          <a href="https://cal.com/team/getdelve/demo"> Book a demo.</a>
        </span>
      </RegularText>
    </LoginBox>
  );
};

export default Login;