import React, { useState } from 'react';
import { Box, Image, HStack, Spacer, VStack, Grid } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';

import AWS from "../../../assets/AWS.png";
import AWS_CloudTrail from "../../../assets/AWS_CloudTrail.png";
import AWS_Documentdb from "../../../assets/AWS_Documentdb.jpeg";
import AWS_Dynamodb from "../../../assets/AWS_DynamoDB.png";
import AWS_EC2 from "../../../assets/AWS_EC2.png";
import AWS_ECS from "../../../assets/AWS_ECS.png";
import AWS_ELB from "../../../assets/AWS_ELB.png";
import AWS_IAM from "../../../assets/AWS_IAM.webp";
import AWS_Lambda from "../../../assets/AWS_Lambda.png";
import AWS_RDS from "../../../assets/AWS_RDS.png";
import AWS_S3 from "../../../assets/AWS_S3.png";
import Anthropic from "../../../assets/Anthropic.png";
import Auth0 from "../../../assets/Auth0.png";
import Azure from "../../../assets/Azure.png";
import BaseTen from "../../../assets/BaseTen.jpeg";
import Cloudflare from "../../../assets/Cloudflare.png";
import Cohere from "../../../assets/Cohere.png";
import Datadog from "../../../assets/Datadog.png";
import Deepgram from "../../../assets/Deepgram.png";
import DefaultLogo from "../../../assets/DefaultLogo.png";
import DocSend from "../../../assets/DocSend.png";
import ElevenLabs from "../../../assets/ElevenLabs.png";
import GCP from "../../../assets/GCP.png";
import Github from "../../../assets/Github.png";
import Google from "../../../assets/Google.png";
import HubSpot from "../../../assets/HubSpot.jpeg";
import Linear from "../../../assets/Linear.png";
import Mixpanel from "../../../assets/Mixpanel.png";
import Namecheap from "../../../assets/Namecheap.png";
import NewRelic from "../../../assets/NewRelic.png";
import Notion from "../../../assets/Notion.png";
import OpenAI from "../../../assets/OpenAI.png";
import Railway from "../../../assets/Railway.png";
import Ramp from "../../../assets/Ramp.png";
import Rippling from "../../../assets/Rippling.jpg";
import Slack from "../../../assets/Slack.png";
import Supabase from "../../../assets/Supabase.png";
import Twilio from "../../../assets/Twilio.png";
import Vercel from "../../../assets/Vercel.png";
import Vonage from "../../../assets/Vonage.png";
import Zoom from "../../../assets/Zoom.png";
import CustomBadge from "../../general/custombadge";
import CustomProgressBar from "../../general/customprogressbar";
import Sentry from '../../../assets/Sentry.png';
import Pinecone from '../../../assets/Pinecone.png';
import Helicone from '../../../assets/Helicone.png';
import Fly from '../../../assets/Fly.png';
import Redis from '../../../assets/Redis.png';
import Axiom from '../../../assets/Axiom.jpg';
import Bland from '../../../assets/Bland.jpg';
import CrunchyData from '../../../assets/CrunchyData.png';

const DocumentsBox = ({ integrationData, handleDeleteIntegration, handleIntegrationClick }) => {

    const categoryColors = {
        'Technical': 'orange',
        'Communication': 'pink',
        'Productivity': 'purple',
        'HR & payroll': 'blue',
      };


const integrationLogos = {
    AWS: AWS,
    GCP: GCP,
    Mixpanel: Mixpanel,
    Github: Github,
    Slack: Slack,
    Zoom: Zoom,
    Notion: Notion,
    Vercel: Vercel,
    Cloudflare: Cloudflare,
    Auth0: Auth0,
    Azure: Azure,
    Datadog: Datadog,
    Supabase: Supabase,
    Google: Google,
    Twilio: Twilio,
    Cohere: Cohere,
    Vonage: Vonage,
    OpenAI: OpenAI,
    Anthropic: Anthropic,
    Rippling: Rippling,
    Ramp: Ramp,
    DocSend: DocSend,
    HubSpot: HubSpot,
    "AWS EC2": AWS_EC2,
    "AWS Cloudtrail": AWS_CloudTrail,
    "AWS Documentdb": AWS_Documentdb,
    "AWS Dynamodb": AWS_Dynamodb,
    "AWS ECS": AWS_ECS,
    "AWS IAM": AWS_IAM,
    "AWS Lambda": AWS_Lambda,
    "AWS RDS": AWS_RDS,
    "AWS S3": AWS_S3,
    "AWS ELB": AWS_ELB,
    Railway: Railway,
    BaseTen: BaseTen,
    ElevenLabs: ElevenLabs,
    Namecheap: Namecheap,
    Linear: Linear,
    Deepgram: Deepgram,
    NewRelic: NewRelic,
    Sentry: Sentry,
    Pinecone: Pinecone,
    Helicone: Helicone,
    Fly: Fly,
    Redis: Redis,
    default: DefaultLogo,
    CrunchyData: CrunchyData,
    Axiom: Axiom,
    Bland: Bland,
};


  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={4}>
        {integrationData.map((integration, index) => (
        <Box key={index} w='100%' borderRadius='xl' bg='#1C1C1E' border='1px solid #222222' p={1}>


            <Box p={2}>

                <HStack mt={2} mb={2} alignItems="start">
                    <Box
                        w="40px"
                        h="40px"
                        bg="white"
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={() => handleIntegrationClick(integration)}
                    >
                        <Image
                            src={integrationLogos[integration.name] || integrationLogos["default"]}
                            alt={integration.name}
                            w="32px"
                            h="auto"
                            objectFit="contain"
                        />
                    </Box>

                    <VStack align='start' spacing={0} cursor="pointer" onClick={() => handleIntegrationClick(integration)}>
                        <RegularText sx={{ color: '#dddddd' }}>
                            {integration.name}
                        </RegularText>
                        <CustomBadge text={integration.category} colorScheme={categoryColors[integration.category]} />
                    </VStack>
                    <Spacer></Spacer>
                    <ThreeDots deleteFunction={() => handleDeleteIntegration(integration.id)} />
                </HStack>
                </Box>

                {/* <VStack align='start' w='100%' p={2} onClick={() => handleIntegrationClick(integration)}>
                    <RegularText>
                        Tests last run 3 hours ago
                    </RegularText>
                    <CustomProgressBar completedTasks={7} totalTasks={10} />
                </VStack> */}

        </Box>
        ))}
    </Grid>
  );
};

export default DocumentsBox;