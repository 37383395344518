import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useUpdateTaskData = () => {
    const toast = useToast();

    const updateTaskData = async (newTaskData) => {
        const token = await getSessionToken();
        const url = `${API_BASE_URL}core/org/task/`;
        
        try {
            const response = await axios.post(url, newTaskData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            posthog.capture('Updated Task Data', {
                newTaskData: newTaskData
            });
            return response.data;
            
        } catch (error) {
            posthog.capture('Error Updating Task Data', {
                error: error
            });
        }
    };

    return { updateTaskData };
};

export default useUpdateTaskData;
