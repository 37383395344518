import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchSecurityReviews = () => {
    const [securityReviews, setSecurityReviews] = useState([]);
    const [error, setError] = useState(null);
    const toast = useToast();

    const fetchSecurityReviews = useCallback(async () => {
        const sessionToken = await getSessionToken();
        try {
            const response = await axios.get(`${API_BASE_URL}core/org/review/security/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Accept': 'application/json',
                },
            });
            setSecurityReviews(response.data);
        } catch (error) {
            console.error('Error during request:', error);
            setError(error);
            toast({
                title: "Error loading security reviews",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            posthog.capture('Error Fetching Security Reviews', {
                error: error
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchSecurityReviews();
    }, [fetchSecurityReviews]);

    return { securityReviews, refetch: fetchSecurityReviews, error };
};

export default useFetchSecurityReviews;
