import React from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/reportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Rythm Health";
  const CompanyEmail = "security@rythmhealth.com";
  const AccentColor = '#B29D94';
  const ComplianceFrameworks = ["HIPAA"];

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;