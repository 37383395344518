import React from 'react';
import ComplianceNavbar from '../components/compliance/compliancenavbar';
import SectionBox from '../components/box/sectionbox';
import { Box, Image } from '@chakra-ui/react';
import TeamCompliance from '../components/teamcompliance/teamcompliancetab';
import TitleText from '../components/text/titletext';
import Submitted from '../assets/submitted.png';
import RegularText from '../components/text/regulartext';


const Compliance = () => {
  return (
    <Box
      w='100%'
      minH='100vh'
      bg='#101012'
    >
      <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, mb: '6vh', zIndex: 1 }}>
        <ComplianceNavbar />
      </Box>
      <SectionBox>
        <Box w='100%' mt='14vh' display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <TitleText>Submitted!</TitleText>
          <RegularText sx={{mt:2}}>Thanks for completing your compliance tasks.</RegularText>
          <Image src={Submitted} alt="Submitted Image" />
        </Box>
      </SectionBox>
    </Box>
  );
};

export default Compliance;