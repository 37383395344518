import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import { Box, Button, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MessageView = memo(({ message, isUserMessage=false }) => {
  console.log('message', message)
  const toast = useToast();

  const customDracula = {
    ...dracula,
    'code[class*="language-"]': {
      ...dracula['code[class*="language-"]'],
      background: '#1a1a1a',
    },
    'pre[class*="language-"]': {
      ...dracula['pre[class*="language-"]'],
      background: '#1a1a1a',
    },
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      toast({
        title: "Code copied!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <Box position="relative">
          <SyntaxHighlighter
            style={customDracula}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
          <Button
            size="xs"
            position="absolute"
            top="0.5rem"
            right="0.5rem"
            onClick={() => handleCopy(String(children).replace(/\n$/, ''))}
          >
            <CopyIcon />
          </Button>
        </Box>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },

    p({ node, children, ...props }) {
      return (
        <p style={{ color: '#dddddd', fontSize: '14px' }} {...props}>
          {children}
        </p>
      );
    },
  };

  return (
    <Box
      bg={isUserMessage ? '#171718' : '#272728'}
      pt={3}
      pl={4}
      pr={4}
      pb={1}
      borderRadius='1rem'
      borderBottomLeftRadius={isUserMessage ? '1rem' : '0'} // Conditional styling based on isUserMessage
      borderBottomRightRadius={isUserMessage ? '0' : '1rem'} // Conditional styling based on isUserMessage
      alignSelf={isUserMessage ? 'flex-end' : 'flex-start'}
      w='auto'
      maxW='100%'
      fontSize='sm'
      color={isUserMessage ? '#000000' : '#dddddd'}
      mb={2}
      sx={{
        '& ol, & ul': {
          paddingLeft: '1.5rem',
        },
        '& ol ol, & ul ul': {
          paddingLeft: '1.5rem',
        },
        '& li': {
          marginBottom: '0.5rem',
        },
        '& p': {
          marginBottom: '0.5rem',
        },
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={components}
      >
        {message}
      </ReactMarkdown>
    </Box>
  );
});

export default MessageView;