import React from 'react';
import RegularText from '../text/regulartext';
import CustomInput from '../general/custominput';

const AWSComponent = () => {
  return (
    <div>
      <RegularText>This is the AWS integration. Follow Karun's steps to integrate your AWS account and add an ARN below.</RegularText>
      <CustomInput sx={{ mt: '1vh' }} placeholder="Enter your AWS ARN here..."/>
    </div>
  );
};

const GCPComponent = () => {
  return <p>This is the GCP integration.</p>;
};

const AzureComponent = () => {
  return <p>This is the Azure integration.</p>;
};

const VercelComponent = () => {
  return <p>This is the Vercel integration.</p>;
};

const GithubComponent = () => {
  return <p>This is the Github integration.</p>;
};

const SupabaseComponent = () => {
  return <p>This is the Supabase integration.</p>;
};

const CloudflareComponent = () => {
  return <p>This is the Cloudflare integration.</p>;
};

const Auth0Component = () => {
  return <p>This is the Auth0 integration.</p>;
};

const MixpanelComponent = () => {
  return <p>This is the Mixpanel integration.</p>;
};

const DatadogComponent = () => {
  return <p>This is the Datadog integration.</p>;
};

const SlackComponent = () => {
  return <p>This is the Slack integration.</p>;
};

const ZoomComponent = () => {
  return <p>This is the Zoom integration.</p>;
};

const NotionComponent = () => {
  return <p>This is the Notion integration.</p>;
};

const GoogleWorkspaceComponent = () => {
  return <p>This is the Google Workspace integration.</p>;
};

const AddIntegration = ({ selectedOption }) => {
  const renderComponent = () => {
    switch (selectedOption) {
      case 'AWS':
        return <AWSComponent />;
      case 'GCP':
        return <GCPComponent />;
      case 'Azure':
        return <AzureComponent />;
      case 'Vercel':
        return <VercelComponent />;
      case 'Github':
        return <GithubComponent />;
      case 'Supabase':
        return <SupabaseComponent />;
      case 'Cloudflare':
        return <CloudflareComponent />;
      case 'Auth0':
        return <Auth0Component />;
      case 'Mixpanel':
        return <MixpanelComponent />;
      case 'Datadog':
        return <DatadogComponent />;
      case 'Slack':
        return <SlackComponent />;
      case 'Zoom':
        return <ZoomComponent />;
      case 'Notion':
        return <NotionComponent />;
      case 'Google Workspace':
        return <GoogleWorkspaceComponent />;
      default:
        return <p>Select a valid integration option.</p>;
    }
  };

  return (
    <div>
      {renderComponent()}
    </div>
  );
};

export default AddIntegration;