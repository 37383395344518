import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton, Icon, HStack, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import RegularText from '../text/regulartext';

const ThreeDots = ({ deleteFunction }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={
          <Icon viewBox='0 0 220 200'>
            <path
              fill="#848484"
              d="M20,100a25,25 0 1,0 50,0a25,25 0 1,0 -50,0"
            />
            <path
              fill="#848484"
              d="M95,100a25,25 0 1,0 50,0a25,25 0 1,0 -50,0"
            />
            <path
              fill="#848484"
              d="M170,100a25,25 0 1,0 50,0a25,25 0 1,0 -50,0"
            />
          </Icon>
        }
        variant="ghost"
        size="sm"
        borderWidth='0'
        boxShadow='xs'
        style={{ outline: 'none', boxShadow: 'none' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        _hover={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
      />
      <MenuList
        minWidth="auto"
        width="max-content"
        minHeight='auto'
        p={0}
        mt='-10px'
        borderRadius='md'
      >
        <MenuItem
          fontSize='sm'
          borderRadius='md'
          color='#848484'
          _hover={{
            bg: '#FCF0F0',
            color: '#D73601',
          }}
          onClick={() => deleteFunction()}
        >
          <Icon as={DeleteIcon} /> <RegularText>&nbsp;Remove</RegularText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ThreeDots;
