import { useSession } from '@descope/react-sdk';
import { useNavigate } from 'react-router-dom';
import { grid } from 'ldrs';

function ProtectedRoute({ children }) {
    const { isAuthenticated, isSessionLoading } = useSession();
    let navigate = useNavigate();

    if (isSessionLoading) {
        grid.register()
        return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "black",
              }}
            />
          );
          }

    if (!isAuthenticated) {
        navigate('/login');
        return null; 
    }

    return children;
}

export default ProtectedRoute;