import React from 'react';
import { Box, Spacer, HStack } from '@chakra-ui/react';
import PaginationButton from './paginationbutton';

const CustomPagination = ({ sx, currentPage, onPageChange, totalPages }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    for (let i = 1; i <= Math.min(maxPageNumbersToShow, totalPages); i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((page, index) => (
      <PaginationButton
        key={index}
        onClick={() => handlePageChange(page)}
        sx={page === currentPage ? {
          bg: '#252527',
          color: 'white',
        } : undefined}
        flex="1"
        textAlign="center"
      >
        {page}
      </PaginationButton>
    ));
  };

  return (
    <Box
      bg="rgba(255, 255, 255, 0.025)"
      backdropFilter="blur(10px)"
      border="0.5px solid #222222"
      fontSize='sm'
      sx={sx}
      p={2}
      w='100%'
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack w="100%" spacing={2}>
        <Spacer />
        {renderPageNumbers()}
        <Spacer />
      </HStack>
    </Box>
  );
};

export default CustomPagination;