import React, { useState } from 'react';
import { Box, Image, HStack, Spacer, VStack, Grid } from '@chakra-ui/react';
import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';

import AWS_CloudTrail from "../../../assets/AWS_CloudTrail.png";
import AWS_Documentdb from "../../../assets/AWS_Documentdb.jpeg";
import AWS_Dynamodb from "../../../assets/AWS_DynamoDB.png";
import AWS_EC2 from "../../../assets/AWS_EC2.png";
import AWS_ECS from "../../../assets/AWS_ECS.png";
import AWS_ELB from "../../../assets/AWS_ELB.png";
import AWS_IAM from "../../../assets/AWS_IAM.webp";
import AWS_Lambda from "../../../assets/AWS_Lambda.png";
import AWS_RDS from "../../../assets/AWS_RDS.png";
import AWS_S3 from "../../../assets/AWS_S3.png";
import DefaultLogo from "../../../assets/DefaultLogo.png";
import CustomBadge from "../../general/custombadge";
import CustomProgressBar from "../../general/customprogressbar";

const DocumentsBox = ({ integrationResources, setClickedResource }) => {

    const categoryColors = {
        'Technical': 'orange',
        'Communication': 'pink',
        'Productivity': 'purple',
        'HR & payroll': 'blue',
      };


const resourceLogos = {
    "AWS EC2": AWS_EC2,
    "AWS Cloudtrail": AWS_CloudTrail,
    "AWS Documentdb": AWS_Documentdb,
    "AWS Dynamodb": AWS_Dynamodb,
    "AWS ECS": AWS_ECS,
    "AWS IAM": AWS_IAM,
    "AWS Lambda": AWS_Lambda,
    "AWS RDS": AWS_RDS,
    "AWS S3": AWS_S3,
    "AWS ELB": AWS_ELB,
    default: DefaultLogo,
};

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={4} mt={4}>
        {integrationResources.map((resource, index) => (
        <Box key={index} w='100%' borderRadius='xl' bg='#1C1C1E' border='1px solid #222222' p={1} onClick={() => setClickedResource(resource)}>


            <Box p={2}>

                <HStack mt={2} mb={2} alignItems="start">
                    <Box
                        w="40px"
                        h="40px"
                        bg="white"
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        // onClick={() => handleIntegrationClick(integration)}
                    >
                        <Image
                            src={resourceLogos[resource.name] || resourceLogos["default"]}
                            alt={resource.name}
                            w="32px"
                            h="auto"
                            objectFit="contain"
                        />
                    </Box>

                    <VStack align='start' spacing={0} cursor="pointer">
                        <RegularText sx={{ color: '#dddddd' }}>
                            {resource.name}
                        </RegularText>
                        {/* <RegularText>
                            Tests last run 3 hours ago
                        </RegularText> */}
                    </VStack>
                    <Spacer></Spacer>
                    {/* <ThreeDots deleteFunction={() => handleDeleteIntegration(integration.id)} /> */}
                </HStack>
                </Box>

                {/* <VStack align='start' w='100%' p={2}>
                    <CustomProgressBar completedTasks={7} totalTasks={10} />
                </VStack> */}

        </Box>
        ))}
    </Grid>
  );
};

export default DocumentsBox;