import React, { useState } from 'react';
import { HStack, Box, Collapse, VStack, Spacer } from '@chakra-ui/react';
import TeamCompleteTraining from './teamcompletetraining';
import TeamSignDocuments from './teamsigndocuments';
import RegularText from '../text/regulartext';

import TitleText from '../text/titletext';
import CustomButton from '../general/custombutton';
import { useNavigate } from 'react-router-dom';
import ConfigureComputerSettings from './configurecomputersettings';
import { ChevronRightIcon } from '@chakra-ui/icons';
import SubtitleText from '../text/subtitletext';

const TeamComplianceTab = ({ }) => {

  const navigate = useNavigate();

  const [isTrainingCollapsed, setIsTrainingCollapsed] = useState(true);
  const [isComputerSettingsCollapsed, setIsComputerSettingsCollapsed] = useState(true);
  const [isSignDocumentsCollapsed, setIsSignDocumentsCollapsed] = useState(true);

  const handleToggleTraining = () => {
    setIsTrainingCollapsed(!isTrainingCollapsed);
  };

  const handleToggleComputerSettings = () => {
    setIsComputerSettingsCollapsed(!isComputerSettingsCollapsed);
  };

  const handleToggleSignDocuments = () => {
    setIsSignDocumentsCollapsed(!isSignDocumentsCollapsed);  };

  const handleSubmit = () => {
    navigate('/submitted');
  };

  return (
    <>
      <Box w='100%'>
        <TitleText>Your compliance to-do</TitleText>

        <HStack mt={10} mb={4} onClick={handleToggleTraining} cursor="pointer" border="1px solid #222222" borderRadius="xl" p={4}>
          <VStack alignItems="flex-start">
            <SubtitleText>Compliance training</SubtitleText>
            <RegularText>Learn about how to stay secure and compliant.</RegularText>
          </VStack>
          <Spacer/>
          <ChevronRightIcon 
            transition="transform 0.2s"
            transform={isTrainingCollapsed ? "rotate(0deg)" : "rotate(90deg)"}
          />
        </HStack>
        <Collapse in={!isTrainingCollapsed} animateOpacity>
          <TeamCompleteTraining />
        </Collapse>


        <HStack mb={4} onClick={handleToggleComputerSettings} cursor="pointer" border="1px solid #222222" borderRadius="xl" p={4}>
          <VStack alignItems="flex-start">
            <SubtitleText>Computer settings</SubtitleText>
            <RegularText>Configure your computer settings to reduce the risk of getting hacked.</RegularText>
          </VStack>
          <Spacer/>
          <ChevronRightIcon 
            transition="transform 0.2s"
            transform={isComputerSettingsCollapsed ? "rotate(0deg)" : "rotate(90deg)"}
          />
        </HStack>
        <Collapse in={!isComputerSettingsCollapsed} animateOpacity>
          <ConfigureComputerSettings/>
        </Collapse>


        <HStack mb={4} onClick={handleToggleSignDocuments} cursor="pointer" border="1px solid #222222" borderRadius="xl" p={4}>
          <VStack alignItems="flex-start">
            <SubtitleText>Legal documents</SubtitleText>
            <RegularText>
              Agree to your company's legal documents that outline plans for compliance.
            </RegularText>
          </VStack>
          <Spacer/>
          <ChevronRightIcon 
            transition="transform 0.2s"
            transform={isSignDocumentsCollapsed ? "rotate(0deg)" : "rotate(90deg)"}
          />
        </HStack>
        <Collapse in={!isSignDocumentsCollapsed} animateOpacity>
          <TeamSignDocuments />
        </Collapse>


        <Box mt={10} mb={4} w='100%' display="flex" justifyContent="center">
          <CustomButton text="Submit" onClick={handleSubmit} />
        </Box>


      </Box>
    </>
  );
};

export default TeamComplianceTab;