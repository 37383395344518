import React, { useState, useEffect, useMemo } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Avatar, Box, HStack, Spacer, VStack, useToast, Button, Skeleton } from '@chakra-ui/react';

import RegularText from '../../text/regulartext';
import ThreeDots from '../../general/threedots';
import CustomBadge from '../../general/custombadge';
import CustomOutlineButton from '../../general/customoutlinebutton';
import CustomButton from '../../general/custombutton';
import CustomInput from '../../general/custominput';
import CustomDropdown from '../../general/customdropdown';
import CustomModal from '../../modal/custommodal';
import SubtitleText from '../../text/subtitletext';
import useFetchUserEvidenceSubmitted from '../../../hooks/useFetchUserEvidenceSubmitted'; // Import the hook
import { CheckCircleIcon, WarningIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useCompanyInfo } from '../../../contexts/CompanyInfoContext';

import { API_BASE_URL } from '../../../utils/Constants';
import { getSessionToken } from '@descope/react-sdk';
import axios from 'axios';

import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';

import posthog from 'posthog-js';

const roleColors = {
    'Tenant Admin': 'orange',
    Member: 'pink',
    Auditor: 'purple',
    Contractor: 'blue',
};

const TeamTable = ({ teamData, sx }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('+1234567890');
    const [role, setRole] = useState('');
    const toast = useToast();



  const taskIds = useMemo(() => [
    '2cb94081-ef36-4745-8d62-331d1eef097b', '502c43e0-080c-4cb9-b7b6-83532b72d556',
    'c337417b-5a21-4466-8de9-907cdb6bb000', '0c3a4a04-2395-4dcb-87af-b6ae8d8ced56',
    '16950dfa-b6e8-4e30-b6fb-41c839bdacb3', '2b82fdb0-e036-41a3-a0b4-fb970d324198',
    '92915e32-84a7-4aa0-8f15-f4925ff1cb6e', '4e522e75-6ba8-4563-aa43-29be76410e2d',
    'c8fefaf2-17ac-4485-bdda-9eef806858c2', '40ce9a04-7fc5-40fc-88d0-1f296d2862d2',
    '5e2537c0-9065-401d-901c-14d546b7cea6'
], []);

const handleButtonClick = () => {
    window.open('/team-compliance', '_blank');
  };


const { userListMap } = useFetchUserEvidenceSubmitted(taskIds);

const macScreenLock = userListMap['c337417b-5a21-4466-8de9-907cdb6bb000'] || [];
const macDiskEncrypted = userListMap['0c3a4a04-2395-4dcb-87af-b6ae8d8ced56'] || [];
const macFirewalls = userListMap['16950dfa-b6e8-4e30-b6fb-41c839bdacb3'] || [];
const passwordManager = userListMap['2b82fdb0-e036-41a3-a0b4-fb970d324198'] || [];
const winMalwareDetection = userListMap['92915e32-84a7-4aa0-8f15-f4925ff1cb6e'] || [];
const winScreenLock = userListMap['4e522e75-6ba8-4563-aa43-29be76410e2d'] || [];
const winDiskEncrypted = userListMap['c8fefaf2-17ac-4485-bdda-9eef806858c2'] || [];
const winFirewalls = userListMap['40ce9a04-7fc5-40fc-88d0-1f296d2862d2'] || [];
const legalAgreementsComplete = userListMap['5e2537c0-9065-401d-901c-14d546b7cea6'] || [];

const macUsersComplete = macScreenLock.filter(user => 
    macDiskEncrypted.includes(user) && 
    macFirewalls.includes(user) && 
    passwordManager.includes(user)
);

const winUsersComplete = winMalwareDetection.filter(user => 
    winScreenLock.includes(user) && 
    winDiskEncrypted.includes(user) && 
    winFirewalls.includes(user)
);
const computerSettingsComplete = Array.from(new Set([...macUsersComplete, ...winUsersComplete]));




    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const getShortRole = (fullRole) => {
        const roleMap = {
            'Tenant Admin (can complete any task)': 'Tenant Admin',
            'Member (can only sign documents)': 'Member',
            'Contractor (can only sign documents)': 'Contractor'
        };
        return roleMap[fullRole] || fullRole;
    };

    const [taskData, setTaskData] = useState([]);
    const relevantTaskIDs = [
      "204fb25a-55db-4cb0-a946-fe1aa52f9d37",
    ];
  
    const { data: fetchedData } = useFetchTaskData(relevantTaskIDs);
    const { updateTaskData } = useUpdateTaskData();
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    useEffect(() => {
      setTaskData(fetchedData);
      const allTasksComplete = fetchedData.every(task => task.complete);
      setIsSubmitted(allTasksComplete);
    }, [fetchedData]);
  
    

    const inviteTeamMember = async (name, email, role, phone) => {
        const sessionToken = await getSessionToken();

        const updatedTasks = taskData.map(task => ({ ...task, complete: true, evidence: `Marked task ${task.name} as complete. ${name} has been invited to the team as ${role}.` }));
        updateTaskData(updatedTasks);

        try {
            const response = await axios.post(`${API_BASE_URL}core/org/team/`,
                { name, email, role, phone },
                {
                    headers: {
                        'Authorization': `Bearer ${sessionToken}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            toast({
                title: `Success!`,
                description: `${name} has been invited!`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            closeModal();
            posthog.capture('Invited Team Member', {
                name: name,
                email: email,
                role: role,
                phone: phone
            });
        } catch (error) {
            console.error('Error during request:', error);
            posthog.capture('Error Inviting Team Member', {
                error: error
            });
        }
    };

    const deleteTeamMember = async (email) => {
        const sessionToken = await getSessionToken();

        const updatedTasks = taskData.map(task => ({ ...task, complete: false, evidence: `Marked task ${task.name} as incomplete. ${email} has been removed from the team.` }));
        updateTaskData(updatedTasks);

        try {
            const response = await axios.delete(`${API_BASE_URL}core/org/team/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json',
                },
                data: { email }
            });

            toast({
                title: 'Success!',
                description: 'User has been deleted!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            posthog.capture('Removed Team Member', {
                email: email
            });
        } catch (error) {
            console.error('Error during request:', error);
            posthog.capture('Error Removing Team Member', {
                error: error
            });
        }
    };

    const modalHeader = <SubtitleText>Add team member</SubtitleText>;
    const modalBody = (
        <VStack spacing={6} align='left'>
            <VStack align='left'>
                <RegularText sx={{ color: '#dddddd' }}>
                    Name
                </RegularText>
                <CustomInput placeholder="Sam Altman" onChange={(e) => setName(e.target.value)} />
            </VStack>

            <VStack align='left'>
                <RegularText sx={{ color: '#dddddd' }}>
                    Email
                </RegularText>
                <CustomInput placeholder="sam@altman.com" onChange={(e) => setEmail(e.target.value)} />
            </VStack>

            <VStack align='left'>
                <RegularText sx={{ color: '#dddddd' }}>
                    Role
                </RegularText>
                <CustomDropdown
                    items={[
                        'Tenant Admin (can complete any task)',
                        'Member (can only sign documents)',
                        'Contractor (can only sign documents)'
                    ]}
                    placeholder="Select role"
                    onChange={(e) => setRole(getShortRole(e.target.value))}
                />
            </VStack>

        </VStack>
    );
    
    const modalFooter = <CustomButton onClick={() => inviteTeamMember(name, email, role, phone)} text='Invite'></CustomButton>;

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (teamData && teamData.length > 0) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [teamData]);
    


    const [hipaa, setHipaa] = useState(false);
    const [soc2, setSoc2] = useState(false);

    const { companyInfo, loadCompanyInfo } = useCompanyInfo();

    useEffect(() => {
        if (companyInfo.isLoading) {
          loadCompanyInfo();
        }
      }, [companyInfo.isLoading, loadCompanyInfo]);

      
    useEffect(() => {
        if (!companyInfo.isLoading) {
          setHipaa(companyInfo.hipaa || false);
          setSoc2(companyInfo.soc2 || false);
        }
      }, [companyInfo]); // Depend on companyInfo to update local states
    
      const complianceTraining1Complete = userListMap['2cb94081-ef36-4745-8d62-331d1eef097b'] || [];
        const complianceTraining2Complete = userListMap['502c43e0-080c-4cb9-b7b6-83532b72d556'] || [];
        const complianceTrainingComplete = useMemo(() => {
            if (!companyInfo.isLoading && soc2) {
            return complianceTraining1Complete;
            } else if (!companyInfo.isLoading && hipaa) {
            return complianceTraining2Complete;
            } else if (!companyInfo.isLoading && hipaa && soc2) {
            return complianceTraining1Complete.filter(name => complianceTraining2Complete.includes(name));
            }
            return [];
        }, [companyInfo.isLoading, soc2, hipaa, complianceTraining1Complete, complianceTraining2Complete]);


    return (
        <>

            <Table variant="simple" colorScheme='white' sx={sx}>
                <Thead>
                    <Tr>
                    {['Name', 'Security training', 'Computer settings', 'Legal docs', 'Role'].map((header, index) => (
                        <Th key={index} color="#222222" pl={0}>
                            <HStack spacing={1}>
                                <RegularText sx={{ fontSize: 'xs' }}>{header}</RegularText>
                                {['Security training', 'Computer settings', 'Legal docs'].includes(header) && (
                                    <Button
                                    size="xs"
                                        variant="unstyled"
                                        borderRadius="md"
                                        bg='#1C1C1E'
                                        border="0.5px solid #222222"
                                        color='#848484'
                                        w="auto"
                                        h='25px'
                                        p={0}
                                        m={0}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() => handleButtonClick()}
                                        >
                                            <ExternalLinkIcon size="xxs" />
                                    </Button>
                                )}
                                <Spacer></Spacer>
                            </HStack>
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                {teamData && (
                <Tbody>
                        {teamData.map((member, index) => (
                            <Tr key={index} color="#222222">
                                <Td pl={0}>
                                    <Box display="flex" alignItems="center">
                                        <Avatar name={member.name} src={member.avatar} size="sm" mr={2} />
                                        <VStack align='start' spacing={0}>
                                            <RegularText sx={{ color: '#DDDDDD' }}>{member.name}</RegularText>
                                            <RegularText>{member.email}</RegularText>
                                        </VStack>
                                    </Box>
                                </Td>
                                <Td p={0}>
                                    {complianceTrainingComplete.includes(member.name) ? 
                                        <CheckCircleIcon color="#55BF79" /> : 
                                        <WarningIcon color="#D34D24" />
                                    }             
                                </Td>
                                <Td p={0}>
                                    {computerSettingsComplete.includes(member.name) ? 
                                        <CheckCircleIcon color="#55BF79" /> : 
                                        <WarningIcon color="#D34D24" />
                                    }     
                                </Td>
                                <Td p={0}>
                                    {legalAgreementsComplete.includes(member.name) ? 
                                        <CheckCircleIcon color="#55BF79" /> : 
                                        <WarningIcon color="#D34D24" />
                                    }
                                </Td>
                                <Td pl={0}>
                                    <HStack>
                                        <RegularText sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {member.role}
                                        </RegularText>
                                        <Spacer></Spacer>
                                        <ThreeDots deleteFunction={() => deleteTeamMember(member.email)} />
                                    </HStack>
                                </Td>
                            </Tr>
                        ))
                    }   

                </Tbody>
                )}
            </Table>

            {isLoading && (
                <>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                    <Skeleton height="2rem" ml='20px' mr='20px' mt='20px' mb='20px' startColor="#333333" endColor="#222222"/>
                </>
            )}

            <HStack mt={5}>
                <CustomOutlineButton
                    onClick={openModal}
                    text="+ Add member"
                />
          </HStack>

            <CustomModal isOpen={isModalOpen} onClose={closeModal} modalHeader={modalHeader} modalBody={modalBody} modalFooter={modalFooter} />
        </>
    );
};

export default TeamTable;