import React from 'react';
import { Textarea } from '@chakra-ui/react';

const CustomInput = ({ placeholder, sx, onChange, value, cursor, disabled, onKeyDown }) => {
  return (
    <Textarea
      bg='#101012'
      border="0.5px solid #222222"
      borderRadius="xl"
      fontSize='sm'
      sx={sx}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      cursor={cursor}
      disabled={disabled}
      onKeyDown={onKeyDown}
      _placeholder={{ color: '#848484' }}
      _hover={{ borderColor: '#222222' }}
      _focus={{ borderColor: '#222222', boxShadow: 'none' }}
      _active={{ borderColor: '#222222', boxShadow: 'none' }}
    >
    </Textarea>
  );
};

export default CustomInput;