import React from 'react';

import Navbar from '../components/general/navbar';
import CustomButton from '../components/general/custombutton';
import SectionBox from '../components/box/sectionbox';
import PageBox from '../components/box/pagebox';
import TitleText from '../components/text/titletext';
import TeamTable from '../components/compliance/team/teamtable';

import { HStack, Spacer } from '@chakra-ui/react';

const teamData = [
  {
    name: 'Selin Kocalar',
    email: 'selin@getdelve.com',
    role: 'Admin',
    status: 'Joined Feb. 12, 2024',
    avatar: 'path/to/avatar1.jpg',
  },
  {
    name: 'Selin Kocalar',
    email: 'selin@getdelve.com',
    role: 'Member',
    status: 'Invite pending',
    avatar: 'path/to/avatar2.jpg',
  },
  {
    name: 'Selin Kocalar',
    email: 'selin@getdelve.com',
    role: 'Member',
    status: 'Joined Feb. 12, 2024',
    avatar: 'path/to/avatar3.jpg',
  },
  {
    name: 'Selin Kocalar',
    email: 'selin@getdelve.com',
    role: 'Contractor',
    status: 'Joined Feb. 12, 2024',
    avatar: 'path/to/avatar4.jpg',
  },
];

const Team = () => {
  return (
    <PageBox>
        <Navbar />
        <SectionBox>
          <HStack>
            <TitleText>Team</TitleText>
            <Spacer/>
            <CustomButton text="+ Add user" />
          </HStack>
          <TeamTable data={teamData} sx={{ mt: '4vh' }} />
        </SectionBox>
    </PageBox>
  );
};

export default Team;