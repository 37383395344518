import React from 'react';
import { Select } from '@chakra-ui/react';
import RegularText from '../text/regulartext';

const CustomDropdown = ({ items, placeholder, sx, onChange, value, disabled }) => {
  return (
    <Select
    bg='#101012'
    border="0.5px solid #222222"
    color='#848484'
    borderRadius="xl"
    boxShadow='none'
    value={value}
    _hover={{ borderColor: '#222222', boxShadow: 'none' }}
    _active={{ borderColor: '#222222', boxShadow: 'none' }}
    fontSize='sm'
    sx={sx}
    placeholder={placeholder}
    _placeholder={{ color: '#848484' }}
    onChange={onChange}
    disabled={disabled}
    >
      {items.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}

    </Select>
  );
};

export default CustomDropdown;