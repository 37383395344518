import React from 'react';
import { HStack, Box, VStack, Spacer } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import ThreeDots from './threedots';
import CustomBadge from './custombadge';

const Integration = ({ }) => {
  return (
    <HStack borderWidth={1} borderColor="#222222" borderRadius='md' p={4}>
        <Box bg='blue' w='40px' h='40px' borderRadius='full'></Box>
        <VStack align='left' pl={2} spacing={1}>
            <HStack>
                <RegularText sx={{color: '#DDDDDD'}}>Amazon Web Services (AWS)</RegularText>
                <CustomBadge text={'Connected'} colorScheme={'green'}></CustomBadge>
            </HStack>
            <RegularText>Cloud infrastructure provider.</RegularText>
        </VStack>
        <Spacer/>
        <ThreeDots/>
    </HStack>
  );
};

export default Integration;