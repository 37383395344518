import { React, useCallback, useState, useEffect } from "react";
import {
    Box,
    Button,
    HStack,
    Image,
    Avatar,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import Logo from "../../assets/logo.png";
import RegularText from "../text/regulartext";
import { useDescope, useUser } from "@descope/react-sdk";
import CustomProgressBar from "../general/customprogressbar";
import useFetchProgress from "../../hooks/useFetchProgress";
import Confetti from "react-confetti";

const Navbar = () => {
    const { logout } = useDescope();
    const { user } = useUser();
    const { total_tasks, complete_tasks } = useFetchProgress();

    const [animatedProgress, setAnimatedProgress] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        if (complete_tasks && total_tasks) {
            setAnimatedProgress(0); // Start from 0
            let start = null;
            const duration = 650; // Animation duration in milliseconds

            const animate = (timestamp) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                const percentage = Math.min(
                    (progress / duration) * 100,
                    (complete_tasks / total_tasks) * 100
                );
                setAnimatedProgress(percentage);

                if (progress < duration) {
                    requestAnimationFrame(animate);
                } else if (percentage >= 100) {
                    setShowConfetti(true);
                    setTimeout(() => setShowConfetti(false), 10000); // Show confetti for 10 seconds
                }
            };

            requestAnimationFrame(animate);
        }
    }, [complete_tasks, total_tasks]);

    const handleLogout = useCallback(() => {
        logout();
    }, [logout]);

    return (
        <Box
            as="nav"
            height="70px"
            pr={4}
            pl={4}
            width="100%"
            bg="#101012"
            pt={4}
            display="flex"
            justifyContent="center"
            position="relative"
        >
            {showConfetti && <Confetti recycle={false} numberOfPieces={1500} />}
            <HStack spacing={4} w="100%" h="100%">
                <Button
                    onClick={() => (window.location.href = "/compliance")}
                    variant="unstyled"
                >
                    <HStack w="100%" h="100%">
                        <Image
                            src={Logo}
                            alt="Logo"
                            h="100%"
                            w="auto"
                            objectFit="contain"
                        />
                        <RegularText sx={{ color: "#dddddd", fontSize: "3xl" }}>
                            Delve
                        </RegularText>
                    </HStack>
                </Button>
                <Spacer></Spacer>

                <Box w="50%">
                    <CustomProgressBar
                        completedTasks={animatedProgress}
                        totalTasks={100}
                        animated
                    />
                </Box>

                <Spacer></Spacer>
                {process.env.REACT_APP_API_BASE_URL ? null : (
                    <RegularText
                        sx={{
                            color: "white",
                            fontSize: "md",
                            bg: "red",
                            p: 2,
                            borderRadius: "md",
                        }}
                    >
                        Dev
                    </RegularText>
                )}
                <Menu placement="right">
                    <MenuButton as={Button} variant="ghost" p={0}>

                        <Avatar
                            src={user?.picture}
                            alt="User Profile"
                            w="40px"
                            h="40px"
                            borderRadius="md"
                        />
                    </MenuButton>
                    <MenuList
                        minWidth="auto"
                        width="max-content"
                        minHeight="auto"
                        p={0}
                        borderRadius="md"
                    >
                        <MenuItem
                            fontSize="sm"
                            borderRadius="md"
                            _hover={{
                                bg: "#FCF0F0",
                                color: "#D73601",
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
        </Box>
    );
};

export default Navbar;
