import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getSessionToken } from '@descope/react-sdk';
import { API_BASE_URL } from '../utils/Constants';
import { useToast } from '@chakra-ui/react';
import posthog from 'posthog-js';

const useFetchSimulateData = () => {
    const [simulateData, setSimulateData] = useState([]);
    const [error, setError] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    const fetchSimulateData = useCallback(async () => {
        const sessionToken = await getSessionToken();
        try {
            const response = await axios.get(`${API_BASE_URL}core/org/incident/simulated/`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Accept': 'application/json',
                },
            });
            setSimulateData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error during request:', error);
            setError(error);
            toast({
                title: "Error loading incident simulation data",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            posthog.capture('Error Fetching Incident Simulation Data', {
                error: error
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchSimulateData();
    }, [fetchSimulateData]);

    return { simulateData, loading, error, refetch: fetchSimulateData };
};

export default useFetchSimulateData;