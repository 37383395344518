import React from 'react';
import { Text } from '@chakra-ui/react';

const SubtitleText = ({ children, sx }) => {
  return (
    <Text fontWeight='bold' sx={sx} fontSize={['lg', 'lg', 'xl', 'xl']} bgClip='text' bgGradient='linear(to-t, #B02525, #D34D24)'>
      {children}
    </Text>
  );
};

export default SubtitleText;