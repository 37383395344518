import React, { useState, useEffect, useMemo } from 'react';
import { HStack, VStack, Box } from '@chakra-ui/react';
import SubtitleText from '../../text/subtitletext';
import RegularText from '../../text/regulartext';
import CustomUploadFileTable from '../customuploadfiletable';
import useFetchTaskData from '../../../hooks/useFetchTaskData';
import useUpdateTaskData from '../../../hooks/useUpdateTaskData';
import TitleText from '../../text/titletext';

const AddTeamMember = ({ }) => {

  const relevantTaskIDs = useMemo(() => [
    "f4fb0bc3-398a-46bb-b6b8-135a42f0473e",
    "30faf73e-6aa3-47ac-98de-e031ddec91a0",
    "f9b532f0-e7bb-4eb9-84ab-c0a311065fe4",
    "326895ee-d6cf-4aed-aead-5bd31cdaaefa",
    "613a1ba3-5ff0-4826-b22a-cb38a8653269",
    "d732d928-d919-45d8-a44e-ae086a43da30",
    "5a36d317-2275-42c3-8bf3-91007445f9b3",
    "d800a82b-6106-464d-9b05-73d0289b5f23",
    "8114d6a9-7266-4c07-8e52-b0e79959719d",
  ], []);

  const [taskData, setTaskData] = useState([]);
  const { data: fetchedData, refetch } = useFetchTaskData(relevantTaskIDs);
  const { updateTaskData } = useUpdateTaskData();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      const taskMap = new Map(fetchedData.map(task => [task.global_task_id, task]));
      const orderedData = relevantTaskIDs.map(id => taskMap.get(id));
      setTaskData(orderedData);
      const allTasksComplete = orderedData.every(task => task && task.complete);
      setIsSubmitted(allTasksComplete);
    }
  }, [fetchedData, relevantTaskIDs]);
 

  const handleTaskDataChange = (updatedTaskData) => {
    setTaskData(updatedTaskData);
  };

  return (
    <Box mb={16}>
      <HStack>
        <VStack align='left'>
          <TitleText>Track security-related tasks in your task tracker</TitleText>
          <RegularText sx={{ mb: '2vh' }}>
            In your task tracker (Asana, Jira, Notion, etc.), track security-related tasks and prioritize them based on urgency.
          </RegularText>
        </VStack>
      </HStack>
      <CustomUploadFileTable taskData={taskData} onTaskDataChange={handleTaskDataChange} refetch={refetch}/>
    </Box>
  );
};

export default AddTeamMember;