import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faPlug, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

// Mapping titles to FontAwesome icons
const iconMap = {
  Dashboard: faHome,
  Team: faUser,
  Integrations: faPlug,
  Compliance: faSquareCheck
};

// Mapping titles to paths
const pathMap = {
  Dashboard: '/dashboard',
  Team: '/team',
  Integrations: '/integrations',
  Compliance: '/compliance'
};

const NavbarButton = ({ title, selected }) => {
  const navigate = useNavigate();
  const icon = iconMap[title];
  const path = pathMap[title];

  return (
      <IconButton
        icon={<FontAwesomeIcon icon={icon} style={{ fontSize: "20px" }} color={selected ? "#DDDDDD" : "#848484"} />}
        variant="ghost"
        sx={{
            _hover: { bg: "#272728" },
            bg: selected ? '#272728' : 'transparent'
        }}
        onClick={() => navigate(path)}
      />
  );
};

export default NavbarButton;