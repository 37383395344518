import React, { useState } from 'react';
import { Box, Image, HStack, Spacer, VStack, Grid } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import DocThumbnail from '../../assets/docthumbnail.png';
import CustomBadge from '../general/custombadge';
import CustomDocumentModal from '../compliance/documents/customdocumentmodal';
import CustomButton from '../general/custombutton';
import CustomInput from '../general/custominput';
import CustomDropdown from '../general/customdropdown';
import SubtitleText from '../text/subtitletext';
import useFetchTeamData from '../../hooks/useFetchTeamData';
import TeamComplianceModal from './teamcompliancemodal';

const DocumentsBox = ({ taskData = [], sx, onTaskDataChange, onDoneClick }) => {

    
  const { teamData } = useFetchTeamData();
  const names = teamData.map(member => member.name);

  const handleSelectionChange = (role, event) => {
    const selectedMemberName = event.target.value;
    if (selectedMemberName === "Select") {
        const updatedTaskData = taskData.map(task => 
            task.title === role ? { ...task, associated_user: null, associated_user_name: null } : task
        );
        onTaskDataChange(updatedTaskData);
        return;
    }
    const selectedMember = teamData.find(member => member.name === selectedMemberName);
    if (!selectedMember) {
        console.error(`No team member found with name: ${selectedMemberName}`);
        return;
    }
    const updatedTaskData = taskData.map(task => {
        if (task.title === role) {
            const updatedTask = { ...task, associated_user: selectedMember.id, associated_user_name: selectedMember.name, evidence: `${selectedMember.name} reviewed and agreed to policy.` };
            return updatedTask;
        }
        return task;
    });
    onTaskDataChange(updatedTaskData);
};


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const openModal = (doc) => {
    setSelectedDoc(doc);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDoc(null);
  };
  const modalHeader = <SubtitleText>Media Re-Use Policy</SubtitleText>;


  const formatFrameworks = (frameworks) => {
    const validFrameworks = frameworks.filter(f => f);
    return validFrameworks.join(' & ');
  };
  
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={2} height="auto" mb={10}>
      {taskData.map((doc, index) => (
        <Box key={index} w='100%' borderRadius='xl' bg='#1C1C1E' border='1px solid #222222' p={1} onClick={() => openModal(doc)} cursor="pointer">
          <Box w='100%' borderRadius='lg' bg='#272728' border='1px solid #222222' display="flex" justifyContent="center" alignItems="center" position="relative">
            <Image src={DocThumbnail} alt="Logo" w='150px'/>
          </Box>
          <Box p={2}>
            <RegularText sx={{ color: '#dddddd' }}>
              {doc.title}
            </RegularText>
            <RegularText>
            {formatFrameworks(doc.frameworks)}
          </RegularText>
            <HStack mt='10px'>
              <Spacer />
              {doc.evidence ? (
                <CustomBadge position="absolute" text={`Signed`} colorScheme={'green'} />
              ) : (
                <CustomBadge position="absolute" text={'Review and sign →'} colorScheme={'gray'} />
              )}
            </HStack>
            </Box>
        </Box>
      ))}
      {selectedDoc && (
        <TeamComplianceModal
          doc={selectedDoc}
          frameworks={formatFrameworks(selectedDoc.frameworks)}
          isOpen={isModalOpen}
          onClose={closeModal}
          modalHeader={modalHeader}
          onDoneClick={onDoneClick}
          names={names}
          teamData={teamData}
          handleSelectionChange={handleSelectionChange}
        />
      )}
    </Grid>
  );
};

export default DocumentsBox;